import {useState} from 'react';
import Button from '../../ui/Button';

import {ReactComponent as EyeIcon} from '../../../assets/images/icons/Eye.svg';

const tableData = [
  {
    id: 1,
    product: 'Instagram',
    creator: 'Meta',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum tempora impedit facere quis incidunt velit',
    rating: 5,
  },
  {
    id: 2,
    product: 'Instagram',
    creator: 'Meta',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum tempora impedit facere quis incidunt velit',
    rating: 3,
  },
  {
    id: 3,
    product: 'Instagram',
    creator: 'Meta',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum tempora impedit facere quis incidunt velit',
    rating: 5,
  },
];

const UserProductList = () => {
  const [showProducts, setShowProduct] = useState('active');

  return (
    <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex flex-col gap-6 rounded-md bg-custom-gradient p-8 shadow-custom-shadow">
        <div className="flex w-full items-center justify-between">
          <p className="text-2xl font-medium text-gray-300">Products</p>

          <div className="relative w-1/4 rounded-md md-lt:w-28">
            <select
              value={showProducts}
              onChange={e => setShowProduct(e.target.value)}
              className="relative w-full appearance-none rounded-md border border-[#c49af9] bg-[#171233] px-4 py-1 pr-8 text-white shadow-custom-shadow outline-none"
            >
              <option className="bg-[#1b1446]" value="like">
                Like
              </option>
              <option className="bg-[#1b1446]" value="dislike">
                Dislike
              </option>
              <option className="bg-[#1b1446]" value="wishlist">
                Wishlist
              </option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-purple-400">
              <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                <path d="M5.5 7.5l4.5 4.5 4.5-4.5h-9z" />
              </svg>
            </div>
          </div>
        </div>

        <div className="md-lt:overflow-x-scroll">
          <table className="w-full table-auto border-collapse">
            <thead className="h-12 text-nowrap border-solid border-[#330987] bg-custom-purple-1 text-left text-sm font-semibold uppercase">
              <tr>
                <td className="px-3 py-2">Product Name</td>
                <td className="px-3 py-2 text-center">Creator</td>
                <td className="px-3 py-2">Description</td>
                <td className="px-3 py-2 text-center">Review</td>
                <td className="px-3 py-2"></td>
              </tr>
            </thead>
            <tbody className="divide-y-2 divide-[#3c4391] border-b-2 border-solid border-[#3c4391] bg-[#1d144c] text-xs font-light">
              {tableData.map(data => (
                <tr key={data.id}>
                  <td className="px-3 py-2">{data.product}</td>
                  <td className="px-3 py-2 text-center">{data.creator}</td>
                  <td className="text-wrap px-3 py-2">
                    <div className="w-2/3 md-lt:w-60">{data.description}</div>
                  </td>
                  <td className="px-3 py-2">
                    <div className="flex h-full items-center justify-center gap-0.5">
                      {[...Array(5)].map((_, index) => (
                        <svg
                          key={index}
                          className="h-5 w-5 text-[#00f7ff]"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                        </svg>
                      ))}
                    </div>
                  </td>
                  <td className="px-3 py-2 text-center">
                    <Button
                      className="rounded-full bg-transparent p-2 hover:bg-[#00000022] active:bg-[#00000044]"
                      type="custom"
                      onClick={() => console.log(data.id)}
                    >
                      <EyeIcon className="h-5 w-5" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserProductList;
