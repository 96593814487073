import {useMutation} from '@tanstack/react-query';
import makeApiCall from '../lib/apiCall';
import {apiEndpoints} from '../constants/api-endpoints';

// login mutation
export function useLogin() {
  // get the query client
  // create the mutation
  return useMutation({
    mutationFn: (data: any) => {
      return makeApiCall({
        ...apiEndpoints.Login,
        payload: data,
      });
    },
    onSuccess: res => {
      localStorage.setItem('authToken', res.token);
    },
  });
}

export function useGetMe() {
  // get the query client
  // create the mutation
  return useMutation({
    mutationFn: (data: undefined) => {
      return makeApiCall(apiEndpoints.GetME);
    },
  });
}

export function useForgotPassword() {
  return useMutation({
    mutationFn: (email: string) => {
      return makeApiCall({
        ...apiEndpoints.ForgotPassword,
        params: {
          ...apiEndpoints.ForgotPassword.params,
          query: {
            ...apiEndpoints.ForgotPassword.params.query,
            email,
          },
        },
      });
    },
  });
}

export function useResetPassword() {
  return useMutation({
    mutationFn: (data: any) => {
      return makeApiCall({
        ...apiEndpoints.ResetPassword,
        payload: {newPassword: data.body.password},
        params: {
          ...apiEndpoints.ResetPassword.params,
          query: {
            ...apiEndpoints.ResetPassword.params.query,
            token: data.token,
          },
        },
      });
    },
  });
}
