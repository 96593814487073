import React, {useState} from 'react';
import {cn} from '../../lib/utils';
import {FieldError, FieldValues, Path, UseFormRegister} from 'react-hook-form';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

interface InputProps<TFormValues extends FieldValues> {
  label?: string;
  name: Path<TFormValues>; // Type-safe name
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  type?: 'text' | 'email' | 'number' | 'password' | 'textarea';
  rows?: number; // Only for textarea
  required?: boolean;
  register: UseFormRegister<TFormValues>; // Type-safe register
  error?: FieldError;
  valueAsNumber?: boolean;
  [x: string]: any; // Allow additional props
}

const Input = <TFormValues extends FieldValues>({
  label = '',
  name,
  placeholder = 'Enter here...',
  rows = 3,
  disabled = false,
  className = '',
  required = false,
  type = 'text',
  error,
  register,
  valueAsNumber = false,
  ...rest
}: InputProps<TFormValues>) => {
  const baseStyles = `w-full outline-none border-2 px-4 py-2  ${
    disabled ? 'text-gray-400' : 'hover:border-[#ffffff99]'
  }`;
  const errorStyles = error
    ? 'border-[#F04438] focus:border-[#F04438]'
    : 'border-[#3c3e5c] focus:border-[#2684ff]';

  const [showPassword, setShowPassword] = useState(false);

  // Toggles the password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  // Determine input type based on `showPassword` state
  const inputType = type === 'password' && !showPassword ? 'password' : 'text';

  return (
    <div>
      {label && (
        <label className="text-sm">
          <p className="text-xs font-light text-white">
            {label}
            {required && <span className="ms-1 text-[#F04438]">*</span>}
          </p>
        </label>
      )}
      <div className="relative">
        {type === 'textarea' ? (
          <textarea
            rows={rows}
            autoComplete="off"
            placeholder={placeholder}
            className={cn(`${baseStyles} ${errorStyles}`, className)}
            style={{
              background: '#2E246C33',
            }}
            disabled={disabled}
            {...register(name, {valueAsNumber})}
            {...rest}
          />
        ) : (
          <input
            type={inputType}
            autoComplete="off"
            placeholder={placeholder}
            className={cn(
              `${baseStyles} rounded-full ${errorStyles}`,
              className,
            )}
            style={{
              background: '#2E246C33',
            }}
            disabled={disabled}
            {...register(name, {valueAsNumber})}
            {...rest}
          />
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-4 top-1/2 -translate-y-1/2 transform cursor-pointer"
            tabIndex={-1}
          >
            {showPassword ? (
              <FaEye className="text-gray-300" />
            ) : (
              <FaEyeSlash className="text-gray-400" />
            )}
          </button>
        )}
      </div>
      {error && (
        <span className="text-xs text-[#f04438]">{error.message ?? ''}</span>
      )}
    </div>
  );
};

export default Input;
