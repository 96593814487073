interface NotificationButtonProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  buttonRef?: React.Ref<HTMLDivElement>;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({
  onClick,
  buttonRef,
}) => {
  return (
    <div className="relative cursor-pointer" onClick={onClick} ref={buttonRef}>
      <div className="rounded-full bg-[#2c2051] px-2 py-2">
        <svg
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
        >
          <path
            d="M14.23 21C14.0542 21.3031 13.8018 21.5547 13.4982 21.7295C13.1946 21.9044 12.8504 21.9965 12.5 21.9965C12.1496 21.9965 11.8054 21.9044 11.5018 21.7295C11.1982 21.5547 10.9458 21.3031 10.77 21M18.5 8C18.5 6.4087 17.8679 4.88258 16.7426 3.75736C15.6174 2.63214 14.0913 2 12.5 2C10.9087 2 9.38258 2.63214 8.25736 3.75736C7.13214 4.88258 6.5 6.4087 6.5 8C6.5 15 3.5 17 3.5 17H21.5C21.5 17 18.5 15 18.5 8Z"
            stroke="#00F0FB"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="absolute right-2 top-2 h-2 w-2 rounded-full border-none border-[#2c2051] bg-red-500" />
    </div>
  );
};

export default NotificationButton;
