import TabItem from '../../common/TabNavigation/TabItem';
import TabList from '../../common/TabNavigation/TabList';
import topTrendingSample from '../../../assets/images/icons/topTrendingSample.jpg';
import disney from '../../../assets/images/misc/disney.png';
import duolingo from '../../../assets/images/misc/duolingo.png';
import cyberpunk from '../../../assets/images/misc/cyberpunk.png';
import canva from '../../../assets/images/misc/canva.png';
import star from '../../../assets/images/icons/star.png';
import heart from '../../../assets/images/icons/heart.png';
import share from '../../../assets/images/icons/share.png';
import TopTrending from '../TopTrending/TopTrending';
import React from 'react';

const topRatedProducts = [
  {
    productId: '1',
    name: 'Fortnite',
    category: 'Games',
    subCategory: 'Action',
    exploreImage: topTrendingSample,
    rating: 4.9,
  },
  {
    productId: '2',
    name: 'Disney+',
    category: 'Apps',
    exploreImage: disney,
    subCategory: 'Entertainment',
    rating: 4.6,
  },
  {
    productId: '3',
    name: 'Duolingo',
    category: 'Apps',
    exploreImage: duolingo,
    subCategory: 'Entertainment',
    rating: 4.5,
  },
  {
    productId: '4',
    name: 'Cyberpunk',
    category: 'Games',
    exploreImage: cyberpunk,
    subCategory: 'Action',
    rating: 4.3,
  },
  {
    productId: '5',
    name: 'Canva',
    category: 'Apps',
    exploreImage: canva,
    subCategory: 'Design',
    rating: 4.0,
  },
];

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

interface UserTopProductsProps {
  className?: string;
}

const UserTopProducts: React.FC<UserTopProductsProps> = ({className}) => {
  return (
    <div
      className={`rounded-2xl bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] ${className}`}
    >
      <div className="relative h-full overflow-hidden rounded-2xl shadow-custom-shadow">
        <TabList
          activeTabIndex={0}
          listClasses="w-full"
          itemClasses="w-full px-3 py-5 uppercase"
        >
          <TabItem label="Top Visited">
            <div className="h-full bg-custom-gradient p-6">
              <TopTrending
                title="Top Visited Products"
                selectOptions={selectOptions}
                products={topRatedProducts}
                buttonText="Add Product to Top Rated"
                onAddProduct={() => {}}
                ratingImage={star}
                showAddButton={false}
              />
            </div>
          </TabItem>
          <TabItem label="Top Liked">
            <div className="h-full bg-custom-gradient p-6">
              <TopTrending
                title="Top Liked Products"
                selectOptions={selectOptions}
                products={topRatedProducts}
                buttonText="Add Product to Top Rated"
                onAddProduct={() => {}}
                ratingImage={heart}
                showAddButton={false}
              />
            </div>
          </TabItem>
          <TabItem label="Top Recomended">
            <div className="h-full bg-custom-gradient p-6">
              <TopTrending
                title="Top Recomended Products"
                selectOptions={selectOptions}
                products={topRatedProducts}
                buttonText="Add Product to Top Rated"
                onAddProduct={() => {}}
                ratingImage={share}
                showAddButton={false}
              />
            </div>
          </TabItem>
        </TabList>
      </div>
    </div>
  );
};

export default UserTopProducts;
