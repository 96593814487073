import React from 'react';

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

interface CardType {
  title: string;
  prefix: string;
  value: number;
  icon: any;
}

interface SummaryCardProps {
  card: CardType;
}

const SummaryCard: React.FC<SummaryCardProps> = ({card}) => {
  return (
    <div className="rounded-2xl bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex h-full flex-col gap-1 rounded-2xl bg-custom-gradient px-4 py-3 shadow-custom-shadow">
        <div className="flex items-center justify-between">
          <p className="text-[13px] uppercase tracking-widest text-[#08bbd1]">
            {card.title}
          </p>
          <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
            {selectOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <p className="text-3xl font-semibold tracking-wide">
          {card.prefix}
          {card.value.toLocaleString()}
        </p>
        <p className="mb-2 text-xs capitalize text-gray-400">Per Week</p>
        <div className="absolute bottom-0 right-0 flex h-16 w-48 justify-center">
          <img
            src={card.icon}
            alt="Dashboard Summary Card Icon"
            className="h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
