import React, {useState} from 'react';
import ProfileCard from '../../components/core/AccountInfo/ProfileCard';
import DropDownFilter from '../../components/common/DropDownFilter';
import AdminForms from '../../components/core/AccountInfo/AdminForms';
import Flyover from '../../components/common/Flyover';
import UserSelect from '../../components/common/UserSelect';

const Settings = () => {
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
  };

  const options = [
    {label: 'Option 1', value: 'option1'},
    {label: 'Option 2', value: 'option2'},
    {label: 'Option 3', value: 'option3'},
  ];
  return (
    <>
      <div
        id="account-information"
        className="relative grid w-full grid-cols-3 gap-8 py-4 md-lt:grid-cols-1"
      >
        <div className="col-span-1 flex flex-col gap-8">
          <ProfileCard showSocials={false} name={'David Lucas'} />

          <div onClick={() => setDeleteAccount(true)}>
            <DropDownFilter
              width="full"
              classes=""
              placeholder="Delete Account"
              options={[]}
              activeOption=""
              disabled={true}
              onChange={() => {}}
            />
          </div>
        </div>

        <div className="col-span-2 md-lt:col-span-1">
          <AdminForms />
        </div>
      </div>
      {deleteAccount && (
        <Flyover isOpen={deleteAccount} onClose={() => setDeleteAccount(false)}>
          <div className="flex min-h-screen p-4">
            <div className="w-full max-w-md shadow-md">
              <h2 className="mb-6 text-lg font-bold text-white">
                Delete Account
              </h2>

              <div className="mb-6">
                <label
                  htmlFor="new-password"
                  className="mb-2 block text-sm font-medium text-white"
                >
                  I am leaving because...
                </label>
                <UserSelect
                  label="Choose an Option"
                  value={selectedValue}
                  options={options}
                  onChange={handleSelectChange}
                />
              </div>

              <div className="mt-4 flex space-x-4">
                <button className="relative rounded-[1.5rem] p-[1px]">
                  <div
                    className="flex w-[170px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                    style={{
                      background:
                        'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                    }}
                  >
                    Save Changes
                  </div>
                </button>

                <button className="w-[170px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Flyover>
      )}
    </>
  );
};

export default Settings;
