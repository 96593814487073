import ytbutton from '../../../assets/images/icons/ytbutton.png';
import {useDispatch, useSelector} from 'react-redux';
import {setProductDetailView} from '../../../store/General/General';
import eye from '../../../assets/images/icons/eye-with-no-bg.png';
import {useNavigate, useLocation} from 'react-router-dom';

const RejectedProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const containsCreatorDetail = location.pathname.includes('creator-detail');
  const dispatch = useDispatch();
  const openDetail = useSelector(
    (state: any) => state.general.productDetailView,
  );

  return (
    <>
      {[...Array(5)].map((_, index) => (
        <div
          key={index}
          className="mb-4 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]"
        >
          <div className="relative flex items-center justify-between rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
            <div className="mr-6 flex w-[20%] items-start space-x-4">
              <img
                src={ytbutton}
                alt="YouTube App"
                className="h-[70px] w-[70px] rounded-md"
              />
              <div>
                <p className="text-lg font-semibold text-white">YouTube</p>
                <p className="text-sm text-gray-400">App</p>
                <p className="text-[9px] text-gray-400">Video, Entertainment</p>
              </div>
            </div>

            <div className="ml-6 w-[40%] flex-grow">
              <p className="text-sm font-medium text-gray-300">
                Product Description
              </p>
              <p className="mt-1 text-xs leading-tight text-gray-400">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Maecenas placerat euismod sem eu consectetur. Nullam efficitur
                lacus ut ante pulvinar, vestibulum semper eros efficitur.
              </p>
            </div>

            <div className="ml-6 flex w-[35%] items-center justify-end space-x-6">
              <span className="rounded-full bg-[#ff17534f] px-3 py-1 text-xs text-[#f04438ff]">
                Rejected
              </span>
              <img
                src={eye}
                alt="Detail"
                onClick={() =>
                  containsCreatorDetail
                    ? dispatch(setProductDetailView(true))
                    : navigate('/manage-products/review-a-product/1')
                }
                className="w-[30px]"
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default RejectedProducts;
