import {useNavigate} from 'react-router-dom';

import Input from '../../components/common/InputNew';
import Button from '../../components/ui/Button';

import logo from '../../assets/images/logo/lusso.png';

import {useForm} from 'react-hook-form';
import {z, ZodType} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {toast} from 'react-toastify';
import {useResetPassword} from '../../hooks/authQueryHooks';

// import {useResetPassword} from '../../hooks/authQueryHooks';

interface FormData {
  confirmPassword: string;
  password: string;
}

const ResetPasswordSchema: ZodType<FormData> = z
  .object({
    password: z
      .string()
      .min(1, {message: 'Password is required'}) // Ensures non-empty password
      .min(8, {message: 'Password must be at least 8 characters long'}), // Ensures password has a minimum length

    confirmPassword: z
      .string()
      .min(1, {message: 'Password is required'}) // Ensures non-empty password
      .min(8, {message: 'Password must be at least 8 characters long'}), // Ensures password has a minimum length
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords do not match', // Error message if passwords do not match
    path: ['confirmPassword'], // Path to highlight the error on the confirmation field
  });

const ResetPassword = () => {
  const navigate = useNavigate();

  const {mutate, isPending} = useResetPassword();

  const {
    register,
    handleSubmit,
    formState: {errors},
    // setError,
  } = useForm<FormData>({
    resolver: zodResolver(ResetPasswordSchema),
  });

  const onSubmit = async (data: FormData) => {
    const req = {body: data, token: ''};
    mutate(req, {
      onSuccess: () => {
        toast.success('Password changed successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate('/login');
      },
      onError: () => {
        toast.error('Failed to change password', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
    });
  };

  return (
    <div className="mx-auto flex w-2/6 flex-col gap-8 text-white">
      <img src={logo} alt="" className="w-1/2 self-center" />
      <div>
        <h4 className="mb-6 text-center text-xl font-medium tracking-wide">
          Reset Password
        </h4>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Enter new password"
            // disabled
            type="password"
            className="text-xs"
            name="password"
            register={register}
            error={errors.password}
            required
          />
          <Input
            placeholder="Re-enter new password"
            // disabled
            type="password"
            className="text-xs"
            name="confirmPassword"
            register={register}
            error={errors.confirmPassword}
            required
          />
          <Button
            label={'Change Password'}
            className="relative w-full rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
            type="submit"
            isLoading={isPending}
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
