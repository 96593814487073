import React from 'react';
import visa from '../../../assets/images/icons/visa.png';
import card from '../../../assets/images/icons/card.png';
interface PaymentMethodProps {
  title: string;
  cardNumber: string;
  planType: string;
  nextBillDate: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  title,
  cardNumber,
  planType,
  nextBillDate,
}) => {
  return (
    <div className="mt-4 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative rounded-md bg-custom-gradient p-6 shadow-custom-shadow">
        <h3 className="mb-4 text-lg font-semibold text-teal-400">{title}</h3>
        <div className="flex-start mb-4 flex text-xs text-white">
          <img src={visa} className="w-13" alt="visa" />{' '}
          <span className="mr-2 mt-2 text-[24px]">**** {cardNumber}</span>
        </div>
        <p className="mb-1 text-sm text-gray-400">
          Plan type <span className="font-semibold text-white">{planType}</span>
        </p>
        <p className="text-sm text-gray-400">
          Next bill date{' '}
          <span className="font-semibold text-white">{nextBillDate}</span>
        </p>
        <div className="absolute right-6 top-16">
          <img src={card} alt="card" className="w-12" />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
