import React from 'react';
import {PRIVACY_POLICY} from '../../constants/privacyPolicy';
import Button from '../ui/Button';

const termsOfService = PRIVACY_POLICY;

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const Policy: React.FC<ModalProps> = ({isOpen, onClose, title}) => {
  if (!isOpen) return null;

  // Function to handle clicks on the backdrop
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Close modal only if the click is on the backdrop, not on the modal itself
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleBackdropClick} // Attach the click handler here
    >
      <div className="h-[90%] w-3/5">
        <div className="no-scrollbar relative h-full overflow-auto rounded-xl bg-custom-gradient shadow-custom-shadow">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 w-10 text-3xl text-white"
          >
            &times; {/* Close icon */}
          </button>
          <div className="mx-auto px-12 py-12 text-white">
            <h1 className="mb-6 text-center text-4xl font-bold">{title}</h1>
            <hr className="mb-4 border-gray-700" />
            <div className="flex flex-col gap-6">
              {termsOfService.map(term => (
                <div key={term.id}>
                  <h2 className="mb-1 text-base font-semibold">
                    {term.id}. {term.title}
                  </h2>
                  <p className="text-sm leading-relaxed text-gray-300">
                    {term.description}
                  </p>
                </div>
              ))}
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-500"
                />
                <span className="text-[0.6rem] italic text-gray-400">
                  "I have read and agree to the Privacy Policy."
                </span>
              </div>
            </div>
            <div className="mt-6">
              <Button
                label="Accept & Continue"
                className="rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
