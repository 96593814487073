import React, {useEffect} from 'react';
import RejectedProducts from '../../components/core/Product/RejectedProducts';
import Pagination from '../../components/common/Pagination';

const RejectedProduct = () => {
  // Set the default active tab to "account-information"
  return (
    <>
      <div className="mb-4 flex flex-col gap-8 text-white">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl">
            <span className="font-light tracking-wider">Rejected</span> Products
          </h1>
        </div>
      </div>
      <RejectedProducts />
      <Pagination />
    </>
  );
};

export default RejectedProduct;
