import SelectDropdown from '../../common/SelectDropdown';
import Input from '../../common/Input';
import ToggleSwitch from '../../ui/ToggleSwitch';

const ageOptions = [
  {value: '', label: 'Select your age'},
  {value: '18-24', label: '18-24'},
  {value: '24-60', label: '24-60'},
  {value: '60+', label: '60+'},
];

interface UserFormsProps {
  userDetails: any;
}
const UserForms: React.FC<UserFormsProps> = ({userDetails}) => {
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] text-white">
        <div className="relative flex flex-col gap-4 rounded-md bg-[#16122b] p-8 shadow-custom-shadow">
          <Input
            label="Name"
            placeholder="Name"
            value={userDetails?.fullName || userDetails?.username}
            onChange={() => {}}
            disabled
            className="text-xs"
          />
          <Input
            label="Email"
            placeholder="email"
            value={userDetails?.email}
            type="email"
            disabled
            className="text-xs"
            onChange={() => {}}
          />
          <Input
            label="Country"
            placeholder="Country"
            value={userDetails?.country}
            disabled
            className="text-xs"
            onChange={() => {}}
          />
          <Input
            label="State"
            placeholder="State"
            value={userDetails?.state}
            disabled
            className="text-xs"
            onChange={() => {}}
          />
          <SelectDropdown
            label="Age group"
            disabled
            className="text-xs"
            value={ageOptions?.find(i => i?.label === userDetails?.ageGroup)}
            options={ageOptions}
          />
        </div>
      </div>
      <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] text-white">
        <div className="relative flex flex-col gap-2 rounded-md bg-[#16122b] p-8 shadow-custom-shadow">
          <span className="text-[0.845rem] font-normal text-gray-500">
            Notification
          </span>
          <div className="flex flex-col gap-5">
            <ToggleSwitch label="All Notification Active" />
            <ToggleSwitch label="Email Notification Active" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserForms;
