import SummaryCard from '../../components/core/Dashboard/SummaryCard';
import TopRatedItems from '../../components/core/Dashboard/TopRatedItems';
import TopRatedPeople from '../../components/core/Dashboard/TopRatedPeople';
import ProductsOfDay from '../../components/core/Dashboard/ProductsOfDay';
import Button from '../../components/ui/Button';
import downArrow from '../../assets/images/icons/down-arrow.png';
import rightArrow from '../../assets/images/icons/right-arrow.png';
import ScheduledPosts from '../../components/core/Dashboard/ScheduledPosts';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import CircularProgress from '../../components/core/Dashboard/CircularProgress';

import dashbord1 from '../../assets/images/misc/dashboard-2.png';
import dashbord2 from '../../assets/images/misc/dashboard-4.png';
import dashbord3 from '../../assets/images/misc/dashboard-1.png';
import dashbord4 from '../../assets/images/misc/dashboard-3.png';

const cardsData = [
  {
    title: 'Active Creators',
    value: 451,
    prefix: '',
    icon: dashbord1,
  },
  {
    title: 'Total Users',
    value: 456502,
    prefix: '',
    icon: dashbord2,
  },
  {
    title: 'Revinue Generated',
    value: 3205,
    prefix: '$',
    icon: dashbord3,
  },
  {
    title: 'Pending Approval',
    value: 402,
    prefix: '',
    icon: dashbord4,
  },
];

const data = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  datasets: [
    {
      label: 'Likes',
      data: [
        180000, 400000, 350000, 450000, 650000, 500000, 600000, 700000, 650000,
        500000, 500000,
      ],
      borderColor: '#573bae',
      backgroundColor: '#573bae',
      tension: 0.4,
      pointRadius: 0,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#ffffff',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 1000000,
      ticks: {
        stepSize: 200000,
        color: '#ffffff',
      },
      grid: {
        color: '#424f9c',
      },
      border: {
        display: false,
      },
    },
  },
};

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

const Dashboard = () => {
  return (
    <div className="flex w-full flex-col gap-8 text-white">
      <div>
        <p className="text-sm uppercase tracking-widest text-[#b1adcd] md-lt:text-xs">
          Dashboard overview
        </p>
        <h1 className="text-4xl font-light md-lt:text-3xl">
          Welcome, <span className="font-semibold">Sarah</span>
        </h1>
      </div>

      <div className="grid grid-cols-4 gap-6 md-lt:grid-cols-1">
        {cardsData.map((card, index) => (
          <SummaryCard card={card} key={index} />
        ))}
      </div>

      <h1 className="px-4 text-3xl font-light md-lt:text-2xl">
        This Week At <span className="font-semibold">Lusso</span>
      </h1>
      <div className="grid grid-cols-3 gap-6 md-lt:grid-cols-1">
        <TopRatedItems />
        <TopRatedPeople />
      </div>

      <h1 className="px-4 text-3xl font-light md-lt:text-2xl">
        Promoted <span className="font-semibold">Products</span>
      </h1>
      <ProductsOfDay />

      <h1 className="px-4 text-3xl font-light md-lt:text-2xl">
        Marketing <span className="font-semibold">Performance</span>
      </h1>
      <div className="grid grid-cols-3 gap-6 md-lt:grid-cols-1">
        <div className="col-span-2 h-full md-lt:col-span-1 md-lt:h-96">
          <CurveLineGraph
            data={data}
            options={options}
            title={'Total Subscription Profit'}
            showLabels={false}
          />
        </div>
        <div className="h-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
          <div className="flex h-full flex-col gap-4 rounded-md bg-custom-gradient px-4 py-6 shadow-custom-shadow">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl text-white">Revinue Goal</h2>
              <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
                {selectOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <CircularProgress percent={75} />
            <div className="flex items-center justify-between">
              <p className="text-xs text-gray-400">
                You reached $103.577 from 150.000
              </p>
              <Button
                label="Show All"
                icon={{
                  component: (
                    <img src={rightArrow} className="w-4" alt="arrow" />
                  ),
                  position: 'end',
                }}
                className="rounded-full bg-[#1a6bcb] px-3 py-1 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between px-4 md-lt:flex-col md-lt:items-start md-lt:gap-3">
        <h1 className="text-3xl font-light md-lt:text-2xl">
          Social Media <span className="font-semibold">Analytics</span>
        </h1>
        <div className="flex items-center gap-4 md-lt:w-full md-lt:justify-end">
          <Button
            label="11-20 JAN"
            icon={{
              component: (
                <img src={downArrow} className="w-5 md-lt:w-4" alt="arrow" />
              ),
              position: 'end',
            }}
            className="rounded-md bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:px-3 md-lt:text-sm"
          />
          <Button
            label="View All"
            className="rounded-md bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:px-3 md-lt:text-sm"
          />
        </div>
      </div>
      <ScheduledPosts />
    </div>
  );
};

export default Dashboard;
