import axios from 'axios';
import {toast} from 'react-toastify';

// Base Axios instance configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Attach Authorization header if token exists
      config.headers = {
        ...config.headers, // Keep existing headers
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error: any) => {
    // Log request error for debugging purposes
    console.error('Request Error:', error);
    return Promise.reject(error);
  },
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response: any) => response, // Pass successful responses through unchanged
  (error: any) => {
    if (error.response) {
      const {status} = error.response;

      // Handle specific error statuses
      switch (status) {
        case 401: {
          toast.error('Unauthorized! Redirecting to login...', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });

          // Redirect after a delay to allow user to see the message
          localStorage.removeItem('authToken');
          setTimeout(() => {
            window.location.href = '/login';
          }, 3000);
          break;
        }
        case 500: {
          toast.error('Internal Server Error! Please try again later.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
          break;
        }
        default: {
          // Generic error handler for other statuses
          toast.error(`Error ${status}: ${'Something went wrong!'}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      }
    } else {
      // Handle network or unexpected errors
      toast.error('Network error! Please check your internet connection.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      console.error('Unexpected Error:', error);
    }

    return Promise.reject(error); // Always reject to allow caller to handle errors
  },
);

export {axiosInstance};
