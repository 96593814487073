import {ReactComponent as Dashboard} from '../assets/images/icons/dashboard.svg';
import {ReactComponent as Users} from '../assets/images/icons/creator.svg';
import {ReactComponent as Creator} from '../assets/images/icons/users.svg';
import {ReactComponent as ManageProducts} from '../assets/images/icons/manage-products.svg';
import {ReactComponent as ManageAccess} from '../assets/images/icons/manage-access.svg';
import {ReactComponent as Analytics} from '../assets/images/icons/analytics.svg';
import {ReactComponent as Revenue} from '../assets/images/icons/revenue.svg';
// import {ReactComponent as Campaign} from '../assets/images/icons/compaign-1.svg';
import {ReactComponent as Subscription} from '../assets/images/icons/subscription.svg';
import {ReactComponent as PostSchduling} from '../assets/images/icons/Add-To-Queue.svg';
import {ReactComponent as NotificationBell} from '../assets/images/icons/notification.svg';
import {ReactComponent as Gear} from '../assets/images/icons/gear.svg';
// import {ReactComponent as Logout} from '../assets/images/icons/logout.svg';

export const ENUM_NAV = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/',
    icon: Dashboard,
  },
  {
    id: 2,
    title: 'Creator',
    path: '/manage-creator',
    icon: Creator,
  },
  {
    id: 3,
    title: 'Users',
    path: '/manage-users',
    icon: Users,
  },
  {
    id: 4,
    title: 'Products',
    path: '/manage-products',
    icon: ManageProducts,
  },
  {
    id: 5,
    title: 'Analytics',
    path: '/analytics',
    icon: Analytics,
  },
  {
    id: 6,
    title: 'Subscriptions',
    path: '/subscriptions',
    icon: Subscription,
  },
  {
    id: 7,
    title: 'Post Scheduling',
    path: '/post-scheduling',
    icon: PostSchduling,
  },
  {
    id: 8,
    title: 'Revenue',
    path: '/revenue',
    icon: Revenue,
  },
  {
    id: 9,
    title: 'Access',
    path: '/manage-access',
    icon: ManageAccess,
  },
  // {
  //   id: 9,
  //   title: 'Campaign',
  //   path: '/campaign',
  //   icon: Campaign,
  // },
  {
    id: 10,
    title: 'Notifications',
    path: '/notifications',
    icon: NotificationBell,
  },
  {
    id: 11,
    title: 'Settings',
    path: '/settings',
    icon: Gear,
  },
  // {
  //   id: 12,
  //   title: 'Logout',
  //   path: '/logout',
  //   icon: Logout,
  // },
];
