import {useEffect, useState} from 'react';

import Input from '../../components/common/InputNew';
import Button from '../../components/ui/Button';

import logo from '../../assets/images/logo/lusso.png';

import {useForm} from 'react-hook-form';
import {z, ZodType} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {toast} from 'react-toastify';

import {useForgotPassword} from '../../hooks/authQueryHooks';

interface FormData {
  email: string;
}

const ForgotPasswordSchema: ZodType<FormData> = z.object({
  email: z
    .string()
    .min(1, {message: 'Email is required'}) // Ensures non-empty email
    .email({message: 'Please enter a valid email address'}), // Ensures it's a valid email
});

const ForgotPassword = () => {
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  const {mutate, isPending} = useForgotPassword();

  const {
    register,
    handleSubmit,
    formState: {errors},
    // setError,
  } = useForm<FormData>({
    resolver: zodResolver(ForgotPasswordSchema),
  });

  const onSubmit = async (data: FormData) => {
    mutate(data.email, {
      onSuccess: () => {
        toast.success('Reset password link sent successfully', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setIsResendDisabled(true);
        setResendTimer(60);
      },
      onError: () => {
        toast.error('Failed to send reset password link', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
    });
  };

  useEffect(() => {
    if (isResendDisabled && resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup interval on component unmount
    }
    if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
  }, [isResendDisabled, resendTimer]);

  return (
    <div className="mx-auto flex w-2/6 flex-col gap-8 text-white">
      <img src={logo} alt="" className="w-1/2 self-center" />
      <div>
        <h4 className="mb-2 text-center text-xl font-medium tracking-wide">
          Forgot Password
        </h4>
        <p className="mb-6 text-center text-base font-light">
          We will send you a link to reset your password
        </p>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Enter your email"
            // disabled
            className="text-xs"
            name="email"
            register={register}
            error={errors.email}
            required
          />
          <Button
            label={isResendDisabled ? 'Please Wait....' : 'Reset Password'}
            disabled={isResendDisabled}
            className="relative w-full rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
            type="submit"
            isLoading={isPending}
          />
          {isResendDisabled && (
            <div className="mt-5 flex flex-row items-center justify-center gap-4 text-sm font-normal">
              <span className="text-white/50">
                Resend link in{' '}
                <em className="font-medium not-italic text-white">
                  {resendTimer < 10
                    ? `00:0${resendTimer}`
                    : `00:${resendTimer}`}
                </em>
              </span>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
