/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo, useRef, ReactNode} from 'react';
import {usePopper} from 'react-popper';

interface PopoverManagerProps {
  button: ReactNode;
  content: ReactNode;
  position: any;
  hidePopover?: boolean;
}

const PopoverManager: React.FC<PopoverManagerProps> = ({
  button,
  content,
  position,
  hidePopover = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const popoverRef = useRef<HTMLElement | null>(null);

  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: position,
  });

  const handleTogglePopover = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setReferenceElement(event.currentTarget);
    setIsOpen(prev => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node) &&
      referenceElement &&
      !referenceElement.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (hidePopover) setIsOpen(false);
  }, [hidePopover]);

  useMemo(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverRef, referenceElement]);

  return (
    <div>
      {React.cloneElement(button as React.ReactElement, {
        onClick: handleTogglePopover,
        ref: setReferenceElement,
      })}
      {isOpen && (
        <div
          ref={el => {
            setPopperElement(el);
            popoverRef.current = el;
          }}
          style={styles.popper}
          {...attributes.popper}
          className="popover-content z-10"
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default PopoverManager;
