import React, {useState} from 'react';

interface PaginationProps {
  totalRecords?: number;
  fetchedRecords?: number;
  start?: number;
  onPageChange?: (newStart: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalRecords = 0,
  fetchedRecords = 0,
  start = 0,
  onPageChange = undefined,
}) => {
  const totalPages = Math.ceil(totalRecords / fetchedRecords);
  const [currentPage, setCurrentPage] = useState(
    Math.floor(start / fetchedRecords) + 1,
  );
  const [pageLimit, setPageLimit] = useState(5);

  const goToPage = (page: number) => {
    if (page !== currentPage) {
      // Avoid re-triggering for the current page
      const newStart = (page - 1) * fetchedRecords;
      setCurrentPage(page);
      if (onPageChange) {
        onPageChange(newStart);
      }
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  };

  const visiblePages = Array.from(
    {length: Math.min(totalPages, pageLimit)},
    (_, index) => index + 1,
  );

  const handleMorePages = () => {
    setPageLimit(prev => prev + 5);
  };

  return (
    <div className="float-right flex items-center space-x-4 py-4 text-sm text-white">
      <button
        className="flex items-center text-gray-400 transition hover:text-white disabled:text-gray-400"
        onClick={goToPreviousPage}
        disabled={currentPage === 1}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="mr-1 h-4 w-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Previous
      </button>

      <div className="flex items-center space-x-2">
        {visiblePages.map(page => (
          <button
            key={page}
            onClick={() => goToPage(page)}
            className={`text-md flex h-8 w-8 items-center justify-center rounded-[9px] font-semibold ${
              page === currentPage
                ? 'border border-[#561c9e] text-white'
                : 'text-gray-400 hover:text-gray-300'
            }`}
            style={
              page === currentPage
                ? {
                    background:
                      'linear-gradient(125.12deg, rgba(45, 36, 108, 0.9) 6.52%, rgba(22, 19, 43, 0.5) 30.66%, rgba(24, 20, 46, 0.5) 63.49%, rgba(37, 32, 74, 0.9) 78.95%)',
                  }
                : {}
            }
          >
            {page}
          </button>
        ))}
        {totalPages > pageLimit && (
          <>
            <span className="text-gray-400">...</span>
            <button onClick={handleMorePages} className="hover:text-gray-300">
              {totalPages}
            </button>
          </>
        )}
      </div>

      <button
        className="flex items-center text-gray-400 transition hover:text-white disabled:text-gray-400"
        onClick={goToNextPage}
        disabled={currentPage === totalPages}
      >
        Next
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="ml-1 h-4 w-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
