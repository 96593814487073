import React, {useState} from 'react';
import SubscriptionHeader from './SubscriptionHeader';
import Renewal from './Renewal';
import PaymentMethod from './PaymentMethod';
import PlanBenefits from './PlanBenefits';
import Popover from '../../common/Popover';
import Input from '../../common/Input';
import Modal from '../../common/Modal';

import Table from '../../common/Table';
import hike from '../../../assets/images/icons/hike.png';
import visa from '../../../assets/images/icons/visa.png';
import card from '../../../assets/images/icons/card.png';
import Flyover from '../../common/Flyover';

const Subscription = () => {
  const [action, setAction] = useState('');

  const headings = ['DATE', 'PACKAGE', 'PAYMENT METHOD', 'AMOUNT', 'ACTIONS'];
  const rows = [
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
    {
      date: '01 Oct, 2024',
      package: 'ACCESS LUSSO (Monthly)',
      paymentMethod: '**** 9519',
      amount: '500$',
      actions: (
        <button className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6h.01M12 12h.01M12 18h.01"
            />
          </svg>
        </button>
      ),
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.date}</td>
      <td className="px-6 py-4">{row.package}</td>
      <td className="flex items-center px-6 py-4">
        <img src={visa} alt="Visa Logo" className="mr-2 h-4 w-10" />
        {row.paymentMethod}
      </td>
      <td className="px-6 py-4">{row.amount}</td>
      <td className="px-6 py-4">
        <Popover
          button={row.actions}
          content={
            <div className="w-70 z-10 rounded-xl bg-[#18142D] text-white shadow-xl backdrop-blur-sm">
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setAction('payment_detail');
                }}
              >
                View Payment Details
              </button>
              <hr className="border-t border-gray-600" />
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setAction('sent_receipt');
                }}
              >
                Sent Receipt
              </button>
              <hr className="border-t border-gray-600" />
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setAction('refund_payment');
                }}
              >
                Refund Payment
              </button>
            </div>
          }
          position="bottom-end"
        />
      </td>
    </>
  );
  return (
    <>
      <div className="w-full">
        <SubscriptionHeader
          planStartDate="May 1, 2024"
          planName="ACCESS"
          planType="LUSSO"
          price="$99.99/mon"
          trialPeriod="Free 7-day trial"
        />
        <div className="flex w-full">
          <div className="w-[70%] py-4">
            <Table
              title="Transaction History"
              headings={headings}
              rows={rows}
              renderRow={renderRow}
            />
          </div>
          <div className="ml-4 w-[30%] py-4">
            <Renewal
              title="NEXT RENEWAL"
              price="$199"
              planType="Standard (monthly)"
              nextBillDate="Jun 15, 2024"
              planName="Standard"
            />

            <PaymentMethod
              title="PAYMENT METHOD"
              cardNumber="9519"
              planType="Standard (monthly)"
              nextBillDate="Jun 15, 2024"
            />

            <PlanBenefits
              title="PLAN BENEFITS"
              accessPlan="ACCESS"
              lussoPlan="LUSSO"
              benefit1="Free updates for 3 months."
              benefit2="Perfect for small projects and individual use."
              benefit3="Includes 50 digital products and complete customizable."
            />
          </div>
        </div>
      </div>
      {action === 'payment_detail' && (
        <Modal
          isOpen={action === 'payment_detail'}
          onClose={() => setAction('')}
          width="70%"
        >
          <div className="w-full px-8 py-16">
            <div className="pb-4">Paymnet Details</div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="text-4xl font-medium font-normal text-white">
                  $299 USD
                </span>
                <div className="ml-4 flex items-center">
                  <span className="flex rounded-full px-2 py-1 text-sm font-medium text-white">
                    <svg
                      className="mr-2"
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="10.5" cy="10.5" r="10.5" fill="#13B497" />
                      <path
                        d="M14.8814 7.75781L9.39276 13.2462L6.64844 10.5021"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Actions successful
                  </span>
                </div>
              </div>
              <button
                onClick={() => {
                  setAction('refund_payment');
                }}
                className="rounded-lg border border-[rgba(108,140,255,0.5)] bg-[#482296] px-6 py-2 font-bold text-white"
              >
                Refund
              </button>
            </div>
            <hr className="mt-4 border-[1px] border-solid border-[#F2F2F233]" />
            <div className="flex w-full text-white">
              <div className="w-4/5 pr-8">
                {/* Timeline Section */}
                <div className="mb-4 mt-4">
                  <h2 className="mb-4 text-2xl font-bold">Timeline</h2>
                  <div className="relative border-b border-gray-700 pb-4 pl-8">
                    {/* Gradient Line */}
                    <div className="absolute bottom-0 left-[46px] top-0 w-[1px] bg-gradient-to-b from-[#13b497] to-[#1b1b2f]"></div>

                    {/* Timeline Item 1 */}
                    <div className="relative mb-8 flex items-start space-x-4">
                      <div className="flex flex-col items-center">
                        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-500">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4 text-white"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="3"
                          >
                            <path d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <p className="font-bold">Actions successful</p>
                        <p className="text-sm text-gray-400">
                          Nov 11, 2024 8:30PM
                        </p>
                      </div>
                    </div>

                    {/* Timeline Item 2 */}
                    <div className="relative flex items-start space-x-4">
                      <div className="flex flex-col items-center">
                        <div className="flex h-7 w-7 items-center justify-center">
                          <svg
                            width="25"
                            height="25"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.00391 5.25C1.00391 4.65326 1.24096 4.08097 1.66292 3.65901C2.08487 3.23705 2.65717 3 3.25391 3H12.7539C13.3506 3 13.9229 3.23705 14.3449 3.65901C14.7669 4.08097 15.0039 4.65326 15.0039 5.25V11.75C15.0039 12.3467 14.7669 12.919 14.3449 13.341C13.9229 13.7629 13.3506 14 12.7539 14H3.25391C2.65717 14 2.08487 13.7629 1.66292 13.341C1.24096 12.919 1.00391 12.3467 1.00391 11.75V5.25ZM3.25391 4C2.92239 4 2.60444 4.1317 2.37002 4.36612C2.1356 4.60054 2.00391 4.91848 2.00391 5.25V6H14.0039V5.25C14.0039 4.91848 13.8722 4.60054 13.6378 4.36612C13.4034 4.1317 13.0854 4 12.7539 4H3.25391ZM2.00391 11.75C2.00391 12.0815 2.1356 12.3995 2.37002 12.6339C2.60444 12.8683 2.92239 13 3.25391 13H12.7539C13.0854 13 13.4034 12.8683 13.6378 12.6339C13.8722 12.3995 14.0039 12.0815 14.0039 11.75V7H2.00391V11.75ZM10.5039 10H12.0039C12.1365 10 12.2637 10.0527 12.3575 10.1464C12.4512 10.2402 12.5039 10.3674 12.5039 10.5C12.5039 10.6326 12.4512 10.7598 12.3575 10.8536C12.2637 10.9473 12.1365 11 12.0039 11H10.5039C10.3713 11 10.2441 10.9473 10.1504 10.8536C10.0566 10.7598 10.0039 10.6326 10.0039 10.5C10.0039 10.3674 10.0566 10.2402 10.1504 10.1464C10.2441 10.0527 10.3713 10 10.5039 10Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <p className="font-bold">Payment started</p>
                        <p className="text-sm text-gray-400">
                          Nov 11, 2024 8:30PM
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Payment Breakdown Section */}
                <div className="mb-4">
                  <h2 className="mb-4 text-2xl font-bold">Payment Breakdown</h2>
                  <div className="space-y-2 border-b border-gray-700 pb-4">
                    <div className="flex justify-between">
                      <span>Payment Amount</span>
                      <span>$299 USD</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Tax</span>
                      <span>$0 USD</span>
                    </div>
                    <div className="flex justify-between font-bold">
                      <span>Net Amount</span>
                      <span>$299 USD</span>
                    </div>
                  </div>
                </div>

                {/* Payment Method Section */}
                <div>
                  <h2 className="mb-4 text-2xl font-bold">Payment Method</h2>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span>Card Number</span>
                        <span>**** **** **** 9519</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Expires</span>
                        <span>12/25</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Type</span>
                        <span>Master Card</span>
                      </div>
                      <div className="flex justify-between">
                        <span>Issuer</span>
                        <span>Name on the card</span>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span>Card Number</span>
                        <span>**** **** **** 9519</span>
                      </div>
                      <div className="flex justify-between">
                        <span>CVV</span>
                        <span className="text-green-500">
                          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-green-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-white"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="3"
                            >
                              <path d="M5 13l4 4L19 7" />
                            </svg>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Details Section */}
              <div className="mt-8 w-1/5 pl-8">
                <h2 className="mb-4 text-2xl font-bold">Details</h2>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-400">Payment method</p>
                    <p className="font-bold">VISA **** 9519</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Package</p>
                    <p className="font-bold">ACCESS LUSSO (Monthly)</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Statement distributed</p>
                    <p className="font-bold">Creator Name</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Last update</p>
                    <p className="font-bold">Nov 11, 2024</p>
                  </div>
                  <div>
                    <p className="text-gray-400">Risk Evaluation</p>
                    <p className="font-bold">Normal</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {action === 'sent_receipt' && (
        <Flyover
          isOpen={action === 'sent_receipt'}
          onClose={() => setAction('')}
        >
          <div className="mt-6">
            <label className="mb-4 block text-xl font-medium text-white">
              Sent Receipt
            </label>

            <div className="mb-4 mt-4">
              <div className="relative w-full">
                <Input
                  label="Deliver To"
                  placeholder="Name"
                  value={''}
                  onChange={() => {}}
                  disabled
                  className="text-xs"
                />
              </div>
            </div>

            <label className="inline-flex items-center">
              <span className="text-gray ml-2 text-xs text-[#B1ADCD]">
                Separate multiple email address with commas.
              </span>
            </label>

            <div className="mt-4 flex space-x-4">
              <button className="relative rounded-[1.5rem] p-[1px]">
                <div
                  className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                  style={{
                    background:
                      'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                  }}
                >
                  Sent
                </div>
              </button>

              <button
                onClick={() => setAction('')}
                className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        </Flyover>
      )}
      {action === 'refund_payment' && (
        <Flyover
          isOpen={action === 'refund_payment'}
          onClose={() => setAction('')}
        >
          <div className="flex flex-col text-white">
            <div className="p-4">
              <h1 className="mb-4 text-xl font-semibold">Refund Payment</h1>
              <p className="mb-6 flex items-center text-sm text-gray-300">
                <svg
                  className="mr-2 h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v2m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Refunds take 5–10 days to appear on a customer’s statement.
                There are no additional fees for the refund.
              </p>

              <form>
                <div className="mb-4 flex items-center">
                  <Input
                    label="Refund"
                    placeholder="99.9"
                    value={''}
                    onChange={() => {}}
                    disabled
                    className="mr-4 w-[350px!important] text-xs"
                  />
                  <div className="mb-4 mt-4">
                    <label className="block text-xs font-medium text-white"></label>
                    <div className="relative w-full">
                      <select
                        className={`mt-6 w-[80px] appearance-none rounded-full border-2 border-[#3c3e5c] bg-[#2E246C33] px-4 py-2 text-[0.75rem] outline-none hover:border-[#ffffff99] focus:border-[#2684ff]`}
                      >
                        <option selected>USD</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-2 right-3 mt-6 flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="reason"
                    className="mb-2 block text-sm font-medium text-gray-300"
                  >
                    Reason
                  </label>
                  <textarea
                    id="reason"
                    placeholder="Admin can add more detail about the refund"
                    className="h-[100px] w-full rounded-md border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                  ></textarea>
                </div>

                <div className="mt-4 flex space-x-4">
                  <button className="relative rounded-[1.5rem] p-[1px]">
                    <div
                      className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                      style={{
                        background:
                          'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                      }}
                    >
                      Sent
                    </div>
                  </button>

                  <button
                    onClick={() => setAction('')}
                    className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Flyover>
      )}
    </>
  );
};

export default Subscription;
