import {useState, useCallback} from 'react';
import fb from '../../../assets/images/icons/fb.png';
import insta from '../../../assets/images/icons/insta.png';
import linkedin from '../../../assets/images/icons/linkedin.png';
import google from '../../../assets/images/icons/google.png';
import Pagination from '../../common/Pagination';
import eye from '../../../assets/images/icons/eye-with-no-bg.png';
import {useDispatch, useSelector} from 'react-redux';
import {setProductDetailView} from '../../../store/General/General';
import {useNavigate, useLocation} from 'react-router-dom';

type Product = {
  id: string;
  name: string;
  category: string;
  subCategory: string;
  description: string;
  bannerImage: string;
};

type ActiveProductsProps = {
  products: Product[];
  totalRecords: number;
  fetchedRecords: number;
  start: number;
  onPageChange: (newStart: number) => void;
};

const ActiveProducts: React.FC<ActiveProductsProps> = ({
  products = [],
  totalRecords = 0,
  fetchedRecords = 0,
  start = 0,
  onPageChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const containsCreatorDetail = location.pathname.includes('creator-detail');
  const dispatch = useDispatch();
  const openDetail = useSelector(
    (state: any) => state.general.productDetailView,
  );

  return (
    <>
      {products.map((item: any, index: number) => (
        <div
          key={index}
          className="mb-4 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]"
          onClick={() => {
            navigate(`/manage-products/review-a-product/${item.productId}`);
          }}
        >
          <div className="relative flex items-center justify-between rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
            <div className="mr-6 flex w-[25%] items-start space-x-4">
              <img
                src={item.bannerImage}
                alt="YouTube App"
                className="h-[70px] w-[70px] rounded-md"
              />
              <div>
                <p
                  className="text-lg font-semibold text-white"
                  title={item.name}
                >
                  {item.name.length > 14
                    ? item.name.slice(0, 14) + '...'
                    : item.name}
                </p>
                <p className="text-sm text-gray-400">{item.category}</p>
                <p className="text-[9px] text-gray-400">{item.subCategory}</p>
              </div>
            </div>

            <div className="ml-6 w-[30%] flex-grow">
              <p className="text-sm font-medium text-gray-400">
                Product Description
              </p>
              <p className="mt-1 text-xs leading-tight text-gray-300">
                {item.description?.length > 90
                  ? item.description?.slice(0, 90) + '...'
                  : item.description}
              </p>
            </div>

            <div className="ml-6 flex w-[15%] flex-grow flex-col items-start">
              <p className="font-small mb-1 text-sm text-gray-400">Reviews</p>
              <div className="flex space-x-1 pt-1">
                <svg
                  className="h-6 w-6 text-[#00f7ff]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                </svg>
                <svg
                  className="h-6 w-6 text-[#00f7ff]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                </svg>
                <svg
                  className="h-6 w-6 text-[#00f7ff]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                </svg>
                <svg
                  className="h-6 w-6 text-[#00f7ff]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                </svg>
                <svg
                  className="h-6 w-6 text-[#00f7ff]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 .587l3.668 7.568L24 9.423l-6 5.84 1.417 8.74L12 18.812l-7.417 5.191L6 15.263 0 9.423l8.332-1.268L12 .587z" />
                </svg>
              </div>
            </div>

            <div className="ml-6 flex w-[15%] flex-grow flex-col items-start">
              <p className="font-small mb-1 text-sm text-gray-400">
                Active Promotions
              </p>
              <div className="flex space-x-4 pt-1">
                <a href="#" className="hover:opacity-80">
                  <img src={insta} alt="Instagram" className="h-5 w-5" />
                </a>
                <a href="#" className="hover:opacity-80">
                  <img src={fb} alt="Facebook" className="h-5 w-5" />
                </a>
                <a href="#" className="hover:opacity-80">
                  <img src={linkedin} alt="LinkedIn" className="h-5 w-5" />
                </a>
                <a href="#" className="hover:opacity-80">
                  <img src={google} alt="Other" className="h-5 w-5" />
                </a>
              </div>
            </div>

            <div className="ml-6 w-[10%] flex-grow">
              <button className="rounded-full bg-[#192039] px-4 py-1 text-sm font-medium text-green-400 shadow-md">
                Active
              </button>
            </div>

            <div className="ml-6 w-[10%] flex-grow">
              <img src={eye} alt="Detail" className="w-[30px]" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ActiveProducts;
