import dp from '../../assets/images/icons/default-pic.jpg';
interface AvatarMenuProps {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  buttonRef?: React.Ref<HTMLDivElement>;
}
const AvatarMenu: React.FC<AvatarMenuProps> = ({onClick, buttonRef}) => {
  return (
    <div
      className="flex items-center gap-4 rounded-full bg-[#12122b] p-2"
      onClick={onClick}
      ref={buttonRef}
    >
      {/* Profile Picture */}
      <img
        src={dp}
        alt="Profile"
        className="h-8 w-8 rounded-full object-cover"
      />
      {/* Profile Information */}
      <div className="mr-2 w-32">
        <div className="flex items-center justify-between">
          <p className="text-xs text-white">David Lucas</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              className="h-4 w-4"
            >
              <path d="M7 10l5 5 5-5z" />
            </svg>
          </span>
        </div>
        <div className="flex items-center">
          <p className="mr-2 text-xs text-gray-400">72%</p>
          <div className="relative h-1 w-full overflow-hidden rounded-full bg-gray-700">
            <div className="absolute left-0 top-0 h-full w-[72%] rounded-full bg-cyan-400"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarMenu;
