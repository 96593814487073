import React from 'react';
import Popover from './Popover';

interface MenuItem {
  label: string;
  action: () => void;
}

interface TableDropdownMenuProps {
  menuItems: MenuItem[];
  dropdownWidth?: 'lg' | 'sm';
}

const TableDropdownMenu: React.FC<TableDropdownMenuProps> = ({
  menuItems,
  dropdownWidth = 'lg',
}) => {
  return (
    <Popover
      button={
        <button
          type="button"
          className={
            'rounded-full bg-transparent p-1 text-white hover:bg-[#00000022] active:bg-[#00000044]'
          }
          style={{border: 'none'}}
          onClick={e => e.stopPropagation()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 0 24 24"
            width="20"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              stroke="#fff"
              fill="#fff"
            />
          </svg>
        </button>
      }
      content={
        <div
          className={`z-10 ${dropdownWidth === 'sm' ? 'w-20' : 'w-40'} overflow-hidden rounded-xl bg-[#18142D] text-white shadow-xl backdrop-blur-sm`}
          onClick={e => e.stopPropagation()}
        >
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              <button
                className="w-full px-4 py-2 text-left hover:bg-[#330987]"
                onClick={e => {
                  e.stopPropagation(); // Stop propagation when a menu item is clicked
                  item.action(); // Call the menu item action
                }}
              >
                {item.label}
              </button>
              {index < menuItems.length - 1 && (
                <hr className="border-t border-gray-600" />
              )}
            </React.Fragment>
          ))}
        </div>
      }
      position="bottom-end"
    />
  );
};

export default TableDropdownMenu;
