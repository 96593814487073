import product1 from '../../../assets/images/icons/product1.png';
import product2 from '../../../assets/images/icons/product2.png';
import product3 from '../../../assets/images/icons/product3.png';
import product4 from '../../../assets/images/icons/product4.png';
import arrow from '../../../assets/images/icons/arrow-right.png';

const ProductStats = ({
  totalProducts = 0,
  pendingProducts = 0,
  rejectedProducts = 0,
  activeProducts = 0,
}) => {
  return (
    <>
      <div className="mt-2 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        {/* Inner container with rounded corners */}
        <div className="relative flex items-center justify-between rounded-md bg-custom-gradient p-4 px-8 shadow-custom-shadow">
          <div className="flex items-center space-x-3">
            <img src={product1} className="w-[50px]" />

            <div>
              <p className="text-[12px] text-gray-400">Total Products</p>
              <p className="text-2xl font-semibold text-white">
                {totalProducts}
              </p>
              <p className="flex items-center text-[10px] text-gray-400">
                Show More{' '}
                <img className="ml-1 w-[5px]" src={arrow} alt="arrow" />
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <img src={product2} className="w-[50px]" />

            <div>
              <p className="text-[12px] text-gray-400">Active</p>
              <p className="text-2xl font-semibold text-white">
                {activeProducts}
              </p>
              <p className="flex items-center text-[10px] text-gray-400">
                Show More{' '}
                <img className="ml-1 w-[5px]" src={arrow} alt="arrow" />
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <img src={product3} className="w-[50px]" />

            <div>
              <p className="text-[12px] text-gray-400">Pending</p>
              <p className="text-2xl font-semibold text-white">
                {pendingProducts}
              </p>
              <p className="flex items-center text-[10px] text-gray-400">
                Show More{' '}
                <img className="ml-1 w-[5px]" src={arrow} alt="arrow" />
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <img src={product4} className="w-[50px]" />

            <div>
              <p className="text-[12px] text-gray-400">Rejected</p>
              <p className="text-2xl font-semibold text-white">
                {rejectedProducts}
              </p>
              <p className="flex items-center text-[10px] text-gray-400">
                Show More{' '}
                <img className="ml-1 w-[5px]" src={arrow} alt="arrow" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductStats;
