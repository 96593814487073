import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import TabList from '../../components/common/TabNavigation/TabList';
import TabItem from '../../components/common/TabNavigation/TabItem';
import CustomNotifiation from './customNotifiation';
import DefaultPrefrences from './defaultPrefrences';
import Settings from './settings';

const Products = () => {
  const [showHistoryButton, setShowHistoryButton] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col gap-8 text-white">
        <TabList
          activeTabIndex={0}
          gap={6}
          listClasses="w-fit divide-x divide-[#4e57c9] overflow-hidden rounded-t-2xl border-x border-t border-[#4e57c9]"
          itemClasses="px-6 py-2 capitalize"
        >
          <TabItem label="Notifications settings">
            <Settings />
          </TabItem>
          <TabItem label="Default Preferences">
            <DefaultPrefrences />
          </TabItem>
          <TabItem label="Custom Notification">
            <CustomNotifiation />
          </TabItem>
        </TabList>
      </div>
    </>
  );
};

export default Products;
