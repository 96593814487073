import React, {useState} from 'react';
import NotificationButton from './NotificationButton';
import SwipeableNotification from './SwipeableNotification'; // Import the new component
import {ReactComponent as DeleteIcon} from '../../assets/images/icons/delete_gray.svg';

interface Notification {
  id: number;
  type: 'message' | 'alert';
  title: string;
  description?: string;
  timestamp: string;
  read: boolean;
  action?: () => void;
}

const NotificationPanel: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      id: 1,
      type: 'message',
      title: 'Your Feedback Matters.',
      description: 'Fill out the survey',
      timestamp: '2 days ago 11:41 AM',
      read: false,
      action: () => {
        console.log('Open survey');
      },
    },
    {
      id: 2,
      type: 'alert',
      title: 'Missing Payments',
      timestamp: '3 days ago 11:41 AM',
      read: false,
    },
    {
      id: 3,
      type: 'message',
      title: 'Subscribe Now!',
      timestamp: '4 days ago 11:41 AM',
      read: false,
    },
  ]);

  const handleMarkAsRead = (id: number) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notif =>
        notif.id === id ? {...notif, read: true} : notif,
      ),
    );
  };

  const handleDelete = (id: number) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notif => notif.id !== id),
    );
  };

  const handleSwipe = (id: number) => {
    setNotifications(prevItems => prevItems.filter(item => item.id !== id));
  };

  return (
    <div className="relative mt-1 w-96 rounded-lg bg-[#342B6E] p-4 text-white shadow-lg">
      {/* Header */}
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <NotificationButton />
          <h2 className="text-xl font-semibold">
            Notification ({notifications.length})
          </h2>
        </div>
        {notifications.length ? (
          <button
            className="focus:outline-none"
            onClick={() => setNotifications([])}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="h-6 w-6 text-white"
            >
              <path
                d="M18.36 6.64a1 1 0 010 1.41L13.41 13l4.95 4.95a1 1 0 01-1.41 1.41L12 14.41l-4.95 4.95a1 1 0 01-1.41-1.41L10.59 13 5.64 8.05a1 1 0 011.41-1.41L12 11.59l4.95-4.95a1 1 0 011.41 0z"
                fill="currentColor"
              />
            </svg>
          </button>
        ) : (
          ''
        )}
      </div>

      {/* Notification List */}
      <div className="max-h-96 space-y-1 overflow-y-auto">
        {notifications.map(notification => (
          <SwipeableNotification
            key={notification.id}
            {...notification}
            handleSwipe={() => handleSwipe(notification.id)}
            handleMarkAsRead={() => handleMarkAsRead(notification.id)}
            handleDelete={() => handleDelete(notification.id)}
          />
        ))}
        {notifications.length ? (
          <DeleteIcon
            className="ml-[93%] mt-2 h-5 w-5 cursor-pointer"
            onClick={() => setNotifications([])}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default NotificationPanel;
