import {useQuery} from '@tanstack/react-query';
import makeApiCall from '../lib/apiCall';
import {apiEndpoints} from '../constants/api-endpoints';
import {formatDateForAPI} from '../lib/utils';

export const useGetUsers = (length = 10, start = 0, searchValue = '') => {
  return useQuery({
    queryKey: ['users', {length, start, searchValue}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.AllUsers,
        params: {
          ...apiEndpoints.AllUsers.params,
          query: {
            ...apiEndpoints.AllUsers.params.query,
            length,
            start,
            searchValue,
          },
        },
      });
      return response;
    },
    // placeholderData: keepPreviousData,
  });
};

export const useGetUserProfile = (id = '') => {
  return useQuery({
    queryKey: ['user', {id}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetUserProfile,
        endpoint: apiEndpoints.GetUserProfile.endpoint?.replace('{id}', id),
      });
      return response;
    },
  });
};

export const useGetUserStatistics = (
  userId = '',
  fromDate = '2024-01-01',
  toDate = formatDateForAPI(new Date()),
) => {
  return useQuery({
    queryKey: ['userStats', {userId, fromDate, toDate}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetUserStatistics,
        endpoint: apiEndpoints.GetUserStatistics.endpoint?.replace(
          '{userId}',
          userId,
        ),
        params: {
          ...apiEndpoints.GetUserStatistics.params,
          query: {
            ...apiEndpoints.GetUserStatistics.params.query,
            fromDate,
            toDate,
          },
        },
      });
      return response;
    },
  });
};

export const useGetUserActions = ({
  userId = '',
  page = 0,
  size = 10,
  type = '',
  startDate = '2024-01-01',
  endDate = formatDateForAPI(new Date()),
  searchValue = '',
  category = '',
}) => {
  return useQuery({
    queryKey: [
      'userActions',
      {userId, page, size, type, startDate, endDate, searchValue, category},
    ],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetUserActions,
        endpoint: apiEndpoints.GetUserActions.endpoint?.replace(
          '{userId}',
          userId,
        ),
        params: {
          ...apiEndpoints.GetUserActions.params,
          query: {
            ...apiEndpoints.GetUserActions.params.query,
            page,
            size,
            type,
            startDate,
            endDate,
            searchValue,
            category,
          },
        },
      });
      return response;
    },
  });
};

export const useGetUserReviews = (userId = '', page = 0, size = 10) => {
  return useQuery({
    queryKey: ['userReviews', {userId, page, size}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetUserReviews,
        endpoint: apiEndpoints.GetUserReviews.endpoint?.replace(
          '{userId}',
          userId,
        ),
        params: {
          ...apiEndpoints.GetUserReviews.params,
          query: {
            ...apiEndpoints.GetUserReviews.params.query,
            page,
            size,
          },
        },
      });
      return response;
    },
  });
};
