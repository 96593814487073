import React from 'react';

interface ProgressSection {
  width: string;
  gradientClasses: string;
}

interface AnalyticsCardProps {
  title: string;
  count: string | number;
  increaseText: string;
  progressSections: ProgressSection[];
  imageSrc: string;
  imageClass: string;
  countClass: string;
  titleClass: string;
  increaseTextClass: string;
  imagePositionClasses?: string;
  padding?: string;
}

const HikeCount: React.FC<AnalyticsCardProps> = ({
  title,
  count,
  increaseText,
  progressSections,
  imageSrc,
  imageClass,
  countClass,
  titleClass,
  increaseTextClass,
  imagePositionClasses,
  padding,
}) => {
  return (
    <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div
        className={`relative h-full rounded-md bg-custom-gradient ${padding ? padding : 'p-5'} shadow-custom-shadow md-lt:gap-3`}
      >
        <h3 className={`${titleClass}`}>{title.toUpperCase()}</h3>
        <div className="flex items-center justify-between">
          <div className={`${countClass}`}>{count}</div>
          <img src={imageSrc} alt="hike" className={`${imageClass}`} />
        </div>

        <p className={`${increaseTextClass}`}>{increaseText}</p>
        <div className="flex h-4 w-full overflow-hidden bg-gray-700">
          {progressSections.map((section, index) => (
            <div
              key={index}
              className={`w-${section.width} ${section.gradientClasses}`}
            ></div>
          ))}
          <div className="flex-1 bg-gray-600"></div>
        </div>
      </div>
    </div>
  );
};

export default HikeCount;
