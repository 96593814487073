import React from 'react';
import tiktok from '../../../assets/images/icons/socials/tiktok.png';
import fb from '../../../assets/images/icons/socials/fb.png';
import linkedin from '../../../assets/images/icons/socials/linkedin.png';
import x from '../../../assets/images/icons/socials/x.png';
import insta from '../../../assets/images/icons/socials/insta.png';

interface SocialMediaButtonProps {
  platform: string;
  image: string; // URL of the social media image
  label: string;
}

const SocialMediaButton: React.FC<SocialMediaButtonProps> = ({
  image,
  label,
}) => {
  return (
    <button className="flex items-center gap-2 rounded-[2px] border border-[#6C8CFF] bg-[#271F5A] px-4 py-1 text-sm text-white shadow-md transition duration-300">
      <img src={image} alt={label} className="h-4 w-4" />
      {/* Use the image URL */}
      <span>{label}</span>
    </button>
  );
};

const SocialMediaButtons: React.FC = () => {
  return (
    <div className="flex gap-2">
      <SocialMediaButton
        platform="LinkedIn"
        image={linkedin}
        label="LinkedIn"
      />
      <SocialMediaButton platform="X" image={x} label="X" />
      <SocialMediaButton platform="TikTok" image={tiktok} label="TikTok" />
      <SocialMediaButton platform="Instagram" image={insta} label="Instagram" />
      <SocialMediaButton platform="Facebook" image={fb} label="Facebook" />
    </div>
  );
};

export default SocialMediaButtons;
