/* eslint-disable react-hooks/rules-of-hooks */
import {useState, useEffect} from 'react';

import AccountInfo from '../../components/core/AccountInfo/AccountInfo';
import ProductListing from '../../components/core/Product/ProductListing';
import Subscription from '../../components/core/Subscription/Subscription';
import SocialAnalytics from '../../components/core/SocialAnalytics/SocialAnalytics';
import TabList from '../../components/common/TabNavigation/TabList';
import TabItem from '../../components/common/TabNavigation/TabItem';
import {useDispatch} from 'react-redux';
import {setProductDetailView} from '../../store/General/General';
import {useParams} from 'react-router-dom';
import {useGetCreatorProfile} from '../../hooks/creatorQueryHooks';
import CreatorProductsAnalytics from './creatorProductsAnalytics';
import {useSearchParams} from 'react-router-dom';

const CreatorDetail = () => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  const {id} = useParams();
  const [activeTab, setActiveTab] = useState<number>(0);

  const {data: profileInfo} = useGetCreatorProfile(id);

  useEffect(() => {
    if (action) {
      if (action === 'activeProducts' || action === 'pendingProducts') {
        setActiveTab(1);
      } else if (action === 'subscriptions') {
        setActiveTab(2);
      } else if (action === 'socialAnaltics') {
        setActiveTab(3);
      } else {
        setActiveTab(0);
      }
    } else {
      setActiveTab(0);
    }
  }, [action]);

  const dispatch = useDispatch();
  dispatch(setProductDetailView(false));
  // Set the default active tab to "account-information"
  console.log(activeTab, 'activeTab-- ');
  return (
    <>
      <div className="flex flex-col gap-8 text-white">
        <div className="flex items-center justify-between px-6">
          <h1 className="text-3xl">
            <span className="font-light tracking-wider">User</span> Profile
          </h1>
        </div>
        <TabList
          key={activeTab}
          activeTabIndex={activeTab}
          gap={6}
          listClasses="w-fit divide-x divide-[#4e57c9] overflow-hidden rounded-t-2xl border-x border-t border-[#4e57c9]"
          itemClasses="px-6 py-2 capitalize"
        >
          <TabItem label="Account information">
            <AccountInfo type="creator" profileInfo={profileInfo} />
          </TabItem>
          <TabItem label="Products">
            <ProductListing />
          </TabItem>
          <TabItem label="Subscription">
            <Subscription />
          </TabItem>
          <TabItem label="Social Analytics">
            <SocialAnalytics />
          </TabItem>
          <TabItem label="Product Analytics">
            <CreatorProductsAnalytics />
          </TabItem>
        </TabList>
      </div>
    </>
  );
};

export default CreatorDetail;
