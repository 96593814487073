import TabItem from '../../common/TabNavigation/TabItem';
import TabList from '../../common/TabNavigation/TabList';

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

const topCreatorData = [
  {
    id: 1,
    name: 'John Doe',
    products: 32,
    subscribe: 550,
    like: '5.1k',
    followers: 980,
  },
  {
    id: 2,
    name: 'John Doe',
    products: 32,
    subscribe: 550,
    like: '5.1k',
    followers: 980,
  },
  {
    id: 3,
    name: 'John Doe',
    products: 32,
    subscribe: 550,
    like: '5.1k',
    followers: 980,
  },
  {
    id: 4,
    name: 'John Doe',
    products: 32,
    subscribe: 550,
    like: '5.1k',
    followers: 980,
  },
  {
    id: 5,
    name: 'John Doe',
    products: 32,
    subscribe: 550,
    like: '5.1k',
    followers: 980,
  },
];

const topUserData = [
  {
    id: 1,
    name: 'John Doe',
    productsVisited: 32,
    timeSpent: '87 hours',
    like: '5.1k',
    share: 980,
  },
  {
    id: 2,
    name: 'John Doe',
    productsVisited: 32,
    timeSpent: '87 hours',
    like: '5.1k',
    share: 980,
  },
  {
    id: 3,
    name: 'John Doe',
    productsVisited: 32,
    timeSpent: '87 hours',
    like: '5.1k',
    share: 980,
  },
  {
    id: 4,
    name: 'John Doe',
    productsVisited: 32,
    timeSpent: '87 hours',
    like: '5.1k',
    share: 980,
  },
  {
    id: 5,
    name: 'John Doe',
    productsVisited: 32,
    timeSpent: '87 hours',
    like: '5.1k',
    share: 980,
  },
];

const TopRatedPeople = () => {
  return (
    <div className="col-span-2 rounded-2xl bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] md-lt:col-span-1">
      <div className="relative h-full overflow-hidden rounded-2xl shadow-custom-shadow">
        <TabList
          activeTabIndex={0}
          listClasses="w-full"
          itemClasses="w-full px-3 py-5 uppercase"
        >
          <TabItem label="Top Creator">
            <div className="flex h-full flex-col gap-6 bg-custom-gradient p-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold text-white md-lt:text-xl">
                  Top Creator
                </h2>
                <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
                  {selectOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="h-full md-lt:overflow-x-scroll">
                <table className="h-full w-full table-auto border-collapse">
                  <thead className="h-12 text-nowrap border-solid bg-custom-purple-1 text-left text-sm font-semibold uppercase">
                    <tr>
                      <td className="px-3 py-2 text-center">Rank</td>
                      <td className="px-3 py-2">Creator Name</td>
                      <td className="px-3 py-2 text-center">Products</td>
                      <td className="px-3 py-2 text-right">Subscribe</td>
                      <td className="px-3 py-2 text-center">Like</td>
                      <td className="px-3 py-2 text-center">Follower</td>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#3c4391] border-b border-solid border-[#3c4391] bg-[#251c5c] bg-opacity-30 text-sm font-light">
                    {topCreatorData.map(data => (
                      <tr key={data.id}>
                        <td className="px-3 py-4 text-center">{data.id}</td>
                        <td className="px-3 py-4">{data.name}</td>
                        <td className="px-3 py-4 text-center">
                          {data.products}
                        </td>
                        <td className="px-3 py-4 text-right">
                          {data.subscribe}
                        </td>
                        <td className="px-3 py-4 text-center">{data.like}</td>
                        <td className="px-3 py-4 text-center">
                          {data.followers}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </TabItem>
          <TabItem label="Top User">
            <div className="flex h-full flex-col gap-6 bg-custom-gradient p-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold text-white md-lt:text-xl">
                  Top User
                </h2>
                <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
                  {selectOptions.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="h-full md-lt:overflow-x-scroll">
                <table className="h-full w-full table-auto border-collapse">
                  <thead className="h-12 text-nowrap border-solid bg-custom-purple-1 text-left text-sm font-semibold uppercase">
                    <tr>
                      <td className="px-3 py-2 text-center">Rank</td>
                      <td className="px-3 py-2">Usee Profile</td>
                      <td className="px-3 py-2 text-center">Product Visited</td>
                      <td className="px-3 py-2 text-right">Time Spent</td>
                      <td className="px-3 py-2 text-center">Like</td>
                      <td className="px-3 py-2 text-center">Shares</td>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#3c4391] border-b border-solid border-[#3c4391] bg-[#251c5c] bg-opacity-30 text-sm font-light">
                    {topUserData.map(data => (
                      <tr key={data.id}>
                        <td className="px-3 py-4 text-center">{data.id}</td>
                        <td className="px-3 py-4">{data.name}</td>
                        <td className="px-3 py-4 text-center">
                          {data.productsVisited}
                        </td>
                        <td className="px-3 py-4 text-right">
                          {data.timeSpent}
                        </td>
                        <td className="px-3 py-4 text-center">{data.like}</td>
                        <td className="px-3 py-4 text-center">{data.share}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </TabItem>
        </TabList>
      </div>
    </div>
  );
};

export default TopRatedPeople;
