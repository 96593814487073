import React, {useState} from 'react';
import Table from '../../components/common/Table';
import ReachChart from '../../components/common/Graphs/ReachChart';
import Modal from '../../components/common/Modal';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import ProductSelect from '../../components/core/Product/Dropdown/ProductSelect';
import LinearIcons from '../../components/core/SocialMedia/LinearIcons';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import hike from '../../assets/images/icons/hike.png';
import hikeWhite from '../../assets/images/icons/reachWhite.png';
import downfall from '../../assets/images/icons/downfall.png';
import active from '../../assets/images/icons/active.png';
import dp from '../../assets/images/icons/dp.png';
import del from '../../assets/images/icons/del.png';
import eye from '../../assets/images/icons/eye-with-no-bg.png';
import fb2 from '../../assets/images/icons/socials/fb2.png';
import insta2 from '../../assets/images/icons/socials/insta2.png';
import tiktok2 from '../../assets/images/icons/socials/tiktok2.png';
import x2 from '../../assets/images/icons/socials/x2.png';
import productSampleImage from '../../assets/images/icons/productSelection.png';
import lussoBg from '../../assets/images/icons/lussoBg.jpg';
import ai from '../../assets/images/icons/ai.png';
import heartGray from '../../assets/images/icons/heartGray.png';
import smsGray from '../../assets/images/icons/smsGray.png';
import labelGray from '../../assets/images/icons/labelGray.png';
import heartBlue from '../../assets/images/icons/heartBlue.png';
import smsBlue from '../../assets/images/icons/smsBlue.png';
import tagBlue from '../../assets/images/icons/tagBlue.png';
import shareBlue from '../../assets/images/icons/shareBlue.png';
import playBlue from '../../assets/images/icons/playBlue.png';

const hikeCardData = [
  {
    title: 'Total Posted',
    image: hike,
    count: 1852,
  },
  {
    title: 'Scheduled',
    image: hike,
    count: 17.029,
  },
  {
    title: 'Reviewed',
    image: hike,
    count: 91,
  },
  {
    title: 'Deleted',
    image: downfall,
    count: 155,
  },
];

const Products = () => {
  const headings = [
    '',
    '',
    'CREATOR',
    'DATE',
    'TIME',
    'PLATFORM',
    'ACTIONS',
    'STATUS',
  ];
  const rows = [
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
    {
      profileImage: dp,
      title: 'Caption here',
      description: 'Transform your digital dreams into...',
      creator: 'PetStop',
      date: '10/12/24',
      time: '10:20',
      platform: {
        fb: fb2,
        insta: insta2,
        tiktok: tiktok2,
        x: x2,
      },
      action: {
        view: eye,
        delete: del,
        reach: hikeWhite,
      },
      status: active,
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">
        <img src={row.profileImage} alt="display" className="mr-2 w-10" />
      </td>
      <td className="px-6 py-4">
        <p>{row.title}</p>
        <p>{row.description}</p>
      </td>
      <td className="px-6 py-4">{row.creator}</td>
      <td className="px-6 py-4">{row.date}</td>
      <td className="px-6 py-4">{row.time}</td>
      <td className="px-6 py-4">
        <div className="flex space-x-2">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={fb2} alt="Facebook" className="w-6" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={insta2} alt="Facebook" className="w-6" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img src={tiktok2} alt="Facebook" className="w-6" />
          </a>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-[-6px]"
          >
            <img src={x2} alt="Facebook" className="mt-[7px] w-6" />
          </a>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="flex space-x-4">
          <a
            onClick={() => {
              setOpenDialogue(true);
              setShowContentInReviewDialogue(true);
              setShowContentInEngageDialogue(false);
            }}
            className="cursor-pointer hover:opacity-80"
          >
            <img src={row.action.view} alt="view" className="w-6" />
          </a>
          <a
            href="#"
            className="ml-2 mt-[-3px] cursor-pointer hover:opacity-80"
          >
            <img src={row.action.delete} alt="delete" className="w-6" />
          </a>
          <a
            onClick={() => {
              setOpenDialogue(true);
              setShowContentInEngageDialogue(true);
              setShowContentInReviewDialogue(false);
            }}
            className="ml-2 cursor-pointer hover:opacity-80"
          >
            <img src={row.action.reach} alt="reach" className="w-6" />
          </a>
        </div>
      </td>
      <td className="px-6 py-4">
        <img src={row.status} alt="Visa Logo" className="w-14" />
      </td>
    </>
  );
  const data = {
    labels: ['Aug 8', 'Aug 18', 'Aug 28', 'Sep 8', 'Sep 18', 'Sep 28'],
    datasets: [
      {
        label: 'Deleted',
        data: [800, 900, 950, 1000, 1200, 1500],
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Shedule',
        data: [2000, 2000, 1800, 1700, 1600, 1700],
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        pointRadius: 0,
        tension: 0,
      },
      {
        label: 'Reviewed',
        data: [700, 800, 900, 850, 800, 900],
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        pointRadius: 0,
        tension: 0,
      },
      {
        label: 'Total Posted',
        data: [1000, 1000, 900, 900, 1100, 1200],
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const products = [
    {id: '1', name: 'Product 1', image: productSampleImage},
    {id: '2', name: 'Product 2', image: productSampleImage},
    {id: '3', name: 'Product 3', image: productSampleImage},
  ];

  const handleProductChange = (selectedProduct: {id: string; name: string}) => {
    console.log('Selected Product:', selectedProduct);
  };

  const [selectedOption, setSelectedOption] = useState('Scheduled');

  const [openDialogue, setOpenDialogue] = useState(false);
  const [showContentInReviewDialogue, setShowContentInReviewDialogue] =
    useState(false);
  const [showContentInEngageDialogue, setShowContentInEngageDialogue] =
    useState(false);

  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };

  const handleCloseDialogue = () => setOpenDialogue(false);

  return (
    <>
      <div className="flex flex-col gap-8 text-white">
        <div className="flex items-center justify-between px-6">
          <h1 className="text-3xl">
            <span className="font-light tracking-wider">Post Scheduling</span>
          </h1>
        </div>
        <div className="flex h-full w-full items-center justify-between">
          <div className="flex h-full">
            <ProductSelect options={products} onChange={handleProductChange} />
          </div>
          <div className="flex h-full flex-col pr-6">
            <LinearIcons />
          </div>
        </div>
        <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
          {/* Left Side Graph Container */}
          <div className="col-span-2 row-span-2 flex h-full flex-col">
            <CurveLineGraph
              data={data}
              options={options}
              title="Social Media"
            />
          </div>

          {/* Right Side Cards Container */}
          {hikeCardData.map((data, index) => (
            <HikeCount
              key={index}
              title={data.title}
              count={data.count}
              increaseText="+8.2k increase/day"
              progressSections={[
                {
                  width: '1/4',
                  gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
                },
                {
                  width: '1/6',
                  gradientClasses:
                    'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
                },
              ]}
              imageSrc={data.image}
              imageClass="w-10 md-lt:w-8"
              countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
              titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
              increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
              imagePositionClasses="absolute right-6 top-16"
            />
          ))}
        </div>

        <div className="mt-6 w-full">
          <Table
            title="Posts"
            headings={headings}
            rows={rows}
            renderRow={renderRow}
            enableSearch={true}
            enableFilter={true}
            filterOptions={[
              {label: 'Upcoming', value: 'Upcoming'},
              {label: 'Scheduled', value: 'Scheduled'},
              {label: 'Publish', value: 'Publish'},
              {label: 'Review', value: 'Review'},
            ]}
            selectedOption={selectedOption}
            searchPlaceholder="Search Post, Creator"
            onFilterChange={handleFilterChange}
          />
        </div>
      </div>

      <Modal isOpen={openDialogue} onClose={handleCloseDialogue} width="70%">
        {showContentInReviewDialogue && (
          <div className="flex w-full">
            <div className="w-[70%] border-r border-[rgba(108,140,255,0.5)]">
              <div className="mb-8 flex justify-start border-b border-[rgba(108,140,255,0.5)] p-[25px]">
                <div className="ml-4 flex space-x-2">
                  <img
                    src={fb2}
                    alt="Facebook"
                    className="h-5 w-5 rounded-md"
                  />
                  <img
                    src={insta2}
                    alt="Instagram"
                    className="h-5 w-5 rounded-md"
                  />
                  <img
                    src={tiktok2}
                    alt="TikTok"
                    className="h-5 w-5 rounded-md"
                  />
                  <img src={x2} alt="Twitter" className="h-5 w-5 rounded-md" />
                </div>
              </div>
              <div className="relative px-8 pb-8">
                <div className="relative h-[400px] rounded-lg border border-[rgba(108,140,255,0.5)] p-8 text-white">
                  <p className="mb-8 text-lg font-light text-[#FFFFFF99]">
                    Discover the perfect blend of innovation and expertise at
                    Lusso Labs. As your digital partner, we specialize in
                    software and digital development...
                  </p>
                  <img
                    src={lussoBg}
                    alt="AI avatar"
                    className="object absolute bottom-[90px] left-4 h-14 w-14 object-cover object-left"
                  />
                  <div className="justify-right text-md absolute bottom-0 left-0 flex w-full items-center border-t border-[rgba(108,140,255,0.5)] pt-6 font-light text-[#FFFFFF99]">
                    <div className="mb-4 ml-auto flex pb-2">
                      <img src={ai} className="mr-2 w-5" />{' '}
                      <span className="mr-4">Created with AI</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[30%]">
              <div className="rounded-lg p-6 font-sans text-white shadow-lg">
                <h2 className="mb-4 text-lg font-semibold">Post Preview</h2>

                <div className="mb-6 h-[2px] w-[120px] bg-[#3C44EC]"></div>
                <div className="mb-2 flex w-full rounded-lg bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                  <div className="flex w-full max-w-[380px] items-center justify-between rounded-full rounded-lg bg-[#1C1F36] bg-custom-gradient p-6 p-[10px] shadow-custom-shadow">
                    <div className="flex items-center">
                      <img
                        src="https://img.icons8.com/color/48/instagram-new.png"
                        alt="Instagram Logo"
                        className="mr-2 h-6 w-6"
                      />
                      <span className="font-medium text-white">Instagram</span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>

                <div className="rounded-lg bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                  <div
                    className={`relative rounded-lg bg-custom-gradient p-6 shadow-custom-shadow`}
                  >
                    <div className="mb-4 flex items-center gap-4">
                      <img
                        src="https://via.placeholder.com/40"
                        alt="Profile"
                        className="h-10 w-10 rounded-full"
                      />
                      <div>
                        <h3 className="text-sm font-semibold">Lusso.ai</h3>
                        <span className="text-sm text-gray-400">
                          marketing agency
                        </span>
                      </div>
                    </div>

                    <div className="mb-4">
                      <img
                        src={lussoBg}
                        alt="Post Image"
                        className="h-[150px] w-full rounded-md"
                      />
                    </div>

                    <div className="mb-4 flex gap-6 text-gray-400">
                      <div className="flex items-center gap-1">
                        <img src={heartGray} className="w-4" />
                        <span>6.2k</span>
                      </div>
                      <div className="flex items-center gap-6">
                        <img src={smsGray} className="w-4" />
                        <span>245</span>
                      </div>
                      <div className="ml-auto flex items-center gap-1">
                        <img src={labelGray} className="w-3" />
                        <span>286</span>
                      </div>
                    </div>

                    <p className="text-sm text-gray-300">
                      Discover the perfect blend of innovation and expertise at
                      Lusso Labs, as your digital partner, we specialize in
                      software and digital development......
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showContentInEngageDialogue && (
          <div className="flex w-full">
            <div className="w-[70%] border-r border-[rgba(108,140,255,0.5)]">
              <div className="mb-2 mb-8 flex justify-start border-b border-[rgba(108,140,255,0.5)] p-[25px]">
                <div className="ml-4 flex space-x-2">
                  <img src={fb2} alt="Facebook" className="h-5 w-5" />
                  <img src={insta2} alt="Instagram" className="h-5 w-5" />
                  <img src={tiktok2} alt="TikTok" className="h-5 w-5" />
                  <img src={x2} alt="Twitter" className="h-5 w-5" />
                </div>
              </div>
              <div>
                <ReachChart
                  followers={1155}
                  nonFollowers={25155}
                  totalReach={56155}
                  chartTitle="Reach"
                  centerText="Account reached"
                  nonFollowersLabel="Non Followers"
                  followersLabel="Followers"
                />
              </div>
            </div>
            <div className="w-[30%]">
              <div className="flex flex-col items-center text-white">
                <div className="py-14">
                  <div className="text-center text-4xl font-semibold">
                    21,542
                  </div>
                  <div className="mt-1 text-lg">Account Engaged</div>
                </div>

                <div className="w-full border-t border-gray-500"></div>

                <div className="w-full space-y-4 p-8">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          src={heartBlue}
                          alt="Likes"
                          className="h-6 w-6 text-blue-400"
                        />
                      </div>
                      <div className="text-base">Likes</div>
                    </div>
                    <div className="text-base">124</div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          src={smsBlue}
                          alt="Comments"
                          className="h-6 w-6 text-blue-400"
                        />
                      </div>
                      <div className="text-base">Comments</div>
                    </div>
                    <div className="text-base">124</div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          src={shareBlue}
                          alt="Shares"
                          className="h-6 w-6 text-blue-400"
                        />
                      </div>
                      <div className="text-base">Shares</div>
                    </div>
                    <div className="text-base">124</div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-2">
                        <img
                          src={playBlue}
                          alt="Plays"
                          className="h-6 w-6 text-blue-400"
                        />
                      </div>
                      <div className="text-base">Plays</div>
                    </div>
                    <div className="text-base">124</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Products;
