import {useEffect, useState} from 'react';

interface SearchProps {
  placeholder?: string;
  className?: string;
  onSearch: (query: string) => void;
}

const Search = ({placeholder, className, onSearch}: SearchProps) => {
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      onSearch(query); // Trigger search with query
    }, 300);

    return () => clearTimeout(handler);
  }, [query, onSearch]);

  return (
    <div
      className={`flex items-center rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-purple-500 p-[1px] ${className}`}
    >
      <div className="flex h-full w-full items-center rounded-full bg-custom-gradient px-4 py-1">
        <span className="text-gray-400">
          <svg
            width="13"
            height="13"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_8642_61746)">
              <path
                d="M4.41768 3.64195C4.18693 3.41102 3.81256 3.41102 3.58181 3.64195C2.72506 4.4987 2.30397 5.6922 2.42634 6.91664C2.45687 7.22167 2.71384 7.44905 3.01393 7.44905C3.03365 7.44905 3.05353 7.44805 3.07325 7.44608C3.39818 7.41358 3.63522 7.1237 3.60272 6.79899C3.51562 5.92883 3.81278 5.08273 4.41768 4.4778C4.64862 4.24708 4.64862 3.87267 4.41768 3.64195Z"
                fill="#C1C1C1"
                fillOpacity="0.4"
              />
              <path
                d="M6.75863 0C3.03191 0 0 3.03191 0 6.75863C0 10.4853 3.03191 13.5173 6.75863 13.5173C10.4853 13.5173 13.5173 10.4853 13.5173 6.75863C13.5173 3.03191 10.4853 0 6.75863 0ZM6.75863 12.335C3.68375 12.335 1.18228 9.8335 1.18228 6.75863C1.18228 3.68375 3.68375 1.18228 6.75863 1.18228C9.83331 1.18228 12.335 3.68375 12.335 6.75863C12.335 9.8335 9.8335 12.335 6.75863 12.335Z"
                fill="#C1C1C1"
                fillOpacity="0.4"
              />
              <path
                d="M15.8269 14.9922L11.5313 10.6966C11.3004 10.4657 10.9264 10.4657 10.6954 10.6966C10.4645 10.9274 10.4645 11.3018 10.6954 11.5325L14.991 15.8281C15.1065 15.9435 15.2576 16.0013 15.409 16.0013C15.5603 16.0013 15.7114 15.9435 15.8269 15.8281C16.0578 15.5973 16.0578 15.223 15.8269 14.9922Z"
                fill="#C1C1C1"
                fillOpacity="0.4"
              />
            </g>
            <defs>
              <clipPath id="clip0_8642_61746">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>

        <input
          type="text"
          placeholder={placeholder ?? 'Search'}
          value={query}
          onChange={e => setQuery(e.target.value)}
          className="bg-transparent pl-3 text-sm text-gray-200 placeholder-[#C1C1C166] outline-none"
        />
      </div>
    </div>
  );
};

export default Search;
