import React from 'react';
import '../../assets/styles/creator/header.css';
import {NavLink} from 'react-router-dom';
import AiSearch from '../ui/AiSearch';
import NotificationButton from '../ui/NotificationButton';
import NotificationPannel from '../ui/NotificationPannel';
import AvatarMenu from '../ui/AvatarMenu';
import Button from '../ui/Button';
import {ReactComponent as MenuIcon} from '../../assets/images/icons/burger-menu.svg';
import {useDispatch} from 'react-redux';
import {setSidebarOpen} from '../../store/General/General';
import Popover from '../common/Popover';
import {ReactComponent as Gear} from '../../assets/images/icons/gear.svg';
import {ReactComponent as Logout} from '../../assets/images/icons/logout.svg';
import {useAuth} from '../../context/AuthContext';

const Header = () => {
  const {logout} = useAuth();
  const dispatch = useDispatch();

  return (
    <header className="z-20 flex w-full items-center justify-between p-6 md-lt:sticky md-lt:top-0 md-lt:bg-[#1b173cf3]">
      {/* Left Section */}
      <Button
        className="rounded-full bg-transparent p-2 hover:bg-[#00000022] active:bg-[#00000044] lg:hidden"
        type="custom"
        onClick={() => dispatch(setSidebarOpen())}
      >
        <MenuIcon className="h-8 w-8 stroke-white" />
      </Button>
      <div className="flex w-1/2 items-center md-lt:hidden">
        <AiSearch />
      </div>

      {/* Right Section */}
      <div className="flex items-center gap-7">
        {/* Notification Icon */}
        <Popover
          button={<NotificationButton />}
          content={<NotificationPannel />}
          position="bottom-end"
        />
        {/* Profile Card */}
        <Popover
          button={<AvatarMenu />}
          content={
            <div className="z-10 w-40 rounded-xl bg-[#342B6E] text-white shadow-xl backdrop-blur-sm">
              <NavLink
                to={'/settings'}
                className={({isActive}) =>
                  `flex items-center gap-4 rounded-lg px-4 py-3 transition-colors hover:bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] ${
                    isActive
                      ? 'bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] stroke-[#00F0FB] text-[#00F0FB]'
                      : 'stroke-gray-400'
                  }`
                }
              >
                <Gear className="h-5 w-5 stroke-inherit" />
                <span>Settings</span>
              </NavLink>
              <hr className="border-t border-gray-600" />
              <NavLink
                to={'/login'}
                onClick={logout}
                className={({isActive}) =>
                  `flex items-center gap-4 rounded-lg px-4 py-3 transition-colors hover:bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] ${
                    isActive
                      ? 'bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] stroke-[#00F0FB] text-[#00F0FB]'
                      : 'stroke-gray-400'
                  }`
                }
              >
                <Logout className="h-5 w-5 stroke-inherit" />
                <span>Logout</span>
              </NavLink>
            </div>
          }
          position="bottom-end"
        />
      </div>
    </header>
  );
};

export default Header;
