import React from 'react';

interface SubscriptionProps {
  planStartDate: string;
  planName: string;
  planType: string;
  price: string;
  trialPeriod: string;
}

const SubscriptionHeader: React.FC<SubscriptionProps> = ({
  planStartDate,
  planName,
  planType,
  price,
  trialPeriod,
}) => {
  return (
    <div className="relative mt-5 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex items-center rounded-md bg-custom-gradient px-8 py-3 shadow-custom-shadow">
        <div className="absolute left-4 top-4 rounded-full bg-blue-600 px-3 py-1 text-sm text-white">
          Plan active since: {planStartDate}
        </div>
        <div className="ml-[-13px] mt-[40px] flex flex-col items-start gap-1">
          <span className="text-lg font-semibold tracking-[7px] tracking-widest text-white">
            {planName}
          </span>
          <span className="text-base font-light tracking-[7px] text-gray-400">
            {planType}
          </span>
        </div>
        <div id="price" className="ml-auto mt-[35px] flex flex-col items-end">
          <span className="text-3xl font-bold text-[#5e96ff]">{price}</span>
          <span className="text-sm text-gray-400">{trialPeriod}</span>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHeader;
