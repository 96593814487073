import {TabItemProps} from './TabTypes';
import {sanitizeForId} from '../../../lib/stringUtils';

const TabItem: React.FC<TabItemProps> = ({label, children, onClick = {}}) => (
  <div
    className="tab-panel h-full"
    role="tabpanel"
    aria-labelledby={`tab-${sanitizeForId(label)}`}
    id={`panel-${sanitizeForId(label)}`}
  >
    {children}
  </div>
);

export default TabItem;
