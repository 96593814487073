import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  width?: string; // Optional prop for custom width as a percentage
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  width = '70%',
}) => {
  if (!isOpen) return null;

  // Function to handle clicks on the backdrop
  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Close modal only if the click is on the backdrop, not on the modal itself
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleBackdropClick} // Attach the click handler here
    >
      <div
        className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]"
        style={{width}}
      >
        <div
          className={`relative rounded-md bg-custom-gradient shadow-custom-shadow`}
        >
          <button
            onClick={onClose}
            className="absolute right-4 top-4 w-10 text-white"
          >
            &times; {/* Close icon */}
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
