import {useState} from 'react';
import {createPortal} from 'react-dom';
import CircularProgress from '../../components/core/Dashboard/CircularProgress';
import Button from '../../components/ui/Button';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import Table from '../../components/common/Table';
import Badge from '../../components/ui/Badge';
import Flyover from '../../components/common/Flyover';
import Input from '../../components/common/Input';
import TableDropdownMenu from '../../components/common/TableDropdownMenu';

const data = {
  labels: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  datasets: [
    {
      label: 'Likes',
      data: [
        180000, 400000, 350000, 450000, 650000, 500000, 600000, 700000, 650000,
        500000, 500000,
      ],
      borderColor: '#573bae',
      backgroundColor: '#573bae',
      tension: 0.4,
      pointRadius: 0,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#ffffff',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      max: 1000000,
      ticks: {
        stepSize: 200000,
        color: '#ffffff',
      },
      grid: {
        color: '#424f9c',
      },
      border: {
        display: false,
      },
    },
  },
};

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

const headings = ['MONTH', 'GOAL', 'SET BY', 'YEAR', 'OUTCOME', ''];
const rows = [
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
  {
    month: 'July',
    goal: '$150,000',
    setBy: 'John Doe',
    year: '2024',
    outcome: 'Achieved',
    actions: '',
  },
];




const Revenue = () => {
  const [action, setAction] = useState('');
  const [selectedOption, setSelectedOption] = useState('Active');

  const menuItems = () => [
    {
      label: 'View Details',
      action: () => setAction('view_details'),
    },
    {
      label: 'Reuse',
      action: () => console.log(`clicked placeholder`),
    },
    {
      label: 'Delete',
      action: () => console.log(`clicked placeholder`),
    },
  ];

  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.month}</td>
      <td className="px-6 py-4">{row.goal}</td>
      <td className="px-6 py-4">{row.setBy}</td>
      <td className="px-6 py-4">{row.year}</td>
      <td className="px-6 py-4">
        <Badge
          value={row.outcome}
          textColorEnumKey={row.outcome}
          bgColorEnumKey={row.outcome}
          rounded="full"
        />
      </td>
      <td className="w-10 px-6 py-4">
        <TableDropdownMenu menuItems={menuItems()} />
      </td>
    </>
  );
  

  return (
    <div className="flex flex-col gap-8 text-white">
      <div className="flex items-center justify-between px-6 md-lt:flex-col md-lt:items-start md-lt:gap-4">
        <h1 className="text-3xl md-lt:text-2xl">Revenue</h1>
        <Button
          label="Add Revenue Goal"
          className="rounded-md bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:px-3 md-lt:text-sm"
          onClick={() => setAction('add_revenue_goal')}
        />
      </div>

      <div className="grid grid-cols-3 gap-6 md-lt:grid-cols-1">
        <div className="col-span-2 h-full md-lt:col-span-1 md-lt:h-96">
          <CurveLineGraph
            data={data}
            options={options}
            title={'Total Subscription Profit'}
            showLabels={false}
          />
        </div>
        <div className="h-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
          <div className="flex h-full flex-col gap-4 rounded-md bg-custom-gradient px-4 py-6 shadow-custom-shadow">
            <div className="flex items-center justify-between">
              <h2 className="text-2xl text-white">Revinue Goal</h2>
              <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
                {selectOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <CircularProgress percent={75} />
            <div className="flex items-center justify-center">
              <p className="text-xs text-gray-400">
                You reached $103.577 from 150.000
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Table
          title="Summery"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={true}
          filterOptions={[
            {label: 'Active', value: 'Active'},
            {label: 'Inactive', value: 'Inactive'},
          ]}
          selectedOption={selectedOption}
          searchPlaceholder="Search Name"
          onFilterChange={value => setSelectedOption(value)}
        />
      </div>
      {/* DELETE ACCOUNT FLYOVER */}
      {createPortal(
        <Flyover
          isOpen={action === 'add_revenue_goal'}
          onClose={() => setAction('')}
        >
          <div className="flex w-full flex-col gap-4 rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-white">
              Add Revenue Goal
            </h2>
            <Input
              label="Select term"
              placeholder="month / year / week"
              // value={'Jon Doe'}
              onChange={() => {}}
              // disabled
              className="text-xs"
            />
            <Input
              label="Amount"
              placeholder="Amount"
              // value={'Jon Doe'}
              onChange={() => {}}
              // disabled
              className="text-xs"
            />

            <Input
              label="Addition remarks"
              type="textarea"
              placeholder="Addition remarks"
              // value={'Jon Doe'}
              onChange={() => {}}
              // disabled
              className="resize-none rounded-lg text-xs"
              rows={3}
            />

            <div className="flex gap-4">
              <Button
                label="Set"
                className="rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
              <Button
                label="Cancel"
                onClick={() => setAction('')}
                className="rounded-full bg-[#7D3CF3] px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </Flyover>,
        document.body,
      )}

{createPortal(
        <Flyover
          isOpen={action === 'view_details'}
          onClose={() => setAction('')}
        >
         <div className="p-8 text-white">
  <div className="flex items-center justify-between mb-4">
    <div>
      <h2 className="text-1xl">Revenue Goal</h2>
      <p className="text-sm text-gray-400">July 2024</p>
      <p className="text-sm mt-2">Set By : <span className="font-semibold">John Doe</span></p>
    </div>
    <div className="flex items-center space-x-2">
      <button className="bg-indigo-700 px-6 py-3 rounded-lg flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 4h10a2 2 0 012 2v12a2 2 0 01-2 2H5a2 2 0 01-2-2V9a2 2 0 012-2h2m3 0h6" />
        </svg>
        <span>Nov 12, 2034</span>
      </button>
    </div>
  </div>

  <div className="p-4 rounded-lg mb-4">
    <p className="text-gray-300 text-center text-sm mb-1">Goal Amount</p>
    <h1 className="text-center text-6xl font-bold mb-3">$150,000</h1>
    <p className="text-center text-green-400 text-sm">&#x2191; 3% <span className='text-white'>vs last month</span> </p>
  </div>

  <div className="mb-4">
    <p className="flex justify-between text-sm mb-3">
      <span>Achieved Amount:</span>
      <span className="font-bold">$103,577</span>
    </p>
    <p className="flex justify-between text-sm text-red-500 mb-3">
      <span>Remaining Amount:</span>
      <span className="font-bold">$46,423</span>
    </p>
    <p className="flex justify-between text-sm">
      <span>Start date - End date:</span>
      <span className="font-bold">Nov 12, 2034 - Nov 26, 2024</span>
    </p>
  </div>

  <div>
    <h3 className="font-semibold text-xl mb-2">Tracking</h3>
    <div className="flex justify-between text-sm">
      <span>Last Edited By</span>
      <span className="font-bold">John Doe</span>
    </div>
  </div>
</div>


        </Flyover>,
        document.body,
      )}
    </div>
  );
};

export default Revenue;
