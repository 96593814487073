export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
};

export const apiEndpoints = {
  Login: {
    endpoint: '/v1/login',
    method: API_METHOD.POST,
    payload: {email: '', password: ''},
  },
  ForgotPassword: {
    endpoint: '/v1/sendResetPasswordMail',
    method: API_METHOD.POST,
    params: {
      query: {email: ''},
    },
  },
  ResetPassword: {
    endpoint: '/v1/resetPassword',
    method: API_METHOD.POST,
    params: {
      query: {token: ''},
    },
    payload: {newPassword: ''},
  },
  GetME: {
    endpoint: '/v1/userProfile',
    method: API_METHOD.GET,
  },
  AllUsers: {
    endpoint: '/v1/admin/users',
    method: API_METHOD.GET,
    params: {
      query: {length: 20, start: 0, searchValue: ''},
    },
  },
  AllCreators: {
    endpoint: '/v1/admin/creators',
    method: API_METHOD.GET,
    params: {
      query: {length: 20, start: 0},
    },
  },
  GetUserProfile: {
    endpoint: '/v1/admin/{id}/profile',
    method: API_METHOD.GET,
  },
  GetUserActions: {
    endpoint: '/v1/admin/users/{userId}/userAction',
    method: API_METHOD.GET,
    params: {
      query: {
        page: 0,
        size: 10,
        type: '',
        startDate: '',
        endDate: '',
        searchValue: '',
        category: '',
      },
    },
  },
  GetUserStatistics: {
    endpoint: '/v1/admin/users/{userId}/statistics',
    method: API_METHOD.GET,
    params: {
      query: {
        fromDate: '',
        toDate: '',
      },
    },
  },
  GetUserReviews: {
    endpoint: '/v1/admin/users/{userId}/reviews',
    method: API_METHOD.GET,
    params: {
      query: {
        page: 0,
        size: 10,
      },
    },
  },
  GetCreatorProducts: {
    endpoint: '/v1/admin/creators/{id}/products',
    method: API_METHOD.GET,
    params: {
      query: {length: 20, start: 0, status: 'active'},
    },
  },
  GetTrendingProducts: {
    endpoint: '/v1/products/trending',
    method: API_METHOD.GET,
  },
  GetRecommendedProducts: {
    endpoint: '/v1/products/recommendations',
    method: API_METHOD.GET,
  },
  GetProductsNew: {
    endpoint: '/v1/products/new',
    method: API_METHOD.GET,
  },
  AllProducts: {
    endpoint: '/v1/products',
    method: API_METHOD.GET,
    params: {
      query: {length: 20, start: 0, status: 'active'},
    },
  },
  GetProductsDetail: {
    endpoint: '/v1/products/{productId}',
    method: API_METHOD.GET,
  },
  GetCreatorProductsDetail: {
    endpoint: '/v1/admin/creators/{creatorId}/products/{productId}',
    method: API_METHOD.GET,
  },
  approveReject: {
    endpoint: '/v1/admin/approveReject',
    method: API_METHOD.POST,
    params: {
      query: {productId: '', action: ''},
    },
  },
  GetCreatorProductsAnalytics: {
    endpoint: '/v1/admin/creators/{creatorId}/productAnalytics',
    method: API_METHOD.GET,
    params: {
      query: {fromDate: '', toDate: ''},
    },
  },
  GetCreatorSocialAnalytics: {
    endpoint: '/v1/admin/creators/{creatorId}/socialAnalytics',
    method: API_METHOD.GET,
    params: {
      query: {fromDate: '', toDate: '', platform: '', timezone: 'America/Chicago'},
    },
  },
  GetProductsOfTheDay: {
    endpoint: '/v1/admin/productOfDayHistory',
    method: API_METHOD.GET,
  }
};