import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Button from '../../components/ui/Button';

import logo from '../../assets/images/logo/lusso.png';

const ActivateAccount = () => {
  const navigate = useNavigate();

  const [otp, setOTP] = useState<string[]>(Array(6).fill(''));

  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendTimer, setResendTimer] = useState(60);

  const handleOTPChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const {value, selectionStart} = e.target;
    const newOTP = [...otp];

    if (/^[A-Za-z0-9]?$/.test(value)) {
      // Ensure input is valid
      newOTP[index] = value;
      setOTP(newOTP);

      // Move focus to the next input field if a character is entered
      if (value && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }

    // Handle backspace
    if (value === '' && selectionStart === 0 && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        // Move focus to the previous input field
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit form logic here
    navigate('/set-password');
  };

  useEffect(() => {
    if (isResendDisabled && resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Cleanup interval on component unmount
    }
    if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
  }, [isResendDisabled, resendTimer]);

  return (
    <div className="mx-auto flex w-2/6 flex-col gap-8 text-white">
      <img src={logo} alt="" className="w-1/2 self-center" />
      <div>
        <h4 className="mb-1 text-center text-xl font-medium tracking-wide">
          Activate Your Account
        </h4>
        <p className="mb-6 text-center text-sm font-light">
          Enter the one-time password (OTP) sent to your registered email
          address.
        </p>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="flex justify-between">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                id={`otp-input-${index}`}
                className="ac-frm-input"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '8px',
                  border: '1px solid #FFFFFF',
                  background: 'rgba(4, 4, 4, 0.20)',
                  textAlign: 'center',
                  fontSize: '12px',
                  color: '#FFFFFF',
                  padding: '0',
                  boxSizing: 'border-box',
                }}
                value={digit}
                onChange={e => handleOTPChange(e, index)}
                onKeyDown={e => handleKeyDown(e, index)}
                maxLength={1}
              />
            ))}
          </div>
          <div className="mb-2 flex flex-col items-center justify-center gap-1 text-sm font-normal">
            {isResendDisabled && (
              <span className="text-white/50">
                Resend link in{' '}
                <em className="font-normal not-italic text-white/80">
                  {resendTimer < 10
                    ? `00:0${resendTimer}`
                    : `00:${resendTimer}`}
                </em>
              </span>
            )}
            <Link to={'#'} className="font-medium text-[#5E91FF]">
              Resend Link
            </Link>
          </div>
          <div className="flex gap-2">
            <Button
              label="login"
              className="relative w-2/3 rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              type="submit"
            />
            <Button
              label="Back"
              className="relative w-1/3 rounded-full bg-transparent px-6 py-1.5 text-sm hover:bg-opacity-60"
              type="button"
              border
            />
          </div>
          <div className="-mx-20 mt-7 flex items-center justify-center gap-2">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-purple-500"
            />
            <span className="text-xs text-white">
              I have read and accept{' '}
              <Link className="text-[#5E91FF]" to="#">
                Terms and conditions
              </Link>{' '}
              and{' '}
              <Link className="text-[#5E91FF]" to="#">
                privacy policy
              </Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActivateAccount;
