import React, {useState} from 'react';

interface ProductOption {
  id: string;
  name: string;
  image: string; // URL or path to the image
}

interface ProductSelectProps {
  options: ProductOption[];
  onChange: (selectedProduct: ProductOption) => void;
  style?: string;
  placeholder?: string;
}

const ProductSelect: React.FC<ProductSelectProps> = ({
  options,
  onChange,
  style,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ProductOption | null>(
    null,
  );

  const handleOptionClick = (option: ProductOption) => {
    setSelectedOption(option);
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative w-[200px] ${style}`}>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex h-[51px] cursor-pointer items-center justify-between rounded-[58px] border border-[#4A56AF] bg-[#302388] px-4 py-2 text-white shadow-md"
      >
        <div className="flex items-center">
          {selectedOption ? (
            <>
              <img
                src={selectedOption.image}
                alt={selectedOption.name}
                className="ml-[-8px] mr-2 w-9 rounded-full"
              />
              <span>{selectedOption.name}</span>
            </>
          ) : (
            <span>{placeholder ? placeholder : 'Select a product'}</span>
          )}
        </div>
        <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
          <path d="M5.5 7.5l4.5 4.5 4.5-4.5h-9z" />
        </svg>
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-md border border-[#4A56AF] bg-[#302388] shadow-lg">
          {options.map(option => (
            <div
              key={option.id}
              onClick={() => handleOptionClick(option)}
              className="flex cursor-pointer items-center p-2 hover:bg-[#4A56AF]"
            >
              <img
                src={option.image}
                alt={option.name}
                className="mr-2 w-9 rounded-full"
              />
              <span className="text-white">{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductSelect;
