import React, {useState, useEffect, useRef} from 'react';

interface FlyoverProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const Flyover: React.FC<FlyoverProps> = ({children, isOpen, onClose}) => {
  const flyoverRef = useRef<HTMLDivElement>(null);

  // Handle click outside of the flyover
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        flyoverRef.current &&
        !flyoverRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-50"></div>
      )}

      {/* Flyover Panel */}
      <div
        ref={flyoverRef}
        style={{
          background: 'linear-gradient(180deg, #18142D 0%, #2E246C 100%)',
        }}
        className={`fixed right-0 top-0 h-full w-1/3 transform shadow-lg ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } z-50 transition-transform duration-300 ease-in-out`}
      >
        <div className="relative p-4">
          {/* Close button (X icon) in the top-right corner */}
          <button
            onClick={onClose}
            className="absolute right-8 top-2 text-2xl text-gray-600 hover:text-gray-800"
          >
            &times;
          </button>

          {/* Flyover content */}
          {children}
        </div>
      </div>
    </>
  );
};

export default Flyover;
