import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Chart, ArcElement, Tooltip, Legend} from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

type ReachChartProps = {
  followers: number;
  nonFollowers: number;
  totalReach: number;
  chartTitle: string;
  centerText: string;
  nonFollowersLabel: string;
  followersLabel: string;
};

const ReachChart: React.FC<ReachChartProps> = ({
  followers,
  nonFollowers,
  totalReach,
  chartTitle,
  centerText,
  nonFollowersLabel,
  followersLabel,
}) => {
  const data = {
    labels: [nonFollowersLabel, followersLabel],
    datasets: [
      {
        data: [nonFollowers, followers],
        backgroundColor: ['#0070F3', '#00D8FF'],
        hoverBackgroundColor: ['#005BBB', '#00A9C4'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '60%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div>
      {/* <div className="text-white ml-8 text-md font-medium">{chartTitle}</div> */}

      <div className="justify-top flex w-full flex-col items-center justify-center py-4 text-white">
        <p className="text-center text-[2.5rem] font-bold">
          {totalReach.toLocaleString()}
        </p>
        <p className="mt-1 text-center text-sm">{centerText}</p>
      </div>

      <div className="flex h-full items-center justify-center p-4">
        <div className="relative mb-12 h-[250px] w-[250px]">
          <Doughnut data={data} options={options} />

          <div className="absolute left-[-168px] top-[30%] flex flex-col items-end text-white">
            <div className="mb-1 text-[2.25rem] text-xl font-semibold">
              {nonFollowers.toLocaleString()}
            </div>
            <div className="flex items-center">
              <div className="mr-1 h-3 w-3 bg-[#0070F3]"></div>
              <span className="text-md">{nonFollowersLabel}</span>
            </div>
          </div>
          <div className="absolute right-[-120px] top-[30%] flex flex-col items-start text-white">
            <div className="mb-1 text-[2.25rem] text-xl font-semibold">
              {followers.toLocaleString().padStart(5, '0')}
            </div>
            <div className="flex items-center">
              <div className="mr-1 h-3 w-3 bg-[#00D8FF]"></div>
              <span className="text-md">{followersLabel}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReachChart;

// Usage Example
// <ReachChart followers={1155} nonFollowers={25155} totalReach={56155} chartTitle="Reach" centerText="Account reached" nonFollowersLabel="Non Followers" followersLabel="Followers" />
