import React, {useState} from 'react';
import productSampleImage from '../../assets/images/icons/productSelection.png';
import ProductSelect from '../../components/core/Product/Dropdown/ProductSelect';
import arrow from '../../assets/images/icons/right-arrow.png';
import Flyover from '../../components/common/Flyover';

const DefaultPrefrences = () => {
  const [newAction, setNewAction] = useState(false);

  const creatorNotifications = [
    {action: 'Important updates and announcements.', email: true, inApp: true},
    {
      action: 'Changes in terms of service or privacy policy.',
      email: true,
      inApp: true,
    },
    {
      action: 'Recommendations based on your viewing or browsing history.',
      email: true,
      inApp: true,
    },
    {
      action: 'Allow new content to your favorite categories or genres.',
      email: true,
      inApp: true,
    },
    {
      action: 'Mentions of your username or handle in public posts.',
      email: true,
      inApp: true,
    },
    {
      action: 'Successful login attempts from new devices or locations.',
      email: true,
      inApp: true,
    },
    {
      action: 'Upcoming subscription renewals and payment reminders.',
      email: true,
      inApp: true,
    },
    {
      action: 'Changes in subscription pricing or plan upgrades.',
      email: true,
      inApp: true,
    },
  ];

  const userNotifications = [
    {action: 'Important updates and announcements.', email: true, inApp: true},
    {
      action: 'Changes in terms of service or privacy policy.',
      email: true,
      inApp: true,
    },
    {
      action: 'Recommendations based on your viewing or browsing history.',
      email: true,
      inApp: true,
    },
    {
      action: 'Allow new content to your favorite categories or genres.',
      email: true,
      inApp: true,
    },
    {
      action: 'Mentions of your username or handle in public posts.',
      email: true,
      inApp: true,
    },
    {
      action: 'Successful login attempts from new devices or locations.',
      email: true,
      inApp: true,
    },
  ];

  const creators = [
    {id: '1', name: 'Creator 1', image: productSampleImage},
    {id: '2', name: 'Creator 2', image: productSampleImage},
    {id: '3', name: 'Creator 3', image: productSampleImage},
  ];

  const users = [
    {id: '1', name: 'User 1', image: productSampleImage},
    {id: '2', name: 'User 2', image: productSampleImage},
    {id: '3', name: 'User 3', image: productSampleImage},
  ];

  return (
    <>
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-3xl">
          <span className="font-light tracking-wider">Default</span> Preferences
        </h1>
      </div>
      <div className="flex">
        <div className="w-1/2 rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
          <div className="mx-auto h-[880px] max-w-xl rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
            <div className="mb-4 flex items-center justify-between">
              <h3 className="text-xl font-semibold text-white">Creator</h3>
              <div className="flex items-center">
                <ProductSelect
                  placeholder="Select Creator"
                  options={creators}
                  onChange={() => {}}
                />
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left">
                    <th className="py-4">Action</th>
                    <th className="w-[90px] px-4 py-4">Email</th>
                    <th className="w-[90px] px-4 py-4">In-App</th>
                  </tr>
                </thead>
                <tbody>
                  {creatorNotifications.map((item, index) => (
                    <tr key={index} className="">
                      <td className="py-4 text-[#B1ADCD]">{item.action}</td>
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          defaultChecked={item.email}
                          className="form-checkbox h-5 w-5 text-purple-500"
                        />
                      </td>
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          defaultChecked={item.inApp}
                          className="form-checkbox h-5 w-5 text-purple-500"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <div className="mt-6 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <button
                  onClick={() => setNewAction(true)}
                  className="flex w-full items-center justify-between rounded-md bg-custom-gradient p-4 px-6 py-2 text-white shadow-custom-shadow transition-all"
                >
                  <span> {'Add new action'}</span>
                  <img src={arrow} className="w-2" alt="arrow" />
                </button>
              </div>
            </div>

            <div className="mt-4">
              <button className="relative rounded-[1.5rem] p-[1px]">
                <div
                  className="flex w-[188px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                  style={{
                    background:
                      'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                  }}
                >
                  Set to default
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="ml-5 w-1/2 rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
          <div className="mx-auto h-[880px] max-w-xl rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
            <div className="mb-4 flex items-center justify-between">
              <h3 className="text-xl font-semibold text-white">User</h3>
              <div className="flex items-center">
                <ProductSelect
                  placeholder="Select User"
                  options={users}
                  onChange={() => {}}
                />
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left">
                    <th className="py-4">Action</th>
                    <th className="w-[90px] px-4 py-4">Email</th>
                    <th className="w-[90px] px-4 py-4">In-App</th>
                  </tr>
                </thead>
                <tbody>
                  {userNotifications.map((item, index) => (
                    <tr key={index} className="">
                      <td className="py-4 text-[#B1ADCD]">{item.action}</td>
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          defaultChecked={item.email}
                          className="form-checkbox h-5 w-5 text-purple-500"
                        />
                      </td>
                      <td className="px-4 py-4">
                        <input
                          type="checkbox"
                          defaultChecked={item.inApp}
                          className="form-checkbox h-5 w-5 text-purple-500"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-4">
              <div className="mt-6 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <button
                  onClick={() => setNewAction(true)}
                  className="flex w-full items-center justify-between rounded-md bg-custom-gradient p-4 px-6 py-2 text-white shadow-custom-shadow transition-all"
                >
                  <span> {'Add new action'}</span>
                  <img src={arrow} className="w-2" alt="arrow" />
                </button>
              </div>
            </div>

            <div className="mt-4">
              <button className="relative rounded-[1.5rem] p-[1px]">
                <div
                  className="flex w-[188px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                  style={{
                    background:
                      'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                  }}
                >
                  Set to default
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Flyover isOpen={newAction} onClose={() => setNewAction(false)}>
        <div className="flex">
          <div className="w-full rounded-lg p-6 shadow-md">
            <h2 className="mb-4 text-2xl font-semibold text-white">
              Add New Action
            </h2>

            <div className="mb-4">
              <label htmlFor="action" className="mb-1 block text-white">
                Action
              </label>
              <input
                type="text"
                placeholder=""
                className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                value="3 Months"
              />
            </div>

            <div className="mb-4 flex items-center">
              <label className="mr-4 flex items-center text-white">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-500"
                />
                <span className="ml-2">Email</span>
              </label>

              <label className="flex items-center text-white">
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-500"
                />
                <span className="ml-2">In-app</span>
              </label>
            </div>

            <div className="flex space-x-4">
              <button className="relative rounded-[1.5rem] p-[1px]">
                <div
                  className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                  style={{
                    background:
                      'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                  }}
                >
                  Add
                </div>
              </button>

              <button className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Flyover>
    </>
  );
};

export default DefaultPrefrences;
