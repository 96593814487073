import React from 'react';
import hike from '../../../assets/images/icons/hike.png';

interface RenewalProps {
  title: string;
  price: string;
  planType: string;
  nextBillDate: string;
  planName: string;
}

const Renewal: React.FC<RenewalProps> = ({
  title,
  price,
  planType,
  nextBillDate,
  planName,
}) => {
  return (
    <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative rounded-md bg-custom-gradient p-6 shadow-custom-shadow">
        <h3 className="mb-4 text-lg font-semibold text-teal-400">{title}</h3>
        <div className="mb-4 text-5xl font-bold text-white">{price}</div>
        <p className="mb-1 text-sm text-gray-400">
          Plan type <span className="font-semibold text-white">{planType}</span>
        </p>
        <p className="text-sm text-gray-400">
          Next bill date{' '}
          <span className="font-semibold text-white">{nextBillDate}</span>
        </p>
        <div className="absolute right-6 top-20">
          <img src={hike} alt="hike" className="w-12" />
        </div>
      </div>
    </div>
  );
};

export default Renewal;
