import React from 'react';
import Search from '../search/Search';

interface FilterOption {
  label: string;
  value: any; // Replace `any` with a more specific type if needed
}

// Define types for the data structure
interface TableProps<T> {
  title: string;
  headings: string[];
  rows: T[];
  enableSearch?: boolean;
  enableFilter?: boolean;
  filterOptions?: FilterOption[];
  searchPlaceholder?: string;
  selectedOption?: string; // Allow passing the selected option
  renderRow: (row: T) => JSX.Element;
  onFilterChange?: (value: any) => void;
}

const Table = <T,>({
  title,
  headings,
  rows,
  renderRow,
  enableSearch,
  enableFilter,
  filterOptions,
  searchPlaceholder,
  selectedOption = '', // Default to an empty string if not provided
  onFilterChange = (value: any) => {},
}: TableProps<T>) => {
  return (
    <div className="mx-auto rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="rounded-md bg-custom-gradient p-6 shadow-custom-shadow">
        {/* Flex container for title, search, and filter */}
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-white">{title}</h2>
          <div className="flex items-center space-x-8">
            {' '}
            {/* Increased space between items */}
            {enableFilter && filterOptions && (
              <div className="relative w-[250px]">
                {' '}
                {/* Fixed width for the select dropdown */}
                <select
                  value={selectedOption} // Use selectedOption prop for controlled component
                  onChange={e => onFilterChange(e.target.value)} // Handle change as needed
                  className="relative w-full appearance-none rounded-md border border-[#c49af9] bg-[#171233] px-4 py-1 pr-8 text-white shadow-custom-shadow outline-none"
                >
                  {filterOptions.map(option => (
                    <option
                      key={option.value}
                      className="bg-[#1b1446]"
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-purple-400">
                  <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                    <path d="M5.5 7.5l4.5 4.5 4.5-4.5h-9z" />
                  </svg>
                </div>
              </div>
            )}
            {enableSearch && (
              <div className="min-w-[250px] flex-grow">
                {' '}
                {/* Set same fixed width for search */}
                <Search
                  className="h-10 w-full"
                  placeholder={searchPlaceholder}
                  onSearch={() => {}}
                />
              </div>
            )}
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full text-left text-sm">
            <thead className="bg-custom-purple-1 text-white">
              <tr>
                {headings.map((heading, index) => (
                  <th key={index} className="px-6 py-4 font-medium">
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-white">
              {rows.map((row, index) => (
                <tr key={index} className="border-b border-gray-700">
                  {renderRow(row)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
