import {NavLink} from 'react-router-dom';
import '../../assets/styles/creator/sidebar.css';
import lussoLogo from '../../assets/images/logo/lusso.png';
import {ENUM_NAV} from '../../constants/navigation.constant';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../ui/Button';
import {setSidebarOpen} from '../../store/General/General';
import {ReactComponent as XIcon} from '../../assets/images/icons/x-solid.svg';
import AiSearch from '../ui/AiSearch';

const Sidebar = () => {
  const sidebarState = useSelector((state: any) => state.general.sidebarOpen);
  const dispatch = useDispatch();

  return (
    <div
      className={`flex h-screen w-72 flex-col justify-between border-r border-r-[rgba(108,140,255,0.5)] bg-sidebar-gradient text-sm font-semibold text-gray-400 shadow-sidebar-glow transition-all delay-300 md-lt:fixed md-lt:z-50 md-lt:w-full md-lt:gap-6 ${!sidebarState ? 'md-lt:-ml-[100%]' : 'md-lt:ml-0'}`}
    >
      {/* Logo Section */}
      <div className="p-6 md-lt:flex md-lt:items-center md-lt:justify-between">
        <img
          src={lussoLogo}
          alt="Lusso AI Logo"
          className="w-full md-lt:w-1/2"
        />
        <Button
          className="rounded-full bg-transparent p-2 hover:bg-[#00000022] active:bg-[#00000044] lg:hidden"
          type="custom"
          onClick={() => dispatch(setSidebarOpen())}
        >
          <XIcon className="h-8 w-8 fill-white" />
        </Button>
      </div>

      {/* Search Bar */}
      <div className="px-6 lg:hidden">
        <AiSearch />
      </div>

      {/* Navigation Links */}
      <nav className="w-full flex-grow overflow-y-auto">
        <ul className="flex flex-col gap-3 pl-6">
          {/* Individual Menu Items */}
          {ENUM_NAV.map(item => (
            <li key={item.id}>
              <NavLink
                to={item.path}
                onClick={() => sidebarState && dispatch(setSidebarOpen())}
                className={({isActive}) =>
                  `flex items-center gap-4 rounded-lg px-4 py-3 transition-colors hover:bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] ${
                    isActive
                      ? 'bg-[linear-gradient(89.97deg,_rgba(0,255,255,0.1)_0.03%,_rgba(0,240,251,0)_99.97%)] stroke-[#00F0FB] text-[#00F0FB]'
                      : 'stroke-gray-400'
                  }`
                }
              >
                <item.icon className="h-5 w-5 stroke-inherit" />
                <span>{item.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
