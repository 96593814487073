import React from 'react';
import card from '../../../assets/images/icons/card.png';
interface PlanBenefitsProps {
  title: string;
  accessPlan: string;
  lussoPlan: string;
  benefit1: string;
  benefit2: string;
  benefit3: string;
}

const PlanBenefits: React.FC<PlanBenefitsProps> = ({
  title,
  accessPlan,
  lussoPlan,
  benefit1,
  benefit2,
  benefit3,
}) => {
  return (
    <div className="mt-4 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative rounded-md bg-custom-gradient p-6 shadow-custom-shadow">
        <h3 className="mb-4 text-lg font-semibold text-teal-400">{title}</h3>
        <div className="text-5xl font-bold tracking-widest text-white">
          {accessPlan}
        </div>
        <p className="mb-6 text-4xl font-light tracking-widest text-gray-400">
          {lussoPlan}
        </p>
        <div className="absolute right-6 top-[70px]">
          <img src={card} className="w-12" alt="Card" />
        </div>
        <hr className="my-4 border-gray-600" />
        <ul className="space-y-4 text-gray-300">
          <li className="flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="teal"
              className="mr-3 mt-[2px] h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>{benefit1}</span>
          </li>
          <li className="flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="teal"
              className="mr-3 mt-[2px] h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>{benefit2}</span>
          </li>
          <li className="flex items-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="teal"
              className="mr-3 mt-[2px] h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>{benefit3}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PlanBenefits;
