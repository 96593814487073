import React, { useReducer, useEffect, useState } from 'react';
import hike from '../../../assets/images/icons/hike.png';
import downfall from '../../../assets/images/icons/downfall.png';
import Table from '../../common/Table';
import CustomDateRangePicker from '../../common/CustomDateRangePicker';
import fbBlue from '../../../assets/images/icons/fbBlue.png';
import dp from '../../../assets/images/icons/dp.png';
import view from '../../../assets/images/icons/view.png';
import more from '../../../assets/images/icons/more.png';
import CurveLineGraph from '../../common/Graphs/CurveLineGraph';
import HikeCount from '../AnalyticsCounter/HikeCount';
import { useParams } from 'react-router-dom';
import { useGetCreatorSocialAnalytics } from '../../../hooks/creatorQueryHooks';

interface State {
  analyticsData: any | null;
  graphDataValues: any | null;
  graphDataDates: any | null;
}

const initialState: State = {
  analyticsData: null,
  graphDataValues: null,
  graphDataDates: null,
};

type Action =
  | { type: 'SET_ANALYTICS_DATA'; payload: any }
  | { type: 'SET_GRAPH_DATA_VALUES'; payload: any }
  | { type: 'SET_GRAPH_DATA_DATES'; payload: any };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_ANALYTICS_DATA':
      return { ...state, analyticsData: action.payload };
    case 'SET_GRAPH_DATA_VALUES':
      return { ...state, graphDataValues: action.payload };
    case 'SET_GRAPH_DATA_DATES':
      return { ...state, graphDataDates: action.payload };
    default:
      return state;
  }
}

const SocialAnalytics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { analyticsData, graphDataValues, graphDataDates } = state;

  const [activePlatform, setActivePlatform] = useState('facebook');
  const { mutate } = useGetCreatorSocialAnalytics();
  const { id } = useParams<{ id: string }>();

  const shortDateFormat = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day} ${month}`;
  };

  const fetchProductAnalytics = async (from: string, to: string, platform: string) => {
    if (!id) return;
    mutate(
      { fromDate: from, toDate: to, creatorId: id, platform },
      {
        onSuccess: (response) => {
          console.log(response.payload, 'payload');
          dispatch({ type: 'SET_ANALYTICS_DATA', payload: response.payload });

          const followersDates = response.payload?.followers[activePlatform]?.map((item: { dateTime: string }) =>
            shortDateFormat(item.dateTime)
          );
          const impressionsDates = response.payload?.impressions[activePlatform]?.map((item: { dateTime: string }) =>
            shortDateFormat(item.dateTime)
          );
          const interactionsDates = response.payload?.interactions[activePlatform]?.[0]?.values?.map(
            (item: { dateTime: string }) => shortDateFormat(item.dateTime)
          );
          const postCountDates = response.payload?.postCount[activePlatform]?.[0]?.values?.map(
            (item: { dateTime: string }) => shortDateFormat(item.dateTime)
          );

          const mergedDates = Array.from(new Set([...(followersDates || []), ...(impressionsDates || []), ...(interactionsDates || []), ...(postCountDates || [])]));

          dispatch({ type: 'SET_GRAPH_DATA_DATES', payload: mergedDates });
          dispatch({
            type: 'SET_GRAPH_DATA_VALUES',
            payload: {
              followers: response.payload?.followers[activePlatform]?.map((item: { value: any }) => item.value),
              impressions: response.payload?.impressions[activePlatform]?.map((item: { value: any }) => item.value),
              interactions: response.payload?.interactions[activePlatform]?.[0]?.values?.map(
                (item: { value: any }) => item.value
              ),
              postCount: response.payload?.postCount[activePlatform]?.[0]?.values?.map(
                (item: { value: any }) => item.value
              ),
            },
          });
        },
      }
    );
  };

  useEffect(() => {
    if (id) {
      console.log(id, 'creatorId');
      fetchProductAnalytics('2023-01-01T00:00:00', '2024-11-26T00:00:00', 'facebook');
    }
  }, [id]);

  const headings = ['', '', 'NETWORK', 'DATA', 'IMPRESSIONS', 'INTERACTION'];
  const rows = [
    {
      postPhoto: dp,
      description: 'Transform your digital dreams into...',
      network: fbBlue,
      date: 'Aug-25-2024 07:01 AM',
      impressions: '120k',
      interaction: '900',
    },
    {
      postPhoto: dp,
      description: 'Transform your digital dreams into...',
      network: fbBlue,
      date: 'Aug-25-2024 07:01 AM',
      impressions: '120k',
      interaction: '900',
    },
    {
      postPhoto: dp,
      description: 'Transform your digital dreams into...',
      network: fbBlue,
      date: 'Aug-25-2024 07:01 AM',
      impressions: '120k',
      interaction: '900',
    },
  ];
  const hikeCardData = [
    {
      title: 'Followers',
      image: hike,
      count: analyticsData?.followers?.headerCalculations?.headerItems[0]?.value ?? 0,
      trend: analyticsData?.followers?.headerCalculations?.headerItems[0]?.trend ?? ''
    },
    {
      title: 'Impressions',
      image: hike,
      count: analyticsData?.impressions?.headerCalculations?.headerItems[0]?.value ?? 0,
      trend: analyticsData?.impressions?.headerCalculations?.headerItems[0]?.trend ?? ''
    },
    {
      title: 'Interactions',
      image: hike,
      count: analyticsData?.interactions?.headerCalculations?.headerItems[0]?.value ?? 0,
      trend: analyticsData?.interactions?.headerCalculations?.headerItems[0]?.trend ?? ''
    },
    {
      title: 'Number of Posts',
      image: downfall,
      count: analyticsData?.postCount?.headerCalculations?.headerItems[0]?.value ?? 0,
      trend: analyticsData?.postCount?.headerCalculations?.headerItems[0]?.trend ?? ''
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">
        <img src={row.postPhoto} alt="display" className="mr-2 w-10" />
      </td>
      <td className="px-6 py-4">
        <p>{row.description}</p>
        <p className="flex-start flex">
          <img
            src={more}
            className="mr-[5px] mt-[5px] h-[10px] w-[7px]"
            alt="more"
          />{' '}
          More{' '}
          <img
            src={view}
            className="ml-5 mr-[5px] mt-[5px] h-[10px] w-[7px]"
            alt="view"
          />{' '}
          View
        </p>
      </td>
      <td className="flex items-center px-6 py-4">
        <img src={row.network} alt="Visa Logo" className="mr-2 w-10" />
      </td>
      <td className="px-6 py-4">{row.date}</td>
      <td className="px-6 py-4">{row.impressions}</td>
      <td className="px-6 py-4">{row.interaction}</td>
    </>
  );
  const data = {
    labels: graphDataDates,
    datasets: [
      {
        label: 'Followers',
        data: graphDataValues?.followers,
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0.4,
      },
      {
        label: 'Impressions',
        data: graphDataValues?.impressions,
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        tension: 0.4,
      },
      {
        label: 'Interactions',
        data: graphDataValues?.interactions,
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        tension: 0.4,
      },
      {
        label: 'No of Posts',
        data: graphDataValues?.postCount,
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const [selectedOption, setSelectedOption] = useState('scheduled');
  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };
  const [selectedRange, setSelectedRange] = useState<{ start: Date | null, end: Date | null }>({
    start: null,
    end: null,
  });

  const handleDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
    setSelectedRange({ start: startDate, end: endDate });
    console.log('Selected Range:', { startDate, endDate });
  };

  return (
    <>
    <div className="py-4">
      <CustomDateRangePicker onChange={handleDateRangeChange} />
    </div>
    <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <CurveLineGraph
              data={data}
              options={options}
              title="Page Analytics"
            />
          </div>
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>
      <div className="flex w-full flex-wrap">
        
        <div className="mt-6 w-full">
          <Table
            title="List of Posts"
            headings={headings}
            rows={rows}
            renderRow={renderRow}
            enableSearch={true}
            enableFilter={true}
            filterOptions={[
              {label: 'Scheduled', value: 'scheduled'},
              {label: 'Publish', value: 'publish'},
              {label: 'Review', value: 'review'},
            ]}
            selectedOption={selectedOption}
            searchPlaceholder="Search Post"
            onFilterChange={handleFilterChange} // Pass down the change handler
          />
        </div>
      </div>
    </>
  );
};

export default SocialAnalytics;
