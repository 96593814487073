import React from 'react';
import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const selectOptions = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

// Register components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const CurveLineGraph = ({data, options, title = '', showLabels = true}) => {
  return (
    <div className="h-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="flex h-full flex-col gap-6 rounded-md bg-custom-gradient px-4 py-6 shadow-custom-shadow">
        {title && (
          <div className="flex items-center justify-between px-4">
            <h1 className="text-2xl text-white md-lt:text-xl">{title}</h1>
            {showLabels ? (
              <div className="flex items-center gap-3">
                {data?.datasets?.map((dataset, index) => (
                  <div key={index} className="flex items-center gap-1">
                    <div
                      className={`h-3 w-3 rounded-sm`}
                      style={{
                        backgroundColor: dataset.backgroundColor,
                      }}
                    ></div>
                    <span className="text-[0.6rem] text-white">
                      {dataset.label}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
                {selectOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            )}
          </div>
        )}
        {/* Set this div to full height to make it dynamic */}
        <div className="flex-grow">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default CurveLineGraph;
