import React from 'react';
import {Outlet} from 'react-router-dom';
import Sidebar from '../components/common/Sidebar';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import '../assets/styles/creator/creator-layout.css'; // Custom CSS for layout

const CreatorLayout = () => {
  return (
    <div className="creator-layout flex h-screen w-screen">
      <Sidebar /> {/* Adjust width as needed */}
      <div className="main-content flex w-screen flex-1 flex-col lg:max-h-[100vh] md-lt:overflow-y-scroll">
        <Header />
        <div className="flex-1 bg-[#171232]/20 px-10 py-6 lg:overflow-auto md-lt:px-6">
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CreatorLayout;
