import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from '../../context/AuthContext';

const PrivateRoutes = () => {
  const location = useLocation();
  const {isAuthenticated} = useAuth();

  if (!isAuthenticated)
    return <Navigate to="/login" state={{from: location.pathname}} replace />;

  return <Outlet />;
};

export default PrivateRoutes;
