import React, {useState} from 'react';
import UserInput from '../../common/UserInput';
import DropDownFilter from '../../common/DropDownFilter';
import Flyover from '../../common/Flyover';

interface AccountInfoProps {
  profileInfo?: any;
}
const CreatorForms: React.FC<AccountInfoProps> = ({profileInfo}) => {
  const [changePassword, setChangePassword] = useState(false);

  return (
    <>
      <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] text-white">
        <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-6 shadow-custom-shadow">
          <h2 className="mb-6 text-lg font-semibold">Personal Info</h2>
          <div className="mb-8">
            <label className="mb-2 block text-sm font-medium">Name</label>
            <UserInput value={'David John'} onChange={() => {}} />
          </div>

          <div className="mb-8">
            <label className="mb-2 block text-sm font-medium">Email</label>
            <UserInput value={'davidjohn@gmail.com'} onChange={() => {}} />
          </div>
        </div>
      </div>

      <div className="mt-10 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] text-white">
        <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-6 shadow-custom-shadow">
          <h2 className="mb-6 text-lg font-semibold">Set Password</h2>
          <div className="mb-8">
            <label className="mb-2 block text-sm font-medium">
              Current Password
            </label>
            <UserInput
              value={'David John'}
              type="password"
              onChange={() => {}}
            />
          </div>

          <div className="mb-8" onClick={() => setChangePassword(true)}>
            <DropDownFilter
              width="full"
              classes=""
              placeholder="Change Password"
              options={[]}
              activeOption=""
              disabled={true}
              onChange={() => {}}
            />
          </div>
        </div>
      </div>

      {changePassword && (
        <Flyover
          isOpen={changePassword}
          onClose={() => setChangePassword(false)}
        >
          <div className="flex min-h-screen p-4">
            <div className="w-full max-w-md shadow-md">
              <h2 className="mb-6 text-lg font-bold text-white">
                Change Password
              </h2>

              <div className="mb-6">
                <label
                  htmlFor="new-password"
                  className="mb-2 block text-sm font-medium text-white"
                >
                  New Password
                </label>
                <UserInput
                  value={'David John'}
                  type="password"
                  onChange={() => {}}
                />
              </div>

              <div className="mb-8">
                <label
                  htmlFor="confirm-password"
                  className="mb-2 block text-sm font-medium text-white"
                >
                  Confirm Password
                </label>
                <UserInput
                  value={'David John'}
                  type="password"
                  onChange={() => {}}
                />
              </div>

              <div className="mt-4 flex space-x-4">
                <button className="relative rounded-[1.5rem] p-[1px]">
                  <div
                    className="flex w-[170px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                    style={{
                      background:
                        'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                    }}
                  >
                    Save Changes
                  </div>
                </button>

                <button className="w-[170px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Flyover>
      )}
    </>
  );
};

export default CreatorForms;
