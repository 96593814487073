import React from 'react';
import {cn} from '../../lib/utils';

interface IconProps {
  component: any;
  position: 'start' | 'end';
}

interface ButtonProps {
  label?: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | 'custom';
  onClick?: (e: any) => void;
  icon?: IconProps;
  children?: React.ReactNode;
  border?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label = '',
  disabled = false,
  isLoading = false,
  className = '',
  type = 'button',
  onClick = () => console.log('...clicked'),
  icon = {},
  children,
  border = false,
}) => {
  return type === 'submit' ? (
    <button
      type={type}
      className={cn(
        'flex items-center justify-center gap-2.5 text-white',
        className,
      )}
      disabled={isLoading || disabled} // Disable button when loading
    >
      {isLoading && <div className="loading loading-spinner loading-sm"></div>}
      {icon?.position === 'start' && icon?.component}
      {!isLoading ? label : ''}
      {icon?.position === 'end' && icon?.component}
    </button>
  ) : type === 'custom' ? (
    <button
      type="button"
      onClick={onClick}
      className={cn('text-white', className)}
      style={{border: 'none'}}
      disabled={isLoading || disabled}
    >
      {children}
    </button>
  ) : (
    <button
      type={type}
      onClick={onClick}
      className={cn(
        `flex items-center justify-center gap-2.5 text-white ${border ? 'border' : 'border-none'}`,
        className,
      )}
      disabled={isLoading || disabled} // Disable button when loading
    >
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <div className="loading loading-spinner loading-xs"></div>
        </div>
      )}
      {icon?.position === 'start' && icon?.component}
      {!isLoading ? label : ''}
      {icon?.position === 'end' && icon?.component}
    </button>
  );
};

export default Button;
