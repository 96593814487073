import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import topTrendingSample from '../../assets/images/icons/topTrendingSample.jpg';
import {useNavigate} from 'react-router-dom';
import TabList from '../../components/common/TabNavigation/TabList';
import TabItem from '../../components/common/TabNavigation/TabItem';
import OverView from './productsOverview';
import AllProductsListing from '../../components/core/Product/ProductListing';
import ProductDetail from '../../components/core/Product/ProductDetail';

const Products = () => {
  const [showHistoryButton, setShowHistoryButton] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex flex-col gap-8 text-white">
        <div className="flex items-center justify-between px-6">
          <h1 className="text-3xl">
            <span className="font-light tracking-wider">Products</span>
          </h1>
        </div>
        <TabList
          activeTabIndex={0}
          gap={6}
          listClasses="w-fit divide-x divide-[#4e57c9] overflow-hidden rounded-t-2xl border-x border-t border-[#4e57c9]"
          itemClasses="px-6 py-2 capitalize"
        >
          <TabItem label="Overview">
            <OverView />
          </TabItem>
          <TabItem label="Products">
            <AllProductsListing showHeader={false} />
          </TabItem>
          {/* <TabItem label="Review Products">
            <ProductDetail />
          </TabItem> */}
        </TabList>
      </div>
    </>
  );
};

export default Products;
