import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';

interface DateRangePickerProps {
  onChange: (startDate: Date | null, endDate: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({ onChange }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [rangeLabel, setRangeLabel] = useState<string>('Today');

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start) {
      setStartDate(start);
    }
    if (end) {
      setEndDate(end);
    }
    if (start && end) {
      setRangeLabel(`${format(start, 'MMM dd, yyyy')} - ${format(end, 'MMM dd, yyyy')}`);
      onChange(start, end);
    }
  };

  const predefinedRanges = [
    { label: 'Today', range: [new Date(), new Date()] },
    { label: 'Yesterday', range: [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(new Date().setDate(new Date().getDate() - 1)),
    ]},
    { label: 'Last week', range: [
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ]},
    { label: 'Last 7 days', range: [
      new Date(new Date().setDate(new Date().getDate() - 7)),
      new Date(),
    ]},
    { label: 'This month', range: [
      new Date(new Date().setDate(1)),
      new Date(),
    ]},
    { label: 'Last 30 days', range: [
      new Date(new Date().setDate(new Date().getDate() - 30)),
      new Date(),
    ]},
    { label: 'Custom range', range: [null, null] },
  ];

  return (
    <div className="relative inline-block text-left">
      {/* <button
        onClick={() => setOpen(!open)}
        className="bg-[#1E1E2D] text-white rounded-lg px-4 py-2 flex items-center space-x-2 shadow-md hover:bg-[#2A2A3D]"
      >
        <FaCalendarAlt />
        <span>{rangeLabel}</span>
      </button> */}

      <button onClick={() => setOpen(!open)} className="flex items-center px-4 py-2 bg-[#2F2386] text-white rounded-lg shadow-md space-x-2 hover:bg-[#4E28A9]">
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#00F0FB]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10m-1 12H6a2 2 0 01-2-2V8a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2z" />
  </svg>
  <span>{rangeLabel}</span>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0l-4.25-4.25a.75.75 0 01-.02-1.06z" clipRule="evenodd" />
  </svg>
</button>
      {open && (
        <div className="absolute z-50 mt-2 w-[800px] rounded-lg shadow-lg bg-[#18142D] text-white">
          <div className="flex p-4">
            <div className="w-3/4 pr-4">
              <div className="flex">
                <div className="w-1/2 pr-4">
                  <h3 className="text-lg mb-4">From</h3>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => handleDateChange([date as Date | null, endDate])}
                    startDate={startDate ?? undefined}
                    endDate={endDate ?? undefined}
                    selectsStart
                    inline
                    calendarClassName="custom-calendar"
                  />
                </div>
                <div className="w-1/2 pl-4">
                  <h3 className="text-lg mb-4">To</h3>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => handleDateChange([startDate, date as Date | null])}
                    startDate={startDate ?? undefined}
                    endDate={endDate ?? undefined}
                    selectsEnd
                    inline
                    calendarClassName="custom-calendar"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/4 pl-4">
              <h3 className="text-lg mb-4">Select a Range</h3>
              {predefinedRanges.map((item) => (
                <button
                  key={item.label}
                  className={`text-left w-full bg-[#BCC0C01F] hover:bg-[#6C8CFF8A] px-4 py-2 rounded-lg mb-2 ${rangeLabel === item.label ? 'bg-blue-700' : ''}`}
                  onClick={() => {
                    if (item.label !== 'Custom range') {
                      handleDateChange(item.range as [Date, Date]);
                    } else {
                      setStartDate(null);
                      setEndDate(null);
                      setRangeLabel('Custom range');
                    }
                  }}
                >
                  {item.label}
                </button>
              ))}
            </div>
          </div>
          <div className="flex justify-end p-4 border-t border-gray-700">
            <button
              className="text-gray-400 hover:text-white mr-2"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button
              className="bg-[#4E4E6E] hover:bg-[#6C6C8D] text-white rounded-lg px-4 py-2"
              onClick={() => setOpen(false)}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
