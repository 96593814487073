import React from 'react';
import dp from '../../../assets/images/icons/default-pic.jpg';
import fb from '../../../assets/images/icons/fb.png';
import insta from '../../../assets/images/icons/insta.png';
import linkedin from '../../../assets/images/icons/linkedin.png';
import google from '../../../assets/images/icons/google.png';
import Badge from '../../ui/Badge';
import {Link} from 'react-router-dom';

interface ProfileCardProps {
  showSocials?: boolean;
  name?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  showSocials = false,
  name = '',
}) => {
  return (
    <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-12 shadow-custom-shadow">
        <div
          className={`mx-auto ${showSocials ? 'h-28 w-28' : 'h-36 w-36'} overflow-hidden rounded-full bg-gradient-to-r from-purple-500 to-teal-400 p-[1px]`}
        >
          <img
            src={dp}
            alt="Profile"
            className="h-full w-full rounded-full object-cover object-center"
          />
        </div>
        <div className="mx-auto flex flex-col items-center gap-4 text-center">
          <Badge
            value={'Active'}
            textColor={'#6AC92F'}
            bgColor={'#67F07D1F'}
            rounded="full"
          />
          <h2 className="text-2xl font-semibold text-white">{name}</h2>
          {showSocials && (
            <div className="flex justify-center space-x-6">
              <Link to="#" className="hover:opacity-80">
                <img src={insta} alt="Instagram" className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:opacity-80">
                <img src={fb} alt="Facebook" className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:opacity-80">
                <img src={linkedin} alt="LinkedIn" className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:opacity-80">
                <img src={google} alt="Other" className="h-6 w-6" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
