import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import ProductStats from './ProductStats';
import ActiveProducts from './ActiveProducts';
import InactiveProducts from './InactiveProducts';
import ProductDetail from './ProductDetail';
import Search from '../../search/Search';
import {useGetProducts} from '../../../hooks/productQueryHooks';
import {useParams} from 'react-router-dom';
import Loading from '../../common/Loading';
import Pagination from '../../common/Pagination';
import {useSearchParams} from 'react-router-dom';

interface ProductListingProps {
  showHeader?: boolean;
}

const ProductListing: React.FC<ProductListingProps> = ({showHeader = true}) => {
  const [searchParams] = useSearchParams();
  const action = searchParams.get('action');
  const {id} = useParams<{id: string}>();
  const openDetail = useSelector(
    (state: any) => state.general.productDetailView,
  );
  const [showProducts, setShowProduct] = useState('active');

  const [fetchedRecords] = useState<number>(20);
  const [start, setStart] = useState<number>(0);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (action) {
      if (action === 'activeProducts') {
        setShowProduct('active');
      } else if (action === 'pendingProducts') {
        setShowProduct('pending');
      }
    } else {
      setShowProduct('active');
    }
  }, [action]);

  const onPageChange = (newStart: number) => {
    setStart(newStart);
  };

  const handleSearch = useCallback((query: string) => {
    setSearchValue(query);
    setStart(0);
  }, []);

  const {data: products, isPending} = useGetProducts(
    id,
    fetchedRecords,
    start,
    searchValue,
    showProducts,
  );

  const totalRecords = products?.recordsFiltered || 0;
  const getProducts = () => {
    if (showProducts === 'active') {
      return (
        <ActiveProducts
          products={products?.products}
          totalRecords={totalRecords}
          fetchedRecords={fetchedRecords}
          start={start}
          onPageChange={onPageChange}
        />
      );
    } else if (showProducts === 'inactive') {
      return (
        <InactiveProducts
          products={products?.products}
          totalRecords={totalRecords}
          fetchedRecords={fetchedRecords}
          start={start}
          onPageChange={onPageChange}
        />
      );
    } else {
      return (
        <ActiveProducts
          products={products?.products}
          totalRecords={totalRecords}
          fetchedRecords={fetchedRecords}
          start={start}
          onPageChange={onPageChange}
        />
      );
    }
  };

  return (
    <>
      {!openDetail && (
        <div className="w-full">
          {showHeader && (
            <ProductStats
              totalProducts={products?.totalProductsCount}
              pendingProducts={0}
              rejectedProducts={products?.rejectedProductsCount}
              activeProducts={products?.activeProductsCount}
            />
          )}
          {/* Product Listing */}
          <div className="mt-4 w-full">
            <div className="w-full">
              {/* Top Section: Products Label and Select Box */}
              <div className="mb-6 flex w-full items-center justify-between">
                <p className="text-lg font-medium text-gray-300">Products</p>

                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <select
                      onChange={e => setShowProduct(e.target.value)}
                      className="h-9 w-[250px] appearance-none rounded-md border border-[#c49af9] bg-[#171233] px-4 py-1 pr-8 text-white shadow-custom-shadow outline-none"
                      value={showProducts}
                    >
                      <option className="bg-[#1b1446]" value="active">
                        Active
                      </option>
                      <option className="bg-[#1b1446]" value="inactive">
                        Inactive
                      </option>
                      <option className="bg-[#1b1446]" value="pending">
                        Pending
                      </option>
                      <option className="bg-[#1b1446]" value="archived">
                        Archived
                      </option>
                      <option className="bg-[#1b1446]" value="rejected">
                        Rejected
                      </option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-purple-400">
                      <svg className="h-4 w-4 fill-current" viewBox="0 0 20 20">
                        <path d="M5.5 7.5l4.5 4.5 4.5-4.5h-9z" />
                      </svg>
                    </div>
                  </div>

                  <Search
                    className="h-10 w-[250px]"
                    placeholder={'Serach Product'}
                    onSearch={handleSearch}
                  />
                </div>
              </div>

              {/* Product Listings */}
              {!isPending && getProducts()}

              {isPending ? (
                <div className="flex h-60 w-full items-center justify-center">
                  <Loading />
                </div>
              ) : (
                totalRecords > fetchedRecords && (
                  <Pagination
                    totalRecords={totalRecords}
                    fetchedRecords={fetchedRecords}
                    start={start}
                    onPageChange={onPageChange}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}

      {openDetail && <ProductDetail />}
    </>
  );
};

export default ProductListing;
