import React, {FC, useState} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

type InputProps = {
  type?: string;
  label?: string;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const UserInput: FC<InputProps> = ({
  value = '',
  label = '',
  onChange,
  type = 'text',
  placeholder = '',
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggles the password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  // Determine input type based on `showPassword` state
  const inputType = type === 'password' && !showPassword ? 'password' : 'text';

  return (
    <div className="relative">
      <label className="mb-2 block text-sm font-medium">{label}</label>
      <input
        type={inputType}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
        disabled={disabled}
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-[14px] top-[14px]"
        >
          {showPassword ? (
            <FaEye className="text-white" />
          ) : (
            <FaEyeSlash className="text-white" />
          )}
        </button>
      )}
    </div>
  );
};

export default UserInput;
