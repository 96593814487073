import UserInput from '../../common/UserInput';
// import FormSelect from '../../common/FormSelect';

interface AccountInfoProps {
  profileInfo?: any;
}
const CreatorForms: React.FC<AccountInfoProps> = ({profileInfo}) => {
  return (
    <>
      <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-12 shadow-custom-shadow">
          <h2 className="mb-6 text-lg font-semibold">Organization</h2>
          <div className="mb-8">
            <label className="mb-2 block text-sm font-medium">
              Organization Type
            </label>
            <UserInput value={'INDIVIDUAL'} onChange={() => {}} />
          </div>

          <h2 className="mb-6 text-lg font-semibold">Creator Details</h2>
          <div className="space-y-6">
            <div>
              <label className="mb-2 block text-sm font-medium">
                Creator Name
              </label>
              <UserInput value={profileInfo?.fullName} onChange={() => {}} />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">Email</label>
              <UserInput
                value={profileInfo?.email}
                type="email"
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">Phone</label>
              <UserInput
                value={profileInfo?.phoneNumber}
                type="tel"
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-12 shadow-custom-shadow">
          <h2 className="mb-6 text-lg font-semibold">Legal Name and Country</h2>
          <div className="space-y-6">
            <div>
              <label className="mb-2 block text-sm font-medium">
                Full Name <span className="text-red-500">*</span>
              </label>
              <UserInput
                value={profileInfo?.fullName}
                type="text"
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">
                Country/region <span className="text-red-500">*</span>
              </label>
              <UserInput
                value={`${profileInfo?.country} ${profileInfo?.state ? '/' + profileInfo?.state : ''}`}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        <div className="relative flex flex-col gap-4 rounded-md bg-custom-gradient p-4 px-8 py-12 shadow-custom-shadow">
          <h2 className="mb-6 text-lg font-semibold">Additional Details</h2>
          <div className="space-y-6">
            <div>
              <label className="mb-2 block text-sm font-medium">
                Country/region
              </label>
              <UserInput
                value={`${profileInfo?.country} ${profileInfo?.state ? '/' + profileInfo?.state : ''}`}
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">
                Full Name
              </label>
              <UserInput
                value={profileInfo?.fullName}
                placeholder="Enter Full Name"
                type="text"
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">
                Street Address <span className="text-red-500">*</span>
              </label>
              <UserInput
                value={''}
                placeholder="Enter Street Address"
                type="text"
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">
                Apt, Suite, etc..(optional)
              </label>
              <UserInput
                value={profileInfo?.aptSuite}
                placeholder="Enter apartment, street"
                type="text"
                onChange={() => {}}
              />
            </div>
            <div>
              <label className="mb-2 block text-sm font-medium">
                City <span className="text-red-500">*</span>
              </label>
              <UserInput
                value={profileInfo?.city}
                placeholder="Enter City"
                type="text"
                onChange={() => {}}
              />
            </div>
            <div className="flex space-x-4">
              <div className="flex-1">
                <label className="mb-2 block text-sm font-medium">State</label>
                <UserInput
                  value={profileInfo?.state}
                  placeholder="Enter State"
                  type="text"
                  onChange={() => {}}
                />
              </div>
              <div className="flex-1">
                <label className="mb-2 block text-sm font-medium">
                  Zip Code <span className="text-red-500">*</span>
                </label>
                <UserInput
                  value={profileInfo?.zipcode}
                  placeholder="Zip Code"
                  type="text"
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorForms;
