import {useState, useEffect} from 'react';
import Table from '../../components/common/Table';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import hike from '../../assets/images/icons/hike.png';
import downfall from '../../assets/images/icons/downfall.png';
import Badge from '../../components/ui/Badge';
import {useParams} from 'react-router-dom';
import {useGetCreatorProductsAnalytics} from '../../hooks/creatorQueryHooks';

const CreatorProductsAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState<any | null>(null); // Local state
  const [graphData, setGraphData] = useState<any | null>(null); // Local state

  const {mutate} = useGetCreatorProductsAnalytics();

  const {id} = useParams<{id: string}>();

  const fetchProductAnalytics = (from: string, to: string) => {
    if (!id) return;
    mutate(
      {fromDate: from, toDate: to, creatorId: id},
      {
        onSuccess: response => {
          setAnalyticsData(response.payload);
          const formattedDates = response.payload?.posts?.map(
            (item: {dateTime: string | number | Date}) => {
              const date = new Date(item.dateTime);
              return date.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
              });
            },
          );
          const values = response.payload?.posts?.map(
            (item: {value: any}) => item.value,
          );
          setGraphData({
            dates: formattedDates,
            values: values,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (id) console.log(id, 'creatorId');
    fetchProductAnalytics('2023-01-01', '2024-11-26');
  }, [id]);

  const hikeCardData = [
    {
      title: 'Clicks',
      image: hike,
      count: analyticsData?.headerCalculations?.headerItems[2]?.value ?? 0,
    },
    {
      title: 'Page Visits',
      image: hike,
      count: analyticsData?.headerCalculations?.headerItems[0]?.value ?? 0,
    },
    {
      title: 'Liked',
      image: hike,
      count: analyticsData?.headerCalculations?.headerItems[1]?.value ?? 0,
    },
    {
      title: 'Average Time Spent',
      image: downfall,
      count: 0,
    },
  ];

  const headings = [
    'PRODUCT NAME',
    'CATEGORY',
    'CREATOR',
    'CLICKS',
    'LIKED',
    'VISITS',
    'STATUS',
  ];
  const rows = [
    {
      productName: 'Fortnite',
      category: 'GAMES',
      creator: 'Meta',
      clicks: '23 K',
      liked: '144 K',
      visits: '3.2 K',
      status: 'active',
    },
    {
      productName: 'Netflix',
      category: 'APPS',
      creator: 'Netflix',
      clicks: '12.7 K',
      liked: '98 K',
      visits: '2.7 K',
      status: 'suspended',
    },
    {
      productName: 'Mastering UI/UX',
      category: 'COURSES',
      creator: 'Abc',
      clicks: '9.5 K',
      liked: '76 K',
      visits: '1.6 K',
      status: 'pending',
    },
    {
      productName: 'DUOLINGO',
      category: 'APPS',
      creator: '125',
      clicks: '6.2 K',
      liked: '53 K',
      visits: '968',
      status: 'active',
    },
    {
      productName: 'McCoy Law Firm',
      category: 'SERVICES',
      creator: 'Service',
      clicks: '3.8 K',
      liked: '39 K',
      visits: '784',
      status: 'pending',
    },
    {
      productName: 'McCoy Law Firm',
      category: 'SERVICES',
      creator: 'Service',
      clicks: '3.8 K',
      liked: '39 K',
      visits: '784',
      status: 'active',
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.productName}</td>
      <td className="px-6 py-4">{row.category}</td>
      <td className="px-6 py-4">{row.creator}</td>
      <td className="px-6 py-4">{row.clicks}</td>
      <td className="px-6 py-4">{row.liked}</td>
      <td className="px-6 py-4">{row.visits}</td>
      <td className="px-6 py-4">
        <Badge
          value={row.status}
          textColorEnumKey={row.status}
          bgColorEnumKey={row.status}
          rounded="full"
        />
      </td>
    </>
  );
  const data = {
    labels: graphData?.dates,
    datasets: [
      {
        label: 'Posts',
        data: graphData?.values,
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const [selectedOption, setSelectedOption] = useState('All Products');

  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <>
      <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <CurveLineGraph
              data={data}
              options={options}
              title="Posts Analytics"
            />
          </div>
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 w-full">
        <Table
          title="Products Page"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={true}
          filterOptions={[
            {label: 'All Products', value: 'All Products'},
            {label: 'Active', value: 'Active'},
            {label: 'Inactive', value: 'Inactive'},
            {label: 'Archived', value: 'Archived'},
            {label: 'Pending', value: 'Pending'},
            {label: 'Under Review', value: 'Under Review'},
          ]}
          selectedOption={selectedOption}
          searchPlaceholder="Search Product"
          onFilterChange={handleFilterChange} // Pass down the change handler
        />
      </div>
    </>
  );
};

export default CreatorProductsAnalytics;
