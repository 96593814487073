type ClassValue = string | undefined | null | {[key: string]: boolean};

function cn(...args: ClassValue[]): string {
  return args.filter(Boolean).join(' ');
}

export const checkNullOrEmpty = (value: any) => {
  return value === undefined || value === null || value === '';
};

export {cn};

export const formatDateForAPI = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
