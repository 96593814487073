import React, {FC} from 'react';

type InputProps = {
  type?: any;
  placeholder?: string;
  value: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ViewOnlyInput: FC<InputProps> = ({
  value,
  onChange,
  type = 'text',
  placeholder = '',
  disabled = false,
}) => {
  return (
    <input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className="w-full border-b border-[rgba(108,140,255,0.5)] bg-transparent py-2 text-white placeholder-white/70 focus:border-cyan-400 focus:outline-none"
      disabled={disabled}
    />
  );
};

export default ViewOnlyInput;
