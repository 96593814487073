import {TEXT_COLOR_ENUM, BG_COLOR_ENUM} from '../../constants/colors.constant';
import {checkNullOrEmpty} from '../../lib/utils';
import HTMLContentRenderer from '../common/HTMLContentRenderer';

interface BadgeProps {
  value?: string;
  textColor?: string;
  rounded?: string;
  textColorEnumKey?: string;
  bgColorEnumKey?: string;
  bgColor?: string;
  children?: React.ReactNode;
}

const Badge = ({
  value = '',
  textColor = '',
  rounded = 'md',
  textColorEnumKey,
  bgColorEnumKey,
  bgColor,
  children,
}: BadgeProps) => {
  return (
    <div
      className={`inline-block rounded-${rounded} px-3 py-0.5 text-[10px] font-medium capitalize`}
      style={{
        backgroundColor: checkNullOrEmpty(bgColor)
          ? BG_COLOR_ENUM[
              bgColorEnumKey?.toUpperCase() as keyof typeof BG_COLOR_ENUM
            ]
          : bgColor,
        color: checkNullOrEmpty(textColor)
          ? TEXT_COLOR_ENUM[
              textColorEnumKey?.toUpperCase() as keyof typeof TEXT_COLOR_ENUM
            ]
          : textColor,
      }}
    >
      {children ? children : <HTMLContentRenderer htmlContent={value ?? ''} />}
    </div>
  );
};

export default Badge;
