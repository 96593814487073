import {Route, Routes, BrowserRouter} from 'react-router-dom';

import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';

import CreatorLayout from './layouts/CreatorLayout';

import ManageCreator from './pages/ManageCreator';
import ManageUsers from './pages/ManageUsers';
import CreatorDetail from './pages/CreatorDetail';
import UserDetail from './pages/UserDetail';
import Products from './pages/Products';
import Dashboard from './pages/Dashboard';
import RejectedProducts from './pages/Products/rejectedProducts';
import ReviewAProduct from './pages/Products/reviewAProduct';
import Analytics from './pages/Analytics';
import PostScheduling from './pages/postScheduling';
import Notification from './pages/Notification';
import Settings from './pages/Settings';
import ManageAccess from './pages/ManageAccess';
import Revenue from './pages/Revenue';
import Subscription from './pages/Subscription';

import PrivateRoutes from './components/auth/PrivateRoutes';

import {AuthProvider} from './context/AuthContext';
import PublicRoutes from './components/auth/PublicRoutes';
import ActivateAccount from './pages/ActivateAccount';
import SetPassword from './pages/SetPassword';
import AuthLayout from './layouts/AuthLayout';

const Routing = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/set-password" element={<SetPassword />} />
              <Route path="/activate-account" element={<ActivateAccount />} />
            </Route>
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<CreatorLayout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/manage-creator">
                <Route index element={<ManageCreator />} />
                <Route path="creator-detail/:id" element={<CreatorDetail />} />
              </Route>

              <Route path="/manage-users">
                <Route index element={<ManageUsers />} />
                <Route path="user-detail/:id" element={<UserDetail />} />
              </Route>

              <Route path="/manage-products">
                <Route index element={<Products />} />
                <Route
                  path="rejected-products"
                  element={<RejectedProducts />}
                />
                <Route
                  path="review-a-product/:productId"
                  element={<ReviewAProduct />}
                />
                <Route
                  path="review-a-product/:creatorId/:productId"
                  element={<ReviewAProduct />}
                />
              </Route>

              <Route path="/analytics">
                <Route index element={<Analytics />} />
              </Route>

              <Route path="/post-scheduling">
                <Route index element={<PostScheduling />} />
              </Route>

              <Route path="/revenue">
                <Route index element={<Revenue />} />
              </Route>

              <Route path="/manage-access">
                <Route index element={<ManageAccess />} />
              </Route>

              <Route path="/notifications">
                <Route index element={<Notification />} />
              </Route>

              <Route path="/settings">
                <Route index element={<Settings />} />
              </Route>

              <Route path="/subscriptions">
                <Route index element={<Subscription />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routing;
