import React, {useState} from 'react';
import Table from '../../components/common/Table';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import ProductSelect from '../../components/core/Product/Dropdown/ProductSelect';
import LinearIcons from '../../components/core/SocialMedia/LinearIcons';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import hike from '../../assets/images/icons/hike.png';
import downfall from '../../assets/images/icons/downfall.png';
import active from '../../assets/images/icons/active.png';
import del from '../../assets/images/icons/del.png';
import eye from '../../assets/images/icons/eye.png';
import pending from '../../assets/images/icons/pending.png';
import productSampleImage from '../../assets/images/icons/productSelection.png';

const hikeCardData = [
  {
    title: 'Followere',
    image: hike,
    count: 1852,
  },
  {
    title: 'Impressions',
    image: hike,
    count: 17.029,
  },
  {
    title: 'Interactions',
    image: hike,
    count: 91,
  },
  {
    title: 'Reach',
    image: downfall,
    count: 155,
  },
];

const SocialAnalytics = () => {
  const headings = [
    'USER',
    'PRODUCT NAME',
    'DESCRIPTION',
    'FOLLOWERS',
    'REACH',
    'LIKES',
    'STATUS',
    'ACTION',
  ];
  const rows = [
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '68',
      reach: '235',
      likes: '5',
      status: active,
      action: {
        view: eye,
        delete: del,
      },
    },
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '68',
      reach: '324',
      likes: '23',
      status: pending,
      action: {
        view: eye,
        delete: del,
      },
    },
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '68',
      reach: '241',
      likes: '6',
      status: pending,
      action: {
        view: eye,
        delete: del,
      },
    },
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '3422',
      reach: '551',
      likes: '455',
      status: pending,
      action: {
        view: eye,
        delete: del,
      },
    },
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '34',
      reach: '32',
      likes: '23',
      status: pending,
      action: {
        view: eye,
        delete: del,
      },
    },
    {
      user: 'Sarah Johnson',
      productName: 'PetStop',
      description: 'Description here..',
      followers: '434',
      reach: '678',
      likes: '231',
      status: pending,
      action: {
        view: eye,
        delete: del,
      },
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.user}</td>
      <td className="px-6 py-4">{row.productName}</td>
      <td className="px-6 py-4">{row.description}</td>
      <td className="px-6 py-4">{row.followers}</td>
      <td className="px-6 py-4">{row.reach}</td>
      <td className="px-6 py-4">{row.likes}</td>
      <td className="px-6 py-4">
        <img src={row.status} alt="Visa Logo" className="w-14" />
      </td>
      <td className="px-6 py-4">
        <div className="flex space-x-4">
          <a href="#" className="hover:opacity-80">
            <img src={row.action.view} alt="view" className="w-8" />
          </a>
          <a href="#" className="hover:opacity-80">
            <img src={row.action.delete} alt="delete" className="w-8" />
          </a>
        </div>
      </td>
    </>
  );
  const data = {
    labels: ['Aug 8', 'Aug 18', 'Aug 28', 'Sep 8', 'Sep 18', 'Sep 28'],
    datasets: [
      {
        label: 'Deleted',
        data: [800, 900, 950, 1000, 1200, 1500],
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Shedule',
        data: [2000, 2000, 1800, 1700, 1600, 1700],
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Comments',
        data: [700, 800, 900, 850, 800, 900],
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Followers',
        data: [1000, 1000, 900, 900, 1100, 1200],
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const products = [
    {id: '1', name: 'Product 1', image: productSampleImage},
    {id: '2', name: 'Product 2', image: productSampleImage},
    {id: '3', name: 'Product 3', image: productSampleImage},
  ];

  const handleProductChange = (selectedProduct: {id: string; name: string}) => {
    console.log('Selected Product:', selectedProduct);
  };

  return (
    <>
      <div className="mb-6 flex h-full w-full items-center justify-between">
        <div className="flex h-full">
          <ProductSelect options={products} onChange={handleProductChange} />
        </div>
        <div className="flex">
          <LinearIcons />
        </div>
      </div>
      <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full flex-col">
          <CurveLineGraph data={data} options={options} title="Social Media" />
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 w-full">
        <Table
          title="Social Media"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={false}
        />
      </div>
    </>
  );
};

export default SocialAnalytics;
