import React, {useState, useRef, useEffect} from 'react';

type ChangeStatusDropdownProps = {
  options: string[];
  activeOption: string;
  width: string;
  classes?: string;
  placeholder?: string;
  onChange: (selectedOption: string) => void;
  disabled?: boolean;
};

const ChangeStatusDropdown: React.FC<ChangeStatusDropdownProps> = ({
  options,
  activeOption,
  onChange,
  width,
  classes = '',
  placeholder = '',
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative inline-block w-full`} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`flex items-center justify-between ${classes} w-${width} rounded-lg border border-indigo-400 px-4 py-2 font-semibold text-white transition duration-200`}
      >
        <span>{!activeOption ? placeholder : activeOption}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && !disabled && (
        <div className="absolute right-0 z-10 mt-0 w-full rounded-lg bg-[#1d183f] py-1 text-white">
          <ul className="divide-y-2 divide-[#252258]">
            {options.map((option, index) => (
              <li key={index}>
                <button
                  onClick={() => handleOptionClick(option)}
                  className="w-full px-4 py-2 text-left text-white hover:bg-[#8133fa]"
                >
                  {option}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChangeStatusDropdown;
