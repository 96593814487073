import {useCallback, useState} from 'react';
import {createPortal} from 'react-dom';
import {FaArrowRightLong} from 'react-icons/fa6';

import Search from '../../components/search/Search';
import Button from '../../components/ui/Button';
import Badge from '../../components/ui/Badge';
import Pagination from '../../components/common/Pagination';
import Loading from '../../components/common/Loading';
import Flyover from '../../components/common/Flyover';
import Input from '../../components/common/Input';
import SelectDropdown from '../../components/common/SelectDropdown';
import TableDropdownMenu from '../../components/common/TableDropdownMenu';

import {useGetUsers} from '../../hooks/userQueryHooks';

import placeholderImg from '../../assets/images/icons/dp.png';

const AllUsers = () => {
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showChangeRole, setShowChangeRole] = useState(false);

  const [start, setStart] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const length = 10;

  const {data: userData, isPending} = useGetUsers(length, start, search);

  const totalRecords = userData?.recordsFiltered || 0;

  const onPageChange = (newStart: number) => {
    setStart(newStart);
  };

  const handleSearch = useCallback((query: string) => {
    setSearch(query);
    setStart(0);
  }, []);

  const menuItems = (userId: string) => [
    {
      label: 'View Permissions',
      action: () => console.log(`View Permissions for user ID: ${userId}`),
    },
    {
      label: 'Change Role',
      action: () => setShowChangeRole(true),
    },
    {
      label: 'Delete Account',
      action: () => setDeleteAccount(true),
    },
  ];

  return (
    <div className="relative">
      <div className="absolute -top-16 right-0 flex items-center justify-between px-6 md-lt:flex-col md-lt:items-start md-lt:gap-4">
        <div className="flex items-center gap-4 md-lt:w-full md-lt:justify-end">
          <Search
            className="h-8 w-64 md-lt:w-36"
            placeholder="Search user"
            onSearch={handleSearch}
          />
          <Button
            label="Add Admin"
            className="rounded-lg bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:px-3 md-lt:text-sm"
            onClick={() => setShowAddAdmin(true)}
          />
        </div>
      </div>
      {/* Table */}
      <div className="md-lt:overflow-x-scroll">
        <table className="w-full table-auto border-collapse">
          <thead className="h-16 text-nowrap border-x-2 border-solid border-[#330987] bg-custom-purple-1 text-left text-sm font-semibold uppercase">
            <tr>
              {/* <td className="px-3 py-2"></td> */}
              <td className="px-3 py-2">Account</td>
              <td className="px-3 py-2">Email</td>
              <td className="px-3 py-2">Role</td>
              {/* <td className="px-3 py-2">Permissions</td> */}
              <td className="px-3 py-2">Status</td>
              <td className="px-3 py-2 text-center">Actions</td>
            </tr>
          </thead>
          {!isPending && (
            <tbody className="divide-y-2 divide-[#252258] text-nowrap border-x-2 border-b-2 border-solid border-[#252258] bg-[#1b1341] text-xs font-light">
              {userData?.users?.map((user: any) => (
                <tr key={user.userId}>
                  {/* <td className="px-3 py-2">
                  <div className="flex h-full w-full items-center justify-end md-lt:w-7">
                    <img
                      src={user.pic}
                      alt="user avatar"
                      className="h-5 w-5 rounded-full"
                    />
                  </div>
                </td> */}
                  <td className="px-3 py-2 font-semibold">{user.userName}</td>
                  <td className="px-3 py-2">{user.email}</td>
                  <td className="px-3 py-2 capitalize">{user.role}</td>
                  {/* <td className="px-3 py-2 capitalize">{user.permissions}</td> */}
                  <td className="px-3 py-2 text-end">
                    <Badge
                      value={user.status ? 'Active' : 'In Active'}
                      textColor={user.status ? '#6AC92F' : '#b2afbf'}
                      bgColor={user.status ? '#67F07D1F' : '#b2afbf33'}
                      rounded="full"
                    />
                  </td>
                  <td className="px-3 py-2 text-center">
                    <TableDropdownMenu menuItems={menuItems(user.userId)} />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {isPending ? (
          <div className="flex h-60 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          totalRecords > length && (
            <Pagination
              totalRecords={totalRecords}
              fetchedRecords={length}
              start={start}
              onPageChange={onPageChange}
            />
          )
        )}
      </div>

      {/* ADD Admin FLYOVER */}
      {createPortal(
        <Flyover isOpen={showAddAdmin} onClose={() => setShowAddAdmin(false)}>
          <div className="flex w-full flex-col gap-4 rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-white">Add Admin</h2>

            <Input
              label="Admin Name"
              placeholder="Enter Name"
              // value={'Jon Doe'}
              onChange={() => {}}
              disabled
              className="text-xs"
            />
            <Input
              label="Admin Email"
              placeholder="Enter Email"
              // value={'Jon Doe'}
              onChange={() => {}}
              disabled
              className="text-xs"
            />
            <Input
              label="Role/Permissions"
              placeholder="Enter Role"
              // value={'Jon Doe'}
              onChange={() => {}}
              disabled
              className="text-xs"
            />

            <div className="flex gap-4">
              <Button
                label="Send Invite"
                className="rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
              <Button
                label="Cancel"
                onClick={() => setShowAddAdmin(false)}
                className="rounded-full bg-[#7D3CF3] px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </Flyover>,
        document.body,
      )}

      {/* CHANGE ROLE FLYOVER */}
      {createPortal(
        <Flyover
          isOpen={showChangeRole}
          onClose={() => setShowChangeRole(false)}
        >
          <div className="flex w-full flex-col gap-4 rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-white">Change Role</h2>
            <p className="text-[0.6rem] italic">
              Changing user role changes therir API token access
            </p>
            <SelectDropdown
              label="Change Role"
              disabled
              placeholder="Select Reason"
              className="text-xs"
              value={{value: '1', label: 'Creator'}}
              options={[{value: '1', label: 'Creator'}]}
            />
            <div className="flex items-center gap-4">
              <div
                className={`h-20 w-20 overflow-hidden rounded-lg bg-gradient-to-r from-purple-500 to-teal-400 p-[1px]`}
              >
                <img
                  src={placeholderImg}
                  alt="Profile"
                  className="h-full w-full rounded-lg object-cover object-center"
                />
              </div>
              <div className="flex flex-col text-xl font-semibold text-white">
                <p>Jon Doe</p>
                <p className="flex items-center gap-2">
                  <span className="text-gray-400 line-through">Admin</span>
                  <FaArrowRightLong className="text-gray-300" /> Creator
                </p>
              </div>
            </div>
            <Input
              label="Comments"
              type="textarea"
              placeholder="Reason for change"
              // value={'Jon Doe'}
              onChange={() => {}}
              disabled
              className="resize-none rounded-lg text-xs"
              rows={3}
            />
            <div className="flex gap-4">
              <Button
                label="Submit"
                className="rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
              <Button
                label="Cancel"
                onClick={() => setShowChangeRole(false)}
                className="rounded-full bg-[#7D3CF3] px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </Flyover>,
        document.body,
      )}

      {/* DELETE ACCOUNT FLYOVER */}
      {createPortal(
        <Flyover isOpen={deleteAccount} onClose={() => setDeleteAccount(false)}>
          <div className="flex w-full flex-col gap-4 rounded-lg p-6">
            <h2 className="text-2xl font-semibold text-white">
              Delete Account
            </h2>

            <Input
              label="Comments"
              type="textarea"
              placeholder="Reason for deleting account"
              // value={'Jon Doe'}
              onChange={() => {}}
              disabled
              className="resize-none rounded-lg text-xs"
              rows={3}
            />
            <div className="flex items-center gap-3">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-purple-500"
              />
              <span className="text-xs">
                Are you sure, you want to delete account
              </span>
            </div>
            <div className="flex gap-4">
              <Button
                label="Submit"
                className="rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
              <Button
                label="Cancel"
                onClick={() => setDeleteAccount(false)}
                className="rounded-full bg-[#7D3CF3] px-6 py-1.5 text-sm hover:bg-opacity-60"
              />
            </div>
          </div>
        </Flyover>,
        document.body,
      )}
    </div>
  );
};

export default AllUsers;
