import React from 'react';

interface ToggleSwitchProps {
  label?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({label = ''}) => {
  return (
    <div className="flex items-center justify-between">
      <label>{label}</label>
      <input
        className="relative h-3 w-7 appearance-none rounded-full bg-[#3a244d] from-neutral-300 transition-colors after:absolute after:top-1/2 after:z-[2] after:h-4 after:w-4 after:-translate-y-1/2 after:rounded-full after:border-none after:bg-gradient-to-r after:from-[#8626f3] after:to-[#e086d9] after:transition-all after:duration-300 after:ease-in-out after:content-[''] checked:after:ml-3 hover:cursor-pointer lg:hover:bg-[#192d4d] lg:hover:after:rotate-90 lg:hover:after:from-[#0353ae] lg:hover:after:to-[#3dd4fa]"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckDefault01"
      />
    </div>
  );
};

export default ToggleSwitch;
