import {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from 'react';
import {useGetMe} from '../hooks/authQueryHooks';
import {useLocation, useNavigate} from 'react-router-dom';
import Loading from '../components/common/Loading';

interface User {
  userId: string;
  email: string;
  type: string;
  username: string;
  [key: string]: any;
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
}

interface AuthContextProps extends AuthState {
  login: (user: User) => void; // Adjust the user type as necessary
  logout: () => void;
}

type AuthAction =
  | {type: 'login'; payload: User} // Adjust the payload type as necessary
  | {type: 'logout'};

// Initial state
const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
};

// Reducer function
function reducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'login':
      return {...state, user: action.payload, isAuthenticated: true};
    case 'logout':
      return {...state, user: null, isAuthenticated: false};
    default:
      throw new Error('Unknown action type');
  }
}

// Create the AuthContext with a default undefined value
const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// AuthProvider component
interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const token = localStorage.getItem('authToken');
  const {mutate, isPending} = useGetMe();

  const login = (user: User) => {
    dispatch({type: 'login', payload: user});
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    dispatch({type: 'logout'});
  };

  useEffect(() => {
    if (token && !state.user) {
      mutate(undefined, {
        onSuccess: user => {
          login(user);
          // Navigate to the original route or dashboard
          const redirectPath = location.state?.from || '/';
          navigate(redirectPath, {replace: true});
        },
        onError: () => {
          // Handle token errors by logging out
          localStorage.removeItem('authToken');
          logout();
        },
      });
    }
  }, [token, mutate, navigate]);

  return (
    <AuthContext.Provider value={{...state, login, logout}}>
      {isPending ? <Loading className="h-screen w-screen" /> : children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export {AuthProvider, useAuth};
