import {useParams} from 'react-router-dom';
import TabList from '../../components/common/TabNavigation/TabList';
import TabItem from '../../components/common/TabNavigation/TabItem';
import AccountInfo from '../../components/core/AccountInfo/AccountInfo';
import UserProducts from '../../components/core/Product/UserProducts';
import {useGetUserProfile} from '../../hooks/userQueryHooks';

const UserDetail = () => {
  const {id} = useParams();

  const {data: userDetail} = useGetUserProfile(id);

  return (
    <div className="flex flex-col gap-8 text-white">
      <div className="flex items-center justify-between px-6">
        <h1 className="text-3xl">
          <span className="font-light tracking-wider">User</span> Profile
        </h1>
      </div>
      <TabList
        activeTabIndex={0}
        gap={6}
        listClasses="w-fit md-lt:w-full divide-x divide-[#4e57c9] overflow-hidden rounded-t-2xl border-x border-t border-[#4e57c9]"
        itemClasses="px-6 md-lt:w-full py-2 capitalize"
      >
        <TabItem label="Account information">
          <AccountInfo profileInfo={userDetail} type="user" />
        </TabItem>
        <TabItem label="Products">
          <UserProducts />
        </TabItem>
      </TabList>
    </div>
  );
};

export default UserDetail;
