import {useCallback, useState} from 'react';
import Search from '../../components/search/Search';
import Badge from '../../components/ui/Badge';
import {useNavigate} from 'react-router-dom';
import {useGetUsers} from '../../hooks/userQueryHooks';
import Pagination from '../../components/common/Pagination';
import Loading from '../../components/common/Loading';
import TableDropdownMenu from '../../components/common/TableDropdownMenu';

export default function ManageUsers() {
  const navigate = useNavigate();

  const [start, setStart] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const length = 10;

  const {data: userData, isPending} = useGetUsers(length, start, search);

  const totalRecords = userData?.recordsFiltered || 0;

  const onPageChange = (newStart: number) => {
    setStart(newStart);
  };

  const handleSearch = useCallback((query: string) => {
    setSearch(query);
    setStart(0);
  }, []);

  const handleUserDetail = (userId: string) => {
    navigate(`user-detail/${userId}`);
  };

  const menuItems = (userId: string) => [
    {
      label: 'View',
      action: () => handleUserDetail(userId),
    },
    {
      label: 'Edit',
      action: () => console.log(`Editing user ID: ${userId}`),
    },
    {
      label: 'Delete',
      action: () => console.log(`Deleting user ID: ${userId}`),
    },
  ];

  return (
    <div className="flex flex-col gap-8 text-white">
      <div className="flex items-center justify-between px-6 md-lt:flex-col md-lt:items-start md-lt:gap-4">
        <h1 className="text-3xl md-lt:text-2xl">
          <span className="font-light tracking-wider">List of</span> Users
        </h1>
        <div className="flex items-center gap-4 md-lt:w-full md-lt:justify-end">
          <Search
            className="h-8 w-64 md-lt:w-36"
            placeholder="Search user"
            onSearch={handleSearch}
          />
        </div>
      </div>
      {/* Table */}
      <div className="md-lt:overflow-x-scroll">
        <table className="w-full table-auto border-collapse">
          <thead className="h-16 text-nowrap border-x-2 border-solid border-[#330987] bg-custom-purple-1 text-left text-sm font-semibold uppercase">
            <tr>
              {/* <td className="px-3 py-2"></td> */}
              <td className="px-3 py-2">Account</td>
              <td className="px-3 py-2">Email</td>
              <td className="px-3 py-2">Role</td>
              {/* <td className="px-3 py-2">Permissions</td> */}
              <td className="px-3 py-2 text-center">Status</td>
              <td className="px-3 py-2 text-center">Actions</td>
            </tr>
          </thead>
          {!isPending && (
            <tbody className="divide-y-2 divide-[#252258] text-nowrap border-x-2 border-b-2 border-solid border-[#252258] bg-[#1b1341] text-xs font-light">
              {userData?.users?.map((user: any) => (
                <tr
                  key={user.userId}
                  onClick={() => handleUserDetail(user.userId)}
                  className="cursor-pointer hover:bg-[rgb(27,19,70)]"
                >
                  <td className="px-3 py-2 font-semibold">{user.userName}</td>
                  <td className="px-3 py-2">{user.email}</td>
                  <td className="px-3 py-2 capitalize">{user.role}</td>
                  <td className="px-3 py-2 text-center">
                    <Badge
                      value={user.status ? 'Active' : 'In Active'}
                      textColor={user.status ? '#6AC92F' : '#b2afbf'}
                      bgColor={user.status ? '#67F07D1F' : '#b2afbf33'}
                      rounded="full"
                    />
                  </td>
                  <td className="px-3 py-2 text-center">
                    <TableDropdownMenu
                      menuItems={menuItems(user.userId)}
                      dropdownWidth="sm"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {isPending ? (
          <div className="flex h-60 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          totalRecords > length && (
            <Pagination
              totalRecords={totalRecords}
              fetchedRecords={length}
              start={start}
              onPageChange={onPageChange}
            />
          )
        )}
      </div>
    </div>
  );
}
