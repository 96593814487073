import TabList from '../../components/common/TabNavigation/TabList';
import TabItem from '../../components/common/TabNavigation/TabItem';
import AllUsers from './AllUsers';
import ManageRoles from './ManageRoles';

const ManageAccess = () => {
  // const [userList, setUserList] = useState<any[]>([]);

  return (
    <div className="flex flex-col gap-8 text-white">
      <div className="flex items-center justify-between px-6 md-lt:flex-col md-lt:items-start md-lt:gap-4">
        <h1 className="text-3xl md-lt:text-2xl">
          <span className="font-light tracking-wider">Access</span> Management
        </h1>
      </div>
      <TabList
        activeTabIndex={0}
        gap={6}
        listClasses="w-fit md-lt:w-full divide-x divide-[#4e57c9] overflow-hidden rounded-t-2xl border-x border-t border-[#4e57c9]"
        itemClasses="px-6 md-lt:w-full py-2 capitalize"
      >
        <TabItem label="All Users">
          <AllUsers />
        </TabItem>
        <TabItem label="User Role Manage">
          <ManageRoles />
        </TabItem>
      </TabList>
    </div>
  );
};

export default ManageAccess;
