import Badge from '../../ui/Badge';

const productData = [
  {
    id: 1,
    productName: 'Fortnite',
    catagory: 'games',
    runTime: '20:00:00',
    redirect: '23k',
    liked: '144k',
    shared: '3.2k',
    status: 'pending',
  },
  {
    id: 2,
    productName: 'Fortnite',
    catagory: 'games',
    runTime: '20:00:00',
    redirect: '23k',
    liked: '144k',
    shared: '3.2k',
    status: 'active',
  },
  {
    id: 3,
    productName: 'Fortnite',
    catagory: 'games',
    runTime: '20:00:00',
    redirect: '23k',
    liked: '144k',
    shared: '3.2k',
    status: 'inactive',
  },
  {
    id: 4,
    productName: 'Fortnite',
    catagory: 'games',
    runTime: '20:00:00',
    redirect: '23k',
    liked: '144k',
    shared: '3.2k',
    status: 'active',
  },
  {
    id: 5,
    productName: 'Fortnite',
    catagory: 'games',
    runTime: '20:00:00',
    redirect: '23k',
    liked: '144k',
    shared: '3.2k',
    status: 'banned',
  },
];

const ProductsOfDay = () => {
  return (
    <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex flex-col gap-8 rounded-md bg-custom-gradient p-8 shadow-custom-shadow">
        <p className="text-2xl font-normal text-white md-lt:text-xl">
          Product of the Day Campaign
        </p>
        <div className="h-full md-lt:overflow-x-scroll">
          <table className="h-full w-full table-auto border-collapse">
            <thead className="h-12 text-nowrap border-solid bg-custom-purple-1 text-left text-sm font-semibold uppercase">
              <tr>
                <td className="px-3 py-2">Product Name</td>
                <td className="px-3 py-2">Catagory</td>
                <td className="px-3 py-2 text-center">Campaign Run Time</td>
                <td className="px-3 py-2 text-center">Redirect</td>
                <td className="px-3 py-2 text-center">Liked</td>
                <td className="px-3 py-2 text-center">Shared</td>
                <td className="px-3 py-2 text-center">Status</td>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#3c4391] border-b border-solid border-[#3c4391] bg-[#251c5c] bg-opacity-30 text-sm font-light">
              {productData.map(data => (
                <tr key={data.id}>
                  <td className="px-3 py-4">{data.productName}</td>
                  <td className="px-3 py-4 uppercase">{data.catagory}</td>
                  <td className="px-3 py-4 text-center">{data.runTime}</td>
                  <td className="px-3 py-4 text-center">{data.redirect}</td>
                  <td className="px-3 py-4 text-center">{data.liked}</td>
                  <td className="px-3 py-4 text-center">{data.shared}</td>
                  <td className="px-3 py-4 text-center">
                    <Badge
                      value={data.status}
                      textColorEnumKey={data.status}
                      bgColorEnumKey={data.status}
                      rounded="full"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductsOfDay;
