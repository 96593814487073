import React from 'react';
import {useSwipeable} from 'react-swipeable';
import {ReactComponent as TickIcon} from '../../assets/images/icons/tick_blue.svg';
import messageIcon from '../../assets/images/icons/message.svg';
import alertIcon from '../../assets/images/icons/alert.svg';

interface Notification {
  id: number;
  type: 'message' | 'alert';
  title: string;
  description?: string;
  timestamp: string;
  read: boolean;
  action?: () => void;
  handleSwipe: () => void;
  handleMarkAsRead: () => void;
  handleDelete: () => void;
}

const SwipeableNotification: React.FC<Notification> = ({
  id,
  type,
  title,
  description,
  timestamp,
  read,
  action,
  handleSwipe,
  handleMarkAsRead,
  handleDelete,
}) => {
  const swipeHandlers = useSwipeable({
    onSwipedRight: handleSwipe,
    delta: 100, // Swipe threshold in pixels
    trackMouse: true, // Enable mouse swipe tracking (for desktop)
  });

  return (
    <div
      {...swipeHandlers}
      key={id}
      style={{
        cursor: 'pointer',
        touchAction: 'none', // Disable touch interactions like scrolling or text selection
        userSelect: 'none', // Prevent text selection during swipe
      }}
      className={`rounded bg-[#FFFFFF1A] p-4 ${read ? 'opacity-75' : 'opacity-100'}`}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-2">
          <img
            className={`mt-${type === 'message' ? '[-33px]' : '[-5px]'} h-6 w-6`}
            src={type === 'message' ? messageIcon : alertIcon}
            alt={type}
          />
          <div>
            <p className="font-semibold">{title}</p>
            {description && (
              <p className="text-sm text-gray-300">{description}</p>
            )}
            <p className="mt-1 text-xs text-gray-400">{timestamp}</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            className="focus:outline-none"
            onClick={handleMarkAsRead}
            title="Mark as Read"
          >
            <TickIcon className="mt-2 h-5 w-5" />
          </button>
          <button
            className="focus:outline-none"
            onClick={handleDelete}
            title="Delete"
          ></button>
        </div>
      </div>
      {action && (
        <button
          className="ml-7 mt-4 w-[100px] rounded-lg bg-purple-600 px-4 py-2 text-white"
          onClick={action}
        >
          Open
        </button>
      )}
    </div>
  );
};

export default SwipeableNotification;
