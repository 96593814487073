import React from 'react';

const SentComents = () => {
  return (
    <div className="mx-auto max-w-3xl space-y-6 p-8 text-white">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Comments</h2>
        <button className="rounded-lg bg-purple-600 px-6 py-2 font-semibold text-white hover:bg-purple-700">
          Edit
        </button>
      </div>
      <div className="h-[75vh] overflow-auto pr-2">
        <div className="space-y-6">
          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Product Name & Logo
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="Comment from that section will be added here, user can update the comments from here too"
            ></textarea>
          </div>

          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Product Category & Sub Category
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="Comment from that section will be added here, user can update the comments from here too"
            ></textarea>
          </div>

          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Product Info
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="Comment from that section will be added here, user can update the comments from here too"
            ></textarea>
          </div>

          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Product Promotion
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="Comment from that section will be added here, user can update the comments from here too"
            ></textarea>
          </div>
          <div>
            <h3 className="border-t border-t-[#5B97FF] pt-[15px] text-[20px] font-medium not-italic text-gray-300">
              Additional Remarks
            </h3>
          </div>

          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Comments
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="User will add collectively what is wrong and what to send back"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="flex space-x-4">
        <button className="relative rounded-[1.5rem] p-[1px]">
          <div
            className="flex w-[170px] items-center justify-center rounded-[1.5rem] px-8 py-2 font-bold text-white"
            style={{
              background:
                'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
            }}
          >
            Send Revision
          </div>
        </button>

        <button className="w-[100px] rounded-full bg-[#7D3CF3] px-6 py-2 font-bold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default SentComents;
