import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../../context/AuthContext';

const PublicRoutes = () => {
  const token = localStorage.getItem('authToken');
  const {isAuthenticated} = useAuth();

  if (isAuthenticated && !!token) return <Navigate to="/" replace />;

  return <Outlet />;
};

export default PublicRoutes;
