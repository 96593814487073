import React, {FC} from 'react';

type SelectProps = {
  label?: string;
  value?: string;
  options: {label: string; value: string}[];
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const UserSelect: FC<SelectProps> = ({
  label = '',
  value = '',
  options = [],
  onChange,
  disabled = false,
}) => {
  return (
    <div className="relative">
      <label className="mb-2 block text-sm font-medium text-white">
        {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        className="w-full appearance-none rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 pr-10 text-sm text-white focus:outline-none"
        disabled={disabled}
      >
        <option
          value=""
          className="border border-[#3c3e5c]"
          disabled
          style={{backgroundColor: '#2E246C', color: '#FFFFFF'}}
        >
          Select an option
        </option>
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
            className="border border-[#3c3e5c]"
            style={{backgroundColor: '#2E246C', color: '#FFFFFF'}}
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute right-4 top-[70%] -translate-y-1/2 transform">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
};

export default UserSelect;
