import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  productDetailView: false,
  enableFlyout: false,
  sidebarOpen: false,
  productConfigForFlyout: {},
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setProductDetailView: (state, action) => {
      state.productDetailView = action.payload;
    },
    setFlyout: (state, action) => {
      state.enableFlyout = action.payload;
    },
    setProductConfigForFlyout: (state, action) => {
      state.productConfigForFlyout = action.payload;
    },
    setSidebarOpen: state => {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },
});

export const {
  setProductDetailView,
  setFlyout,
  setProductConfigForFlyout,
  setSidebarOpen,
} = generalSlice.actions;

export default generalSlice.reducer;
