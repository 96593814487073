export const PRIVACY_POLICY = [
  {
    id: 1,
    title: 'Account Creation',
    description:
      'To access certain features of our services, users must create an account. You agree to provide accurate and complete information and to update your account details as necessary. You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities under your account.',
  },
  {
    id: 2,
    title: 'Account Security',
    description:
      'Your account security is crucial. You must protect your login information and notify us immediately of any unauthorized access or suspicious activity. We are not liable for any losses resulting from unauthorized use of your account.',
  },
  {
    id: 3,
    title: 'Data and Privacy',
    description:
      'We prioritize your privacy and adhere to strict data protection regulations. Our Privacy Policy outlines how we collect, use, and store your data. By using our services, you consent to the practices described in our Privacy Policy.',
  },
  {
    id: 4,
    title: 'User Conduct',
    description:
      'Users agree to use our services in a lawful manner and not to engage in any behavior that could harm or disrupt the platform, other users, or third parties. Violations may result in suspension or termination of access.',
  },
  {
    id: 5,
    title: 'Intellectual Property',
    description:
      'All content, logos, and materials on our platform are the intellectual property of [Your Company Name] or our licensors. Users are prohibited from using, reproducing, or distributing this content without explicit permission.',
  },
  {
    id: 6,
    title: 'Limitation of Liability',
    description:
      '[Your Company Name] is not responsible for any indirect, incidental, or consequential damages arising from the use of our services. Our liability is limited to the maximum extent permitted by law.',
  },
  {
    id: 7,
    title: 'Termination of Services',
    description:
      'We reserve the right to suspend or terminate user accounts at our discretion, particularly for violations of these terms. Users may also terminate their accounts by contacting support.',
  },
  {
    id: 8,
    title: 'Refund Policy',
    description:
      'Refunds are available under specific conditions and must be requested within [specified timeframe]. Refer to our detailed refund policy for more information.',
  },
  {
    id: 9,
    title: 'Governing Law',
    description:
      'These terms are governed by the laws of [Your Jurisdiction], and any disputes will be resolved under the local court’s jurisdiction.',
  },
  {
    id: 10,
    title: 'Changes to Terms',
    description:
      'We may modify these terms at any time. Continued use of our services after updates indicates your acceptance of the new terms.',
  },
  {
    id: 11,
    title: 'Contact Us',
    description:
      'For questions regarding these terms, please contact [Your Company Name] at [Contact Information].',
  },
];
