import React, {useState, useEffect} from 'react';

interface CircularProgressProps {
  percent: number; // Percentage of progress
}

const CircularProgress: React.FC<CircularProgressProps> = ({percent}) => {
  const radius = 80;
  const [circumference, setCircumference] = useState(2 * Math.PI * radius);

  useEffect(() => {
    setCircumference(2 * Math.PI * radius); // Calculate circumference only once
  }, []);

  const strokeDashoffset = circumference - (percent / 100) * circumference;

  return (
    <div className="relative flex items-center justify-center">
      <svg className="h-52 w-52 -rotate-90 transform">
        <circle
          cx="104"
          cy="104"
          r={radius}
          stroke="currentColor"
          strokeWidth="18"
          fill="transparent"
          className="text-gray-700"
        />
        <circle
          cx="104"
          cy="104"
          r={radius}
          stroke="currentColor"
          strokeWidth="18"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          className="text-[rgba(7,90,239,255)]"
        />
      </svg>
      <span className="absolute text-4xl">{`${percent}%`}</span>
    </div>
  );
};

export default CircularProgress;
