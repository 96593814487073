// Footer.tsx
import {useState} from 'react';
import hike from '../../assets/images/icons/hike.png';
import downfall from '../../assets/images/icons/downfall.png';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import Button from '../../components/ui/Button';
import Table from '../../components/common/Table';
import Flyover from '../../components/common/Flyover';
import closeIcon from '../../assets/images/icons/closeIcon.png';
import Popover from '../../components/common/Popover';

const hikeCardData = [
  {title: 'Total', image: hike, count: 1852},
  {title: 'Survey', image: hike, count: 17.029},
  {title: 'Security', image: hike, count: 91},
  {title: 'Updates', image: downfall, count: 155},
];

const headings = ['ENTITY', 'RECIPIENTS', 'TYPE', 'TIME', 'MEDIUM', 'Actions'];
const rows = [
  {
    entity: 'FDB_03_Genaral survey about feedback',
    recipients: 'All Users ',
    type: 'Feedback form',
    time: '10:30AM 10-2-24',
    medium: 'Email, In app',
    actions: '',
  },
  {
    entity: 'Alert_04_What’s New ',
    recipients: 'All Users ',
    type: 'Update Alert',
    time: '10:30AM 10-2-24',
    medium: 'Email, In app',
    actions: '',
  },
  {
    entity: 'Alert_04_What’s New ',
    recipients: 'All Users ',
    type: 'Feedback form',
    time: '10:30AM 10-2-24',
    medium: 'Email, In app',
    actions: '',
  },
  {
    entity: 'DUAlert_04_What’s New OLINGO',
    recipients: 'All Users ',
    type: 'Update Alert',
    time: '10:30AM 10-2-24',
    medium: 'Email, In app',
    actions: '',
  },
  {
    entity: 'Alert_04_What’s New ',
    recipients: 'All Users ',
    type: 'Feedback form',
    time: '10:30AM 10-2-24',
    medium: 'Email, In app',
    actions: '',
  },
];

const CustomNotifiation = () => {
  const [newNotification, setNewNotification] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Feedback Form');

  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.entity}</td>
      <td className="px-6 py-4">{row.recipients}</td>
      <td className="px-6 py-4">{row.type}</td>
      <td className="px-6 py-4">{row.time}</td>
      <td className="px-6 py-4">{row.medium}</td>
      <td className="px-6 py-4">
        <Popover
          button={
            <Button
              className="rounded-full bg-transparent p-1 hover:bg-[#00000022] active:bg-[#00000044]"
              type="custom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  stroke="#fff"
                  fill="#fff"
                />
              </svg>
            </Button>
          }
          content={
            <div className="z-10 w-40 rounded-xl bg-[#18142D] text-white shadow-xl backdrop-blur-sm">
              <button className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]">
                Duplicate
              </button>
              <hr className="border-t border-gray-600" />
              <button className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]">
                Move to trash
              </button>
            </div>
          }
          position="bottom-end"
        />
      </td>
    </>
  );

  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-3xl">
          <span className="font-light tracking-wider">Custom</span> Notification
        </h1>
        <Button
          onClick={() => setNewNotification(true)}
          label="+ Add New"
          className="2-[70px] rounded-lg bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:px-3 md-lt:text-sm"
        />
      </div>

      <div className="relative mt-10 grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 w-full">
        <Table
          title="Summery"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={true}
          filterOptions={[
            {label: 'Feedback Form', value: 'Feedback Form'},
            {label: 'Update Alert', value: 'Update Alert'},
          ]}
          selectedOption={selectedOption}
          searchPlaceholder="Search Name"
          onFilterChange={value => setSelectedOption(value)}
        />
      </div>

      <Flyover
        isOpen={newNotification}
        onClose={() => setNewNotification(false)}
      >
        <div className="flex items-center justify-center p-4">
          <div className="w-full max-w-xl rounded-lg">
            <h1 className="mb-6 text-2xl font-semibold text-white">
              New Notification
            </h1>
            <div className="h-[600px] overflow-y-auto">
              <form className="pr-4">
                <div className="mb-4">
                  <label className="mb-2 block text-sm font-medium text-white">
                    Choose type
                  </label>
                  <input
                    type="text"
                    placeholder="Survey"
                    className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                  />
                </div>

                <div className="mb-4">
                  <label className="mb-2 block text-sm font-medium text-white">
                    Description
                  </label>
                  <textarea
                    placeholder="Sent text to user"
                    className="h-[100px] w-full rounded-md border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                  ></textarea>
                </div>

                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-purple-500"
                    />
                    <span className="ml-2 text-white">Add CTA</span>
                  </label>
                </div>

                <div className="mb-4 flex gap-4">
                  <div className="w-1/2">
                    <label className="mb-2 block text-sm font-medium text-white">
                      Date
                    </label>
                    <input
                      type="text"
                      placeholder="Survey"
                      className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="mb-2 block text-sm font-medium text-white">
                      Time
                    </label>
                    <input
                      type="text"
                      placeholder="Time"
                      className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                    />
                  </div>
                </div>

                <div className="mb-4 flex gap-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-purple-500"
                    />
                    <span className="ml-2 text-white">Email</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox text-purple-500"
                    />
                    <span className="ml-2 text-white">In-app</span>
                  </label>
                </div>

                <div className="mb-4">
                  <label className="mb-2 block text-sm font-medium text-white">
                    Sent to
                  </label>
                  <div className="mb-4 flex gap-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-purple-500"
                      />
                      <span className="ml-2 text-white">All User</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-purple-500"
                      />
                      <span className="ml-2 text-white">All Creator</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-purple-500"
                        defaultChecked
                      />
                      <span className="ml-2 text-white">Individual</span>
                    </label>
                  </div>
                </div>

                <div className="mb-4 mt-4">
                  <label className="mb-2 block text-sm font-medium text-white">
                    Search Individual
                  </label>
                  <input
                    type="text"
                    placeholder="Search User or creator.."
                    className="w-full rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
                  />
                </div>

                <label className="mb-4 block text-sm font-medium text-white">
                  Recipients
                </label>

                <div className="mb-6 flex flex-wrap gap-4">
                  <div className="flex items-center rounded-full bg-[#492397] px-4 py-[2px] text-[##BE9FF6] shadow-md">
                    <span className="font-small flex text-xs">
                      User 1
                      <img
                        alt="close"
                        src={closeIcon}
                        className="ml-1 w-[16px] brightness-200 grayscale"
                      />{' '}
                    </span>
                  </div>
                  <div className="flex items-center rounded-full bg-[#492397] px-4 py-[2px] text-[##BE9FF6] shadow-md">
                    <span className="font-small flex text-xs">
                      User 2
                      <img
                        alt="close"
                        src={closeIcon}
                        className="ml-1 w-[16px] brightness-200 grayscale"
                      />{' '}
                    </span>
                  </div>
                  <div className="flex items-center rounded-full bg-[#492397] px-4 py-[2px] text-[##BE9FF6] shadow-md">
                    <span className="font-small flex text-xs">
                      User 3
                      <img
                        alt="close"
                        src={closeIcon}
                        className="ml-1 w-[16px] brightness-200 grayscale"
                      />{' '}
                    </span>
                  </div>
                  <div className="flex items-center rounded-full bg-[#492397] px-4 py-[2px] text-[##BE9FF6] shadow-md">
                    <span className="font-small flex text-xs">
                      User 4
                      <img
                        alt="close"
                        src={closeIcon}
                        className="ml-1 w-[16px] brightness-200 grayscale"
                      />{' '}
                    </span>
                  </div>
                </div>

                <div className="mt-4 flex space-x-4">
                  <button className="relative rounded-[1.5rem] p-[1px]">
                    <div
                      className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                      style={{
                        background:
                          'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                      }}
                    >
                      Sent
                    </div>
                  </button>

                  <button className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Flyover>
    </>
  );
};

export default CustomNotifiation;
