import {useSelector, useDispatch} from 'react-redux';
import { useReducer, useEffect, useState } from 'react';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import TopTrending from '../../components/core/TopTrending/TopTrending';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import AddTopProduct from '../../components/core/Product/AddTopProduct';
import Flyover from '../../components/common/Flyover';
import product1 from '../../assets/images/icons/product1.png';
import product2 from '../../assets/images/icons/product2.png';
import product3 from '../../assets/images/icons/product3.png';
import product4 from '../../assets/images/icons/product4.png';
import prod1a from '../../assets/images/icons/products/topRatedProducts/product1.png';
import prod2a from '../../assets/images/icons/products/topRatedProducts/product2.png';
import prod3a from '../../assets/images/icons/products/topRatedProducts/product3.png';
import prod4a from '../../assets/images/icons/products/topRatedProducts/product4.png';
import star from '../../assets/images/icons/star.png';
import heart from '../../assets/images/icons/heart.png';
import share from '../../assets/images/icons/share.png';
import {setFlyout} from '../../store/General/General';
import {
  useGetNewProducts,
  useGetTrendingProducts,
  useProductsOfDay,
} from '../../hooks/productQueryHooks';

interface State {
  productsOfDayHistory: any | null;
}

const initialState: State = {
  productsOfDayHistory: null,
};

type Action =
  | { type: 'SET_PRODUCTS_OF_DAY_DATA'; payload: any }
  | { type: 'SET_GRAPH_DATA_VALUES'; payload: any }
  | { type: 'SET_GRAPH_DATA_DATES'; payload: any };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_PRODUCTS_OF_DAY_DATA':
      return { ...state, productsOfDayHistory: action.payload };
    default:
      return state;
  }
}

const hikeCardData = [
  {
    title: 'Total Posted',
    image: product1,
    count: 1852,
  },
  {
    title: 'Scheduled',
    image: product2,
    count: 17.029,
  },
  {
    title: 'Reviewed',
    image: product3,
    count: 91,
  },
  {
    title: 'Deleted',
    image: product4,
    count: 155,
  },
];

const data = {
  labels: ['Aug 8', 'Aug 18', 'Aug 28', 'Sep 8', 'Sep 18', 'Sep 28'],
  datasets: [
    {
      label: 'Active',
      data: [800, 900, 950, 1000, 1200, 1500],
      borderColor: '#ff0000',
      backgroundColor: '#ff0000',
      pointRadius: 0,
      tension: 0,
    },
    {
      label: 'Under Rivew',
      data: [2000, 2000, 1800, 1700, 1600, 1700],
      borderColor: '#0000ff',
      backgroundColor: '#0000ff',
      pointRadius: 0,
      tension: 0,
    },
    {
      label: 'Rejected',
      data: [700, 800, 900, 850, 800, 900],
      borderColor: '#ffa500',
      backgroundColor: '#ffa500',
      pointRadius: 0,
      tension: 0,
    },
    {
      label: 'Total Products',
      data: [1000, 1000, 900, 900, 1100, 1200],
      borderColor: '#ffffff',
      backgroundColor: '#ffffff',
      pointRadius: 0,
      tension: 0,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: '#ffffff',
      },
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        color: '#ffffff',
      },
      grid: {
        color: 'rgba(255, 255, 255, 0.2)',
      },
    },
  },
};

const selectTopRatedProducts = [
  {value: 'week', label: 'This Week'},
  {value: 'month', label: 'This Month'},
  {value: 'year', label: 'This Year'},
];

const products = [
  {
    name: 'Architecture 101',
    category: 'Games, Action',
    rating: 4.9,
    image: prod1a,
  },
  {
    name: 'Merry Maids',
    category: 'Games, Action',
    rating: 4.9,
    image: prod2a,
  },
  {
    name: 'Servepo',
    category: 'Games, Action',
    rating: 4.9,
    image: prod3a,
  },
  {
    name: 'Fortnite',
    category: 'Games, Action',
    rating: 4.9,
    image: prod4a,
  },
];

const OverView = () => {
  const dispatchFlyOut = useDispatch();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { productsOfDayHistory } = state;
  
  // const [activeProductType, setActiveProductType] = useState({});
  // const [topRatedProducts, setTopRatedProducts] = useState([]);
  // const [newlyAddedProducts, setNewlyAddedProducts] = useState([]);

  const {data: topRatedProducts} = useGetTrendingProducts();

  const fetchProductOfDay = useProductsOfDay();

  const {data: newlyAddedProducts} = useGetNewProducts();

  useEffect(() => {
    getProductsOfDay();
  }, []);

  const getProductsOfDay = () => {
    fetchProductOfDay.mutate(undefined, {
      onSuccess: (response) => {
        console.log(response, 'response.payload')
        dispatch({ type: 'SET_PRODUCTS_OF_DAY_DATA', payload: response });
      },
    });
  }

  const handleAddProduct = () => {
    console.log('Add Product clicked');
  };

  const handleCancel = () => {
    dispatchFlyOut(setFlyout(false));
  };

  const enableFlyout = useSelector((state: any) => state.general.enableFlyout);
  const selectedProductType = useSelector(
    (state: any) => state.general.productConfigForFlyout,
  );

  return (
    <>
      <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full flex-col">
          <CurveLineGraph data={data} options={options} title="Social Media" />
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] h-[550px]">
          <div
            className={`relative rounded-md bg-custom-gradient p-4 shadow-custom-shadow h-[548px]`}
          >
            <TopTrending
              title="Top Rated Products"
              selectOptions={selectTopRatedProducts}
              products={topRatedProducts?.products.slice(0, 5)}
              buttonText="Add Product to Top Rated"
              onAddProduct={(e:any) => {console.log(e, 'test')}}
              type="top_rated_products"
              ratingImage={star}
            />
          </div>
        </div>
        <div>
          <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] h-[550px]">
            <div
              className={`relative rounded-md bg-custom-gradient p-4 shadow-custom-shadow h-[548px]`}
            >
              <TopTrending
                title="Products of the day"
                selectOptions={selectTopRatedProducts}
                products={productsOfDayHistory?.products.slice(0, 5)}
                buttonText="Add Product Top Recomended"
                ratingImage={heart}
                onAddProduct={(e:any) => {console.log(e, 'test2')}}
                type="products_of_day"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px] h-[550px]">
            <div
              className={`relative rounded-md bg-custom-gradient p-4 shadow-custom-shadow h-[548px]`}
            >
              <TopTrending
                title="Newly added product"
                selectOptions={selectTopRatedProducts}
                products={newlyAddedProducts?.products.slice(0, 5)}
                buttonText="Add Product to Top Liked"
                onAddProduct={(e:any) => {console.log(e, 'test3')}}
                type="newly_added_products"
                ratingImage={share}
              />
            </div>
          </div>
        </div>
      </div>

      <Flyover isOpen={enableFlyout} onClose={() => dispatchFlyOut(setFlyout(false))}>
        <AddTopProduct
          title={`Add ${selectedProductType.title}`}
          onAddProduct={handleAddProduct}
          onCancel={handleCancel}
        />
      </Flyover>
    </>
  );
};

export default OverView;
