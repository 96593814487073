import React, {useEffect} from 'react';
import ProductDetail from '../../components/core/Product/ProductDetail';

const CreatorDetail = () => {
  return (
    <>
      <div className="mb-4 flex flex-col gap-8 text-white">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl">
            <span className="font-light tracking-wider">Review</span> Products
          </h1>
        </div>
      </div>
      <ProductDetail />
    </>
  );
};

export default CreatorDetail;
