import ytbutton from '../../../assets/images/icons/ytbutton.png';
import {useDispatch, useSelector} from 'react-redux';
import {setProductDetailView} from '../../../store/General/General';
import eye from '../../../assets/images/icons/eye-with-no-bg.png';
import prd1 from '../../../assets/images/icons/products/inactiveproduct1.png';
import prd2 from '../../../assets/images/icons/products/inactiveproduct2.png';
import prd3 from '../../../assets/images/icons/products/inactiveproduct3.png';
import prd4 from '../../../assets/images/icons/products/inactiveproduct4.png';

import {useNavigate, useLocation} from 'react-router-dom';
type Product = {
  id: string;
  name: string;
  category: string;
  subCategory: string;
  description: string;
  bannerImage: string;
};

type ActiveProductsProps = {
  products: Product[];
  totalRecords: number;
  fetchedRecords: number;
  start: number;
  onPageChange: (newStart: number) => void;
};

const ProductDetail: React.FC<ActiveProductsProps> = ({
  products = [],
  totalRecords = 0,
  fetchedRecords = 0,
  start = 0,
  onPageChange,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const containsCreatorDetail = location.pathname.includes('creator-detail');
  const openDetail = useSelector(
    (state: any) => state.general.productDetailView,
  );

  const getProductIcon = (index: number) => {
    if (index === 0) return prd1;
    else if (index === 1) return prd2;
    else if (index === 2) return prd3;
    else if (index === 3) return prd4;
    else if (index === 4) return prd3;
  };

  return (
    <>
      {products.map((item: any, index: number) => (
        <div
          key={index}
          className="mb-4 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]"
        >
          <div className="relative flex items-center justify-between rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
            <div className="mr-6 flex w-[20%] items-start space-x-4">
              <img
                src={item.bannerImage}
                alt="Product"
                className="h-[70px] w-[70px] rounded-md grayscale"
              />
              <div>
                <p className="text-lg font-semibold text-white">
                  {item.name.length > 14
                    ? item.name.slice(0, 14) + '...'
                    : item.name}
                </p>
                <p className="text-sm text-gray-400">{item.category}</p>
                <p className="text-[9px] text-gray-400">{item.subCategory}</p>
              </div>
            </div>

            <div className="ml-6 w-[40%] flex-grow">
              <p className="text-sm font-medium text-gray-300">
                Product Description
              </p>
              <p className="mt-1 text-xs leading-tight text-gray-400">
                {item.description.length > 90
                  ? item.description.slice(0, 90) + '...'
                  : item.description}
              </p>
            </div>

            <div className="ml-6 flex w-[35%] items-center justify-end space-x-6">
              <span className="rounded-full bg-[#3f3a19] px-3 py-1 text-xs text-yellow-400">
                Submitted for approval
              </span>

              <img
                src={eye}
                alt="Detail"
                onClick={() =>
                  containsCreatorDetail
                    ? dispatch(setProductDetailView(true))
                    : navigate('/manage-products/review-a-product/1')
                }
                className="w-[30px]"
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductDetail;
