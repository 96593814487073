import {useNavigate} from 'react-router-dom';

import Input from '../../components/common/InputNew';
import Button from '../../components/ui/Button';

import logo from '../../assets/images/logo/lusso.png';

import {useForm} from 'react-hook-form';
import {z, ZodType} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
// import {toast} from 'react-toastify';

interface FormData {
  confirmPassword: string;
  password: string;
}

const SetPasswordSchema: ZodType<FormData> = z
  .object({
    password: z
      .string()
      .min(1, {message: 'Password is required'}) // Ensures non-empty password
      .min(8, {message: 'Password must be at least 8 characters long'}), // Ensures password has a minimum length

    confirmPassword: z
      .string()
      .min(1, {message: 'Password is required'}) // Ensures non-empty password
      .min(8, {message: 'Password must be at least 8 characters long'}), // Ensures password has a minimum length
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords do not match', // Error message if passwords do not match
    path: ['confirmPassword'], // Path to highlight the error on the confirmation field
  });

const SetPassword = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
    // setError,
  } = useForm<FormData>({
    resolver: zodResolver(SetPasswordSchema),
  });

  const onSubmit = async (data: FormData) => {
    // const req = {body: data, token: ''};
    // mutate(req, {
    //   onSuccess: () => {
    //     toast.success('Password changed successfully', {
    //       position: 'top-right',
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //     navigate('/login');
    //   },
    //   onError: () => {
    //     toast.error('Failed to change password', {
    //       position: 'top-right',
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //     });
    //   },
    // });
    navigate('/login');
  };

  return (
    <div className="mx-auto flex w-2/6 flex-col gap-8 text-white">
      <img src={logo} alt="" className="w-2/3 self-center" />
      <div>
        <h4 className="text-center text-xl font-medium tracking-wide">
          Set Your Password
        </h4>
        <p className="mb-6 text-center text-sm font-light text-white/70">
          Create a secure password to access your account.
        </p>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Enter new password"
            // disabled
            type="password"
            className="text-xs"
            name="password"
            register={register}
            error={errors.password}
            required
          />
          <Input
            placeholder="Re-enter new password"
            // disabled
            type="password"
            className="text-xs"
            name="confirmPassword"
            register={register}
            error={errors.confirmPassword}
            required
          />
          <Button
            label={'Set Password'}
            className="relative w-full rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
