import React, {useState} from 'react';

import DropDownFilter from '../../common/DropDownFilter';
import ProfileCard from './ProfileCard';
import Button from '../../ui/Button';
import UserActivity from './UserActivity';
import CreatorForms from './CreatorForms';
import UserForms from './UserForms';

const statusOptions = ['Active', 'Inactive', 'Pending', 'Archived'];
const suspendProfileOptions = ['Yes', 'No'];

interface AccountInfoProps {
  type?: string;
  profileInfo?: any;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  type = 'creator',
  profileInfo,
}) => {
  // console.log(profileInfo, 'profileInfo')
  const [activeStatus, setActiveStatus] = useState('');
  const [suspendProfile, setSuspendProfile] = useState('');

  const handleStatusChange = (selectedOption: string) => {
    setActiveStatus(selectedOption);
  };

  const handleSuspendProfile = (selectedOption: string) => {
    setSuspendProfile(selectedOption);
  };

  return (
    <div
      id="account-information"
      className="relative grid w-full grid-cols-3 gap-8 py-4 md-lt:grid-cols-1"
    >
      <div className="col-span-1 flex flex-col gap-8">
        <ProfileCard
          showSocials={type.toLowerCase() === 'user' ? false : true}
          name={profileInfo?.fullName || profileInfo?.username}
        />

        <UserActivity />

        {type.toLowerCase() !== 'user' && (
          <DropDownFilter
            width="full"
            classes=""
            placeholder="Change Status"
            options={statusOptions}
            activeOption={activeStatus}
            onChange={handleStatusChange}
          />
        )}

        <DropDownFilter
          width="full"
          classes=""
          placeholder="Suspend Profile"
          options={suspendProfileOptions}
          activeOption={suspendProfile}
          onChange={handleSuspendProfile}
        />
      </div>
      <Button
        label="Edit"
        className="absolute -top-16 right-0 w-32 rounded-lg bg-[#472297] px-6 py-2 hover:bg-opacity-60 md-lt:relative md-lt:top-0"
      />
      <div className="col-span-2 md-lt:col-span-1">
        {type.toLowerCase() === 'creator' && (
          <CreatorForms profileInfo={profileInfo} />
        )}
        {type.toLowerCase() === 'user' && (
          <UserForms userDetails={profileInfo} />
        )}
      </div>
    </div>
  );
};

export default AccountInfo;
