import {Outlet} from 'react-router-dom';
import bg from '../assets/images/misc/SigninBG.png';

const AuthLayout = () => {
  return (
    <div
      className="grid h-screen w-screen grid-cols-12 bg-cover"
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="col-span-5 flex flex-col justify-end pb-16 pl-10 text-3xl uppercase leading-tight tracking-[0.2em] text-white">
        <h1>
          Exploring
          <br /> the world of
        </h1>
        <h1 className="text-5xl font-semibold leading-tight tracking-[0.25em]">
          <span className="font-bold text-[#0FF]">Lusso</span>
          <br /> Universe
        </h1>
      </div>
      <div className="col-span-7 grid items-center bg-[#1b184588]">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
