import React from 'react';
import Select, {SingleValue} from 'react-select';
import {cn} from '../../lib/utils';
import {checkNullOrEmpty} from '../../lib/utils';

interface OptionType {
  value: string;
  label: string;
}

interface InputProps {
  label?: string;
  value?: SingleValue<OptionType>;
  options: OptionType[];
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  error?: boolean;
  className?: string;
  append?: any;
  errorMessage?: string;
  dropdownSubTitle?: string;
  onChange?: (newValue: SingleValue<OptionType>, actionMeta: any) => void;
}

const SelectDropdown: React.FC<InputProps> = ({
  label = '',
  value = null,
  options = [],
  placeholder = 'Select...',
  required = false,
  disabled = false,
  readOnly = false,
  className = '',
  append = {},
  onChange = () => console.log('...clicked'),
  error = false,
  dropdownSubTitle = '',
  errorMessage = '',
}) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      borderRadius:
        append?.append?.toLowerCase() === 'end'
          ? '16px 0px 0px 16px'
          : append?.append?.toLowerCase() === 'start'
            ? '0px 16px 16px 0px'
            : 50,
      border: `1px solid ${error ? '#F04438' : '#3c3e5c'}`, // Conditional border color
      backgroundColor: '#2E246C33', // Updated background color
      //   color: '#FFFFFF99',
      // Ensure the height matches the input field
      paddingLeft: 8, // Ensure padding matches the input field
      // display: 'flex',
      // justifyContent: "start",
      // alignItems: 'start', // Ensure the text is aligned similarly
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: disabled ? '#9ca3af' : '#fff',
      //   paddingLeft: 15, // Fixed typo from padddingLeft to paddingLeft
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: disabled ? '#9ca3af' : '#FFFFFF99',
      //   paddingLeft: 15, // Fixed typo from padddingLeft to paddingLeft
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'rgba(4, 4, 4)',
      borderRadius: 10,
      overflow: 'hidden',
      padding: 0, // Remove any extra padding if needed
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0, // Remove any extra padding if needed
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isFocused ? '#8133fa' : '#1d183f',
      color: '#FFFFFF99',
      // padding: '8px 12px', // Adjust padding if needed
    }),
    indicatorSeparator: () => ({
      display: 'none', // Hide the indicator separator
    }),
  };

  return (
    <div>
      <label className={`grid grid-cols-1 md:grid-cols-${label ? '2' : '1'}`}>
        <span className="hidden flex-col justify-center text-xs font-light text-white md:flex">
          <span>
            {label ?? ''}
            {label && required && (
              <span className="ms-1 text-[#F04438]">*</span>
            )}
          </span>
          {!checkNullOrEmpty(dropdownSubTitle) && (
            <span className="text-sm" style={{color: '#FFFFFF99'}}>
              {' '}
              {dropdownSubTitle ?? ''}
            </span>
          )}
        </span>
        {append && Object.keys(append)?.length !== 0 ? (
          <label className="mt-2 flex h-[50px] items-center">
            {append?.append?.toLowerCase() !== 'end' && (
              <span
                className={cn(
                  'flex h-full items-center justify-center rounded-l-2xl px-4 text-[#FFFFFF99]',
                  append?.className,
                )}
                style={{
                  border: `1px solid ${error ? '#F04438' : '#3c3e5c'}`,
                }}
              >
                {append?.type?.toLowerCase() === 'text' ? (
                  (append?.text ?? '')
                ) : (
                  <img
                    src={append?.icon ?? ''}
                    alt={'dropdown-icon'}
                    className="h-6 w-8"
                  />
                )}
              </span>
            )}
            <Select
              placeholder={placeholder}
              className={`flex-1 ${className}`}
              value={value}
              styles={customStyles}
              options={options}
              onChange={onChange}
              isDisabled={disabled}

              // readOnly={readOnly}
            />
            {append?.append?.toLowerCase() === 'end' && (
              <span
                className={cn(
                  'flex h-full items-center justify-center rounded-r-2xl px-4 text-[#FFFFFF99]',
                  append?.className,
                )}
                style={{
                  border: `1px solid ${error ? '#F04438' : '#3c3e5c'}`,
                }}
              >
                {append?.type?.toLowerCase() === 'text' ? (
                  (append?.text ?? '')
                ) : (
                  <img
                    src={append?.icon ?? ''}
                    alt={'dropdown-icon'}
                    className="h-4 w-6"
                  />
                )}
              </span>
            )}
          </label>
        ) : (
          <Select
            placeholder={placeholder}
            className={`mt-2 w-full flex-1 ${disabled && 'text-gray-400'} ${className}`}
            value={value}
            styles={customStyles}
            options={options}
            onChange={onChange}
            isDisabled={disabled}

            // readOnly={readOnly}
          />
        )}
      </label>
      {error && (
        <div className="errorField text-right">{errorMessage ?? ''}</div>
      )}
    </div>
  );
};

export default SelectDropdown;
