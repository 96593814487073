import {useQuery, useMutation} from '@tanstack/react-query';
import makeApiCall from '../lib/apiCall';
import {apiEndpoints} from '../constants/api-endpoints';

export const useGetTrendingProducts = () => {
  return useQuery({
    queryKey: ['productsTrending'],
    queryFn: async () => {
      const response = await makeApiCall(apiEndpoints.GetTrendingProducts);
      return response;
    },
    // placeholderData: keepPreviousData,
  });
};

export const useGetRecommendedProducts = () => {
  return useQuery({
    queryKey: ['productsRecommended'],
    queryFn: async () => {
      const response = await makeApiCall(apiEndpoints.GetRecommendedProducts);
      return response;
    },
    // placeholderData: keepPreviousData,
  });
};

export const useGetNewProducts = () => {
  return useQuery({
    queryKey: ['productsNew'],
    queryFn: async () => {
      const response = await makeApiCall(apiEndpoints.GetProductsNew);
      return response;
    },
    // placeholderData: keepPreviousData,
  });
};

export const useGetProducts = (
  creatorId = '',
  length = 10,
  start = 0,
  searchValue = '',
  status = 'active',
) => {
  return useQuery({
    queryKey: ['products', {length, start, searchValue, status, creatorId}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...(creatorId
          ? {
              ...apiEndpoints.GetCreatorProducts,
              endpoint: apiEndpoints.GetCreatorProducts.endpoint?.replace(
                '{id}',
                creatorId,
              ),
            }
          : apiEndpoints.AllProducts),
        params: {
          ...apiEndpoints.AllProducts.params,
          query: {
            ...apiEndpoints.AllProducts.params.query,
            length,
            start,
            searchValue,
            status,
          },
        },
      });
      return response;
    },
  });
};

export const useGetProductsDetails = (
  creatorId = '',
  productId = '',
  length = 10,
  start = 0,
) => {
  return useQuery({
    queryKey: ['approve', {length, start, productId, creatorId}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...(creatorId && productId
          ? {
              ...apiEndpoints.GetCreatorProductsDetail,
              endpoint: apiEndpoints.GetCreatorProductsDetail.endpoint
                ?.replace('{creatorId}', creatorId)
                .replace('{productId}', productId),
            }
          : {
              ...apiEndpoints.GetProductsDetail,
              endpoint: apiEndpoints.GetProductsDetail.endpoint?.replace(
                '{productId}',
                productId,
              ),
            }),
      });
      return response;
    },
  });
};

export const useApproveReject = () => {
  return useMutation<
    any,
    Error,
    {productId: string; action: string} // The input type for the mutation
  >({
    mutationFn: async ({
      productId,
      action,
    }: {
      productId: string;
      action: string;
    }) => {
      const response = await makeApiCall({
        ...apiEndpoints.approveReject,
        params: {
          ...apiEndpoints.approveReject.params,
          query: {
            ...apiEndpoints.approveReject.params.query,
            productId,
            action,
          },
        },
      });
      return response;
    },
  });
};

export const useProductsOfDay = () => {
  return useMutation<
    any,
    Error,
    void // Corrected input type for the mutation
  >({
    mutationFn: async () => {
      const response = await makeApiCall(apiEndpoints.GetProductsOfTheDay);
      return response;
    },
  });
};