import {configureStore} from '@reduxjs/toolkit';
import generalReducer from './General/General';

const store = configureStore({
  reducer: {
    general: generalReducer,
  },
});

export default store;
