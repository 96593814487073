import React from 'react';
import arrow from '../../../assets/images/icons/arrow-right.png';
import {useDispatch} from 'react-redux';
import {
  setFlyout,
  setProductConfigForFlyout,
} from '../../../store/General/General';

interface Product {
  productId: string;
  name: string;
  category: string;
  subCategory: string;
  exploreImage: string;
  rating: number;
}

interface TopTrendingProps {
  title: string;
  selectOptions: {value: string; label: string}[];
  products: Product[];
  buttonText: string;
  onAddProduct: (e:any) => void;
  ratingImage: string;
  showAddButton?: boolean;
  type?: string;
}

const TopTrending: React.FC<TopTrendingProps> = ({
  title,
  selectOptions,
  products,
  buttonText,
  ratingImage,
  showAddButton = true,
  onAddProduct,
  type
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="mb-6 flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-white md-lt:text-xl md-lt:font-normal">
          {title}
        </h2>
        <select className="rounded-full bg-[#00000033] px-1 py-0.5 pl-2 text-[10px] text-gray-400">
          {selectOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-col gap-2">
        {products?.map((product, index) => (
          <div
            key={product.productId}
            className="rounded-lg p-[1px] hover:bg-gradient-to-br hover:from-[#3c80f6] hover:via-[#312961] hover:to-[#4b3baf]"
          >
            <div className="flex items-center justify-between rounded-lg bg-[#2E246C80] px-4 py-2 hover:bg-[#340f87]">
              <div className="flex items-center gap-4">
                <span className="text-3xl font-bold text-[#3D56F0]">
                  {index + 1}
                </span>
                <img
                  src={product.exploreImage}
                  alt={product.name}
                  className="h-12 w-16 rounded-lg"
                />
                <div>
                  <h3 className="font-semibold text-white">
                    {`${product.name}`.length > 32
                      ? `${product.name}`.slice(0, 32) + '...'
                      : `${product.name}`}
                  </h3>
                  <p className="text-[10px] text-gray-400">
                    {`${product.category}, ${product.subCategory}`.length > 32
                      ? `${product.category}, ${product.subCategory}`.slice(
                          0,
                          32,
                        ) + '...'
                      : `${product.category}, ${product.subCategory}`}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-1">
                <span className="w-4">
                  <img src={ratingImage} alt="top" />
                </span>
                <span className="font-semibold text-white">
                  {product.rating ? product.rating.toFixed(1) : 0}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {showAddButton && (
        <div className="mt-6 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
          <button
            onClick={() => {
              dispatch(setFlyout(true));
              dispatch(
                setProductConfigForFlyout({
                  title: title,
                }),
              );
              onAddProduct(type)
            }}
            className="flex w-full items-center justify-between rounded-md bg-custom-gradient p-4 px-6 py-4 text-white shadow-custom-shadow transition-all"
          >
            <span> {buttonText}</span>
            <img src={arrow} className="w-2" alt="arrow" />
          </button>
        </div>
      )}
    </>
  );
};

export default TopTrending;
