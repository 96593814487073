import {Link, useLocation, useNavigate} from 'react-router-dom';

import Input from '../../components/common/InputNew';
import Button from '../../components/ui/Button';

import logo from '../../assets/images/logo/lusso.png';

import {useForm} from 'react-hook-form';
import {z, ZodType} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {useGetMe, useLogin} from '../../hooks/authQueryHooks';
import {toast} from 'react-toastify';

import {useAuth} from '../../context/AuthContext';

interface FormData {
  email: string;
  password: string;
}

const LoginSchema: ZodType<FormData> = z.object({
  email: z
    .string()
    .min(1, {message: 'Email is required'}) // Ensures non-empty email
    .email({message: 'Please enter a valid email address'}), // Ensures it's a valid email

  password: z
    .string()
    .min(1, {message: 'Password is required'}) // Ensures non-empty password
    .min(8, {message: 'Password must be at least 8 characters long'}), // Ensures password has a minimum length
});

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {mutate: handleLogin, isPending: loggingIn} = useLogin();
  const {mutate: handleGetMe, isPending: gettingMe} = useGetMe();

  const {login} = useAuth();

  const {
    register,
    handleSubmit,
    formState: {errors},
    // setError,
  } = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
  });

  const onSubmit = async (data: FormData) => {
    handleLogin(data, {
      onSuccess: () => {
        handleGetMe(undefined, {
          onSuccess: res => {
            login(res);
            toast.success('Logged In Successfully', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
            });
            const redirect = location.state?.from || '/';
            navigate(redirect);
          },
        });
      },
    });
  };

  return (
    <div className="mx-auto flex w-2/6 flex-col gap-12 text-white">
      <img src={logo} alt="" className="w-64 self-center" />
      <div>
        <h1 className="text-center text-3xl font-medium uppercase tracking-widest text-[#00ffff]">
          Hello
        </h1>
        <h1 className="text-center text-3xl font-normal uppercase tracking-widest">
          Welcome Back
        </h1>
        <p className="mb-6 mt-2 text-center text-xl font-light">
          Login to access your dashboard.
        </p>
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder="Enter your email"
            // disabled
            className="text-xs"
            name="email"
            register={register}
            error={errors.email}
            required
          />
          <Input
            placeholder="Enter your password"
            // disabled
            type="password"
            className="text-xs"
            name="password"
            register={register}
            error={errors.password}
            required
          />
          <div className="text-right text-xs font-medium text-[#5E91FF]">
            <Link to={'/forgot-password'}>Forgot password</Link>
          </div>
          <Button
            label="Login"
            className="relative w-full rounded-full bg-custom-btn-gradient px-6 py-1.5 text-sm hover:bg-opacity-60"
            type="submit"
            isLoading={loggingIn || gettingMe}
          />
          <div className="text-center text-xs font-normal text-gray-400">
            Not yet part of the Lusso Community?{' '}
            <Link to={'#'} className="font-medium text-[#5E91FF]">
              Sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
