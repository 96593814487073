import React from 'react';
import {ReactElement, useState} from 'react';
import TabItem from './TabItem';
import {TabItemProps, TabListProps} from './TabTypes';
import {sanitizeForId} from '../../../lib/stringUtils';

const TabList: React.FC<TabListProps> = ({
  children,
  activeTabIndex = 0,
  listClasses = '',
  itemClasses = '',
  gap = 0,
}) => {
  const [activeTab, setActiveTab] = useState(activeTabIndex);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const tabs = React.Children.toArray(children).filter(
    (child): child is ReactElement<TabItemProps> =>
      React.isValidElement(child) && child.type === TabItem,
  );

  return (
    <div className={`flex flex-col gap-${gap} h-full`}>
      <nav className="">
        <ul
          className={`flex overflow-hidden rounded-t-2xl bg-tab-gradient text-gray-400 ${listClasses}`}
          role="tablist"
          aria-orientation="horizontal"
        >
          {tabs.map((tab, index) => (
            <li key={`tab-${index}`} className="flex flex-grow">
              <button
                key={`tab-btn-${index}`}
                role="tab"
                id={`tab-${sanitizeForId(tab.props.label)}`}
                aria-controls={`panel-${sanitizeForId(tab.props.label)}`}
                aria-selected={activeTab === index}
                onClick={() => handleTabClick(index)}
                className={`tab-btn flex items-center justify-center hover:bg-tab-active-gradient hover:text-[#00f0fb] ${
                  activeTab === index && 'bg-tab-active-gradient text-[#00f0fb]'
                } ${itemClasses}`}
              >
                {tab.props.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {tabs[activeTab]}
    </div>
  );
};

export default TabList;
