import React, {useState} from 'react';
import search from '../../../assets/images/icons/search.png';
import add from '../../../assets/images/icons/add.png';
import close from '../../../assets/images/icons/close.png';
import {useGetProducts} from '../../../hooks/productQueryHooks';

interface RecommendedProductsProps {
  title: string;
  onAddProduct: () => void;
  onCancel: () => void;
}

const RecommendedProducts: React.FC<RecommendedProductsProps> = ({
  title,
  onAddProduct,
  onCancel,
}) => {
  const [showProducts, setShowProduct] = useState('active');

  const [fetchedRecords] = useState<number>(20);
  const [start, setStart] = useState<number>(0);
  const [searchValue, setSearchValue] = useState('');

  const {data: products} = useGetProducts(
    '',
    fetchedRecords,
    start,
    searchValue,
    showProducts,
  );
  return (
    <div className="flex min-h-screen flex-col items-center bg-gradient-to-b from-[#18142D] to-[#2E246C] py-10">
      <h1 className="mb-8 text-3xl font-semibold text-gray-300">{title}</h1>

      <div className="mb-6 w-[90%]">
        <div className="relative">
          <input
            type="text"
            placeholder="Search by category, Product"
            className="w-full rounded-full border border-purple-500 bg-[#18142D] p-2 pl-12 text-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <img
            className="absolute left-4 top-3 h-5 w-5 text-gray-400"
            src={search} alt='search'
          />
        </div>
      </div>

      <div className="w-[90%] space-y-4 h-[400px] overflow-x-auto pr-2"> 
        {products?.products.map((product:any, index:number) => (
          <div
            key={index}
            className="flex items-center justify-between rounded-xl bg-[#2E246C] p-4"
          >
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-purple-500"
              />
              <img
                src={product.bannerImage}
                alt={product.name}
                className="ml-4 h-12 w-12 rounded"
              />
              <div className="ml-4">
                <h3 className="font-semibold text-white">{product.name}</h3>
                <p className="text-sm text-gray-400">{product.category}</p>
              </div>
            </div>
            <div className="flex items-center">
              <span className="text-lg text-yellow-400">&#9733;</span>
              <span className="ml-1 text-white">{product.rating}</span>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 flex space-x-4">
        <button className="flex items-center space-x-2 rounded-full bg-gradient-to-r from-pink-500 to-purple-600 px-6 py-3 text-white">
          <img src={add} alt='add' className="w-6" />
          <span className="font-semibold">Add Product</span>
        </button>

        <button
          onClick={() => onCancel()}
          className="flex items-center space-x-2 rounded-full bg-[#5721B9] px-6 py-3 text-gray-300"
        >
          <img src={close} alt='cancel' className="w-6" />
          <span className="font-semibold">Cancel</span>
        </button>
      </div>
    </div>
  );
};

export default RecommendedProducts;
