import {useCallback, useState} from 'react';
import Search from '../../search/Search';
import Badge from '../../ui/Badge';
import Button from '../../ui/Button';

import {ReactComponent as EyeIcon} from '../../../assets/images/icons/Eye.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/images/icons/delete.svg';
import {ReactComponent as RepeatIcon} from '../../../assets/images/icons/icon_repeat.svg';
import facebookLogo from '../../../assets/images/misc/fb.png';
import instagramLogo from '../../../assets/images/misc/ig.png';
import xLogo from '../../../assets/images/misc/x.png';
import tiktokLogo from '../../../assets/images/misc/tiktok.png';

const postData = [
  {
    id: 1,
    user: 'John Doe',
    productName: 'Pet Shop',
    description: 'Description here...',
    date: '10/12/24',
    time: '10:30 AM',
    status: 'pending',
    platforms: [facebookLogo, instagramLogo, tiktokLogo, xLogo],
  },
  {
    id: 2,
    user: 'John Doe',
    productName: 'Pet Shop',
    description: 'Description here...',
    date: '10/12/24',
    time: '10:30 AM',
    status: 'active',
    platforms: [facebookLogo, instagramLogo, tiktokLogo, xLogo],
  },
  {
    id: 3,
    user: 'John Doe',
    productName: 'Pet Shop',
    description: 'Description here...',
    date: '10/12/24',
    time: '10:30 AM',
    status: 'inactive',
    platforms: [facebookLogo, instagramLogo, tiktokLogo, xLogo],
  },
  {
    id: 4,
    user: 'John Doe',
    productName: 'Pet Shop',
    description: 'Description here...',
    date: '10/12/24',
    time: '10:30 AM',
    status: 'active',
    platforms: [facebookLogo, instagramLogo, tiktokLogo, xLogo],
  },
  {
    id: 5,
    user: 'John Doe',
    productName: 'Pet Shop',
    description: 'Description here...',
    date: '10/12/24',
    time: '10:30 AM',
    status: 'banned',
    platforms: [facebookLogo, instagramLogo, tiktokLogo, xLogo],
  },
];

const ScheduledPosts = () => {
  const [tableData, setTableData] = useState<any[]>(postData ?? []);

  return (
    <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="relative flex flex-col gap-8 rounded-md bg-custom-gradient p-8 shadow-custom-shadow">
        <div className="flex items-center justify-between">
          <p className="text-2xl font-normal text-white md-lt:text-xl">
            Scheduled Posts
          </p>
          <Search
            className="h-10 w-60 md-lt:w-36"
            placeholder="Search user"
            onSearch={() => {}}
          />
        </div>
        <div className="h-full md-lt:overflow-x-scroll">
          <table className="h-full w-full table-auto border-collapse">
            <thead className="h-12 text-nowrap border-solid bg-custom-purple-1 text-left text-sm font-semibold uppercase">
              <tr>
                <td className="px-3 py-2">User</td>
                <td className="px-3 py-2">Product Name</td>
                <td className="px-3 py-2">Description</td>
                <td className="px-3 py-2 text-center">Date</td>
                <td className="px-3 py-2 text-center">Time</td>
                <td className="px-3 py-2 text-center">Platform</td>
                <td className="px-3 py-2 text-center">Actions</td>
                <td className="px-3 py-2 text-center">Status</td>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#3c4391] text-nowrap border-b border-solid border-[#3c4391] bg-[#251c5c] bg-opacity-30 text-sm font-light">
              {tableData.map(data => (
                <tr key={data.id}>
                  <td className="px-3 py-4">{data.user}</td>
                  <td className="px-3 py-4">{data.productName}</td>
                  <td className="px-3 py-4">{data.description}</td>
                  <td className="px-3 py-4 text-center">{data.date}</td>
                  <td className="px-3 py-4 text-center">{data.time}</td>
                  <td className="px-3 py-4 text-center">
                    <div className="flex h-full w-full items-center justify-center gap-2">
                      {data.platforms.map((logo: any) => (
                        <img
                          key={logo}
                          src={logo}
                          alt="platform"
                          className="h-4 w-4"
                        />
                      ))}
                    </div>
                  </td>
                  <td className="px-3 py-4 text-center">
                    <Button
                      className="rounded-full bg-transparent p-1 hover:bg-[#00000022] active:bg-[#00000044]"
                      type="custom"
                    >
                      <EyeIcon className="h-5 w-5" />
                    </Button>
                    <Button
                      className="rounded-full bg-transparent p-1 hover:bg-[#00000022] active:bg-[#00000044]"
                      type="custom"
                    >
                      <DeleteIcon className="h-5 w-5" />
                    </Button>
                    <Button
                      className="rounded-full bg-transparent p-1 hover:bg-[#00000022] active:bg-[#00000044]"
                      type="custom"
                    >
                      <RepeatIcon className="h-5 w-5" />
                    </Button>
                  </td>
                  <td className="px-3 py-4 text-center">
                    <Badge
                      value={data.status}
                      textColorEnumKey={data.status}
                      bgColorEnumKey={data.status}
                      rounded="full"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ScheduledPosts;
