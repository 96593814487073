import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import closeIcon from '../../../assets/images/icons/closeIcon.png';
import redirect from '../../../assets/images/icons/redirect.png';
import productMediaLink from '../../../assets/images/icons/productMedia.png';
import fbSign from '../../../assets/images/icons/fbSign.png';
import history from '../../../assets/images/icons/history.png';
import ViewOnlyInput from '../../common/ViewOnlyInput';
import Flyover from '../../common/Flyover';
import ProductHistory from './Flyovers/ProductHistory';
import ApproveProduct from './Flyovers/ApproveProduct';
import RejectProduct from './Flyovers/RejectProduct';
import SentComents from './Flyovers/SentComents';
import {setFlyout} from '../../../store/General/General';
import {useParams} from 'react-router-dom';
import {
  useGetProductsDetails,
  useApproveReject,
} from '../../../hooks/productQueryHooks';
import Popover from '../../common/Popover';
import {toast} from 'react-toastify';

function ProductReviewUI() {
  const {productId, creatorId} = useParams<{
    productId: string;
    creatorId: string;
  }>();

  const dispatch = useDispatch();
  const [productName, setProductName] = useState(false);
  const [productCategory, setProductCategory] = useState(false);
  const [productInfo, setProductInfo] = useState(false);
  const [productMedia, setProductMedia] = useState(false);
  const [productPromotion, setProductPromotion] = useState(false);
  const enableFlyout = useSelector((state: any) => state.general.enableFlyout);
  const [flyoverType, setFlyoverType] = useState('');
  const [isPopoverHidden, setIsPopoverHidden] = useState(false);

  const {data: products} = useGetProductsDetails(creatorId, productId);
  const {mutate, isError, data, error} = useApproveReject();

  const hidePopover = () => {
    setIsPopoverHidden(false);
  };

  const handleApproveReject = (action: string) => {
    if (!productId) {
      console.error('productId is undefined!');
      return;
    }
    mutate(
      {productId: productId, action: action},
      {
        onSuccess: response => {
          toast.success('Request has been proceeded successfully', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
        },
        // onError: (err) => {
        //   toast.error('Unable to process request', {
        //     position: 'top-right',
        //     autoClose: 3000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //   });
        // },
      },
    );
  };

  return (
    <>
      <div className="relative w-full">
        <button
          onClick={() => {
            dispatch(setFlyout(true));
            setFlyoverType('history');
          }}
          className="absolute right-[0rem] top-[-4rem] flex h-12 w-12 items-center justify-center rounded-lg border border-gray-400 bg-[#1b1446] text-white"
        >
          <img src={history} alt="history" />
        </button>
      </div>
      <div className="w-full rounded-lg">
        {/* Product Name & Logo */}
        <div className="mb-4 flex cursor-pointer items-center justify-between">
          <div
            onClick={() => setProductName(!productInfo)}
            className="mr-4 flex w-full items-center justify-between rounded-[10px] border border-[rgba(108,140,255,0.5)] bg-gradient-to-br from-[rgba(45,36,108,0.9)] via-[rgba(22,19,43,0.5)] via-[rgba(24,20,46,0.5)] to-[rgba(37,32,74,0.9)] p-4"
          >
            <span className="text-sm font-semibold text-white">
              Product Name & Logo
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-4 w-4 transform text-white transition-transform ${
                productName ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <Popover
            hidePopover={isPopoverHidden}
            button={
              <button className="flex w-[200px] items-center space-x-2 rounded-[10px] border border-[#6c8cff7f] bg-[#482296ff] px-4 py-3 text-white shadow-md transition duration-300 ease-in-out">
                <span className="text-lg font-bold">+</span>
                <span
                  className="font-medium"
                  onClick={() => setIsPopoverHidden(false)}
                >
                  Add Comment
                </span>
              </button>
            }
            content={
              <div className="z-18 mr-10 mt-4 w-[50vw] rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <div className="mx-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
                  <h2 className="mb-4 text-xl font-semibold text-white">
                    Add Comment
                  </h2>
                  <label className="mb-2">Comment</label>
                  <textarea
                    className="w-full rounded-lg border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
                    rows={6}
                    placeholder="Write a detailed comment for the creator on this section..."
                  ></textarea>
                  <div className="mt-4 flex space-x-4">
                    <button className="relative rounded-[1.5rem] p-[1px]">
                      <div
                        className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-1 text-white"
                        style={{
                          background:
                            'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                        }}
                      >
                        Save
                      </div>
                    </button>

                    <button
                      onClick={() => setIsPopoverHidden(true)}
                      className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-1 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            position="bottom"
          />
        </div>
        {productName && (
          <div>
            <div className="flex items-center">
              <div className="overflow-hidden rounded-lg">
                <img
                  src={products.bannerImage}
                  alt="Product"
                  className="w-[200px]"
                />
              </div>
              <div className="ml-4">
                <h3 className="mb-1 text-xs font-semibold text-cyan-400">
                  PRODUCT NAME
                </h3>
                <h2 className="text-md text-white">{products.name}</h2>
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  PRODUCT TAGLINE
                </h3>
                <p className="text-md text-white">{products.tagLine}</p>
              </div>
            </div>
          </div>
        )}

        {/* Product Info */}
        <div className="mb-4 mt-4 flex cursor-pointer items-center justify-between">
          <div
            onClick={() => setProductInfo(!productInfo)}
            className="mr-4 flex w-full items-center justify-between rounded-[10px] border border-[rgba(108,140,255,0.5)] bg-gradient-to-br from-[rgba(45,36,108,0.9)] via-[rgba(22,19,43,0.5)] via-[rgba(24,20,46,0.5)] to-[rgba(37,32,74,0.9)] p-4"
          >
            <span className="text-sm font-semibold text-white">
              Product Info
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-4 w-4 transform text-white transition-transform ${
                productName ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <Popover
            hidePopover={isPopoverHidden}
            button={
              <button className="flex w-[200px] items-center space-x-2 rounded-[10px] border border-[#6c8cff7f] bg-[#482296ff] px-4 py-3 text-white shadow-md transition duration-300 ease-in-out">
                <span className="text-lg font-bold">+</span>
                <span
                  className="font-medium"
                  onClick={() => setIsPopoverHidden(false)}
                >
                  Add Comment
                </span>
              </button>
            }
            content={
              <div className="z-18 mr-10 mt-4 w-[50vw] rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <div className="mx-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
                  <h2 className="mb-4 text-xl font-semibold text-white">
                    Add Comment
                  </h2>
                  <label className="mb-2">Comment</label>
                  <textarea
                    className="w-full rounded-lg border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
                    rows={6}
                    placeholder="Write a detailed comment for the creator on this section..."
                  ></textarea>
                  <div className="mt-4 flex space-x-4">
                    <button className="relative rounded-[1.5rem] p-[1px]">
                      <div
                        className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-1 text-white"
                        style={{
                          background:
                            'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                        }}
                      >
                        Save
                      </div>
                    </button>

                    <button
                      onClick={() => setIsPopoverHidden(true)}
                      className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-1 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            position="bottom"
          />
        </div>
        {productInfo && (
          <div>
            <div className="flex items-center">
              <div className="ml-1">
                <h3 className="mb-1 text-xs font-semibold text-cyan-400">
                  PRODUCT CATEGORY
                </h3>
                <h2 className="text-md text-white">{products.category}</h2>
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  PRODUCT'S SUB CATEGORY
                </h3>
                <p className="text-md text-white">{products.subCategory}</p>
              </div>
            </div>
          </div>
        )}

        {/* Product Name & Logo */}
        <div className="mb-4 mt-4 flex cursor-pointer items-center justify-between">
          <div
            onClick={() => setProductCategory(!productCategory)}
            className="mr-4 flex w-full items-center justify-between rounded-[10px] border border-[rgba(108,140,255,0.5)] bg-gradient-to-br from-[rgba(45,36,108,0.9)] via-[rgba(22,19,43,0.5)] via-[rgba(24,20,46,0.5)] to-[rgba(37,32,74,0.9)] p-4"
          >
            <span className="text-sm font-semibold text-white">
              Product Category & Sub Category
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-4 w-4 transform text-white transition-transform ${
                productName ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <Popover
            hidePopover={isPopoverHidden}
            button={
              <button className="flex w-[200px] items-center space-x-2 rounded-[10px] border border-[#6c8cff7f] bg-[#482296ff] px-4 py-3 text-white shadow-md transition duration-300 ease-in-out">
                <span className="text-lg font-bold">+</span>
                <span
                  className="font-medium"
                  onClick={() => setIsPopoverHidden(false)}
                >
                  Add Comment
                </span>
              </button>
            }
            content={
              <div className="z-18 mr-10 mt-4 w-[50vw] rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <div className="mx-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
                  <h2 className="mb-4 text-xl font-semibold text-white">
                    Add Comment
                  </h2>
                  <label className="mb-2">Comment</label>
                  <textarea
                    className="w-full rounded-lg border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
                    rows={6}
                    placeholder="Write a detailed comment for the creator on this section..."
                  ></textarea>
                  <div className="mt-4 flex space-x-4">
                    <button className="relative rounded-[1.5rem] p-[1px]">
                      <div
                        className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-1 text-white"
                        style={{
                          background:
                            'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                        }}
                      >
                        Save
                      </div>
                    </button>

                    <button
                      onClick={() => setIsPopoverHidden(true)}
                      className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-1 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            position="bottom"
          />
        </div>
        {productCategory && (
          <div>
            <div className="ml-1 mt-4 rounded-lg shadow-sm">
              <label className="mb-2 block text-sm font-semibold text-white">
                Product Description
              </label>
              <textarea
                placeholder={products.description}
                className="h-32 w-full resize-none rounded-lg bg-[#161333] p-4 text-white placeholder-white/70 shadow-inner focus:outline-none focus:ring-2 focus:ring-[#5651e5]"
              ></textarea>
            </div>
            <div className="mt-4 flex items-center border-b border-[rgba(108,140,255,0.5)]">
              <div className="ml-1">
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  PRODUCT'S SUB CATEGORY
                </h3>
                <p className="text-md mb-3 mt-1 text-white">
                  TikTok is a social media platform that lets users create,
                  discover, and share short-form videos with a global audience.
                  Known for its viral challenges, filters, and diverse music
                  library, TikTok offers an immersive experience for both
                  creators and viewers. With a focus on entertainment,
                  creativity, and community, TikTok has become a cultural
                  phenomenon, empowering users to express themselves and connect
                  with others.
                </p>
              </div>
            </div>
            <div className="mt-4 flex items-center border-b border-[rgba(108,140,255,0.5)]">
              <div className="ml-1">
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  FEATURES
                </h3>
                <p
                  className="text-md mb-3 mt-1 text-white"
                  dangerouslySetInnerHTML={{
                    __html: products.productInfo?.Feature?.Features,
                  }}
                ></p>
              </div>
            </div>
            <div className="mt-4 flex items-center border-b border-[rgba(108,140,255,0.5)]">
              <div className="ml-1">
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  FUNCTIONALITY
                </h3>
                <p className="text-md mb-3 mt-1 text-white">
                  TikTok provides a seamless user experience with a variety of
                  content discovery options, such as the personalized "For You"
                  page. Users can interact with content via likes, shares, and
                  comments, while creators can edit their videos with music
                  overlays, special effects, and filters. The app also
                  integrates social sharing features, allowing users to post
                  their content on other platforms like Instagram and Facebook.
                </p>
              </div>
            </div>
            <div className="mt-4 flex items-center border-b border-[rgba(108,140,255,0.5)]">
              <div className="ml-1">
                <h3 className="mt-4 text-xs font-semibold text-cyan-400">
                  KEY FEATURES
                </h3>
                <p className="text-md mb-3 mt-1 text-white">
                  Global Reach: Instantly connect with millions of users across
                  the globe.
                  <br />
                  Content Discovery: Personalized recommendations based on user
                  preferences and activity.
                  <br />
                  Easy to Create: Simple video editing tools make it easy to
                  create engaging content in minutes.
                  <br />
                  Viral Trends: Stay ahead of viral challenges and trends that
                  dominate social media.
                  <br />
                  Monetization: Creators can earn through sponsored content and
                  partnerships.
                </p>
              </div>
            </div>
            <div className="w-full rounded-lg shadow-md">
              <label className="mb-2 block text-sm font-semibold text-cyan-400">
                Product Compatibility
              </label>
              <p className="mb-4 text-sm font-semibold text-white">
                Compatibility
              </p>
              <div className="flex flex-wrap gap-4">
                {products.additionalInfo?.productComability?.map(
                  (element: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center rounded-full bg-[#E9DFFC] px-4 py-[2px] text-[##BE9FF6] shadow-md"
                    >
                      <span className="font-small flex text-xs">
                        <img
                          alt="close"
                          src={closeIcon}
                          className="mr-1 w-[16px]"
                        />
                        {element}
                      </span>
                    </div>
                  ),
                )}
              </div>
            </div>
            <div className="w-full space-y-8 rounded-lg pt-8 text-white shadow-md">
              <div className="space-y-2">
                <h3 className="mb-8 text-sm font-semibold text-cyan-400">
                  Product Support Info
                </h3>
                <div className="flex gap-8">
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold">
                      EMAIL
                    </label>
                    <ViewOnlyInput
                      value={products.additionalInfo?.supportInfo?.EMAIL}
                      onChange={() => {}}
                    />
                  </div>
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold">
                      PHONE
                    </label>
                    <ViewOnlyInput
                      value={products.additionalInfo?.supportInfo?.PHONE}
                      type="tel"
                      onChange={() => {}}
                    />
                  </div>
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold">
                      WEBSITE
                    </label>
                    <ViewOnlyInput
                      value={products.additionalInfo?.supportInfo?.WEBSITE}
                      type="url"
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="mb-8 text-sm font-semibold text-cyan-400">
                  Product Social Links
                </h3>
                <div className="flex gap-8">
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold text-cyan-400">
                      FACEBOOK
                    </label>
                    <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                      <ViewOnlyInput
                        value={
                          products.additionalInfo?.socialLinks?.Facebook?.link
                        }
                        type="url"
                        onChange={() => {}}
                      />
                      <a
                        href={
                          products.additionalInfo?.socialLinks?.Facebook?.link
                        }
                        className="ml-2 text-cyan-400"
                      >
                        <img
                          src={redirect}
                          className="h-5 w-5"
                          alt="External link"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold text-cyan-400">
                      INSTAGRAM
                    </label>
                    <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                      <ViewOnlyInput
                        value={
                          products.additionalInfo?.socialLinks?.Instagram?.link
                        }
                        type="url"
                        onChange={() => {}}
                      />
                      <a
                        href={
                          products.additionalInfo?.socialLinks?.Instagram?.link
                        }
                        className="ml-2 text-cyan-400"
                      >
                        <img
                          src={redirect}
                          className="h-5 w-5"
                          alt="External link"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold text-cyan-400">
                      TWITTER
                    </label>
                    <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                      <ViewOnlyInput
                        value={
                          products.additionalInfo?.socialLinks?.Twitter?.link
                        }
                        type="url"
                        onChange={() => {}}
                      />
                      <a
                        href={
                          products.additionalInfo?.socialLinks?.Twitter?.link
                        }
                        className="ml-2 text-cyan-400"
                      >
                        <img
                          src={redirect}
                          className="h-5 w-5"
                          alt="External link"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div className="w-1/3">
                  <label className="mb-1 block text-sm font-semibold text-cyan-400">
                    G-DRIVE
                  </label>
                  <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                    <ViewOnlyInput
                      value={products.additionalInfo?.socialLinks?.GDRIVE?.link}
                      type="url"
                      onChange={() => {}}
                    />
                    <a
                      href={products.additionalInfo?.socialLinks?.GDRIVE?.link}
                      className="ml-2 text-cyan-400"
                    >
                      <img
                        src={redirect}
                        className="h-5 w-5"
                        alt="External link"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <h3 className="mb-8 text-sm font-semibold text-cyan-400">
                  PAYMENT PLAN
                </h3>
                <ViewOnlyInput
                  value={'Lusso Access'}
                  type="text"
                  onChange={() => {}}
                />
              </div>

              <div className="space-y-2">
                <h3 className="mb-8 text-sm font-semibold text-cyan-400">
                  LANGUAGE SUPPORT
                </h3>
                <ViewOnlyInput
                  value={'English'}
                  type="text"
                  onChange={() => {}}
                />
              </div>

              <div className="space-y-2">
                <h3 className="mb-8 text-sm font-semibold text-cyan-400">
                  Audience
                </h3>
                <div className="flex gap-8">
                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold text-cyan-400">
                      AGE
                    </label>
                    <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                      <ViewOnlyInput
                        value={'18'}
                        type="url"
                        onChange={() => {}}
                      />
                    </div>
                  </div>

                  <div className="w-1/3">
                    <label className="mb-1 block text-sm font-semibold text-cyan-400">
                      COUNTRY
                    </label>
                    <div className="flex items-center border-b border-[rgba(108,140,255,0.5)]">
                      <ViewOnlyInput
                        value={'support@tiktok.com'}
                        type="url"
                        onChange={() => {}}
                      />
                      <a href="#" className="ml-2 text-cyan-400">
                        <img
                          src={redirect}
                          className="h-5 w-5"
                          alt="External link"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              style={{color: 'rgba(255, 255, 255, 0.5)'}}
              className="mt-4 text-sm"
            >
              Regardless of your location, you're legally required to comply
              with the Children's Online Privacy Protection Act (COPPA) and/or
              other laws. You're required to tell us whether your videos are
              made for kids.{' '}
              <a
                href="https://support.google.com/youtube/answer/9528076?hl=en"
                className="text-[#A259FF]"
              >
                What's content made for kids?
              </a>
            </p>
            <div className="mb-4 mt-4 flex items-center gap-3">
              <input
                type="radio"
                name="age_restriction"
                id="not_for_kids"
                className="custom-radio hidden"
              />
              <label
                htmlFor="not_for_kids"
                className="custom-radio-label relative h-8 w-8 cursor-pointer rounded-full"
              ></label>
              <span className="text-base text-white">
                No, it's not made for kids
              </span>
            </div>
          </div>
        )}

        {/* Product Media & Links */}
        <div className="mb-4 flex cursor-pointer items-center justify-between">
          <div
            onClick={() => setProductMedia(!productMedia)}
            className="mr-4 flex w-full items-center justify-between rounded-[10px] border border-[rgba(108,140,255,0.5)] bg-gradient-to-br from-[rgba(45,36,108,0.9)] via-[rgba(22,19,43,0.5)] via-[rgba(24,20,46,0.5)] to-[rgba(37,32,74,0.9)] p-4"
          >
            <span className="text-sm font-semibold text-white">
              Product Media & Links
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-4 w-4 transform text-white transition-transform ${
                productMedia ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <Popover
            hidePopover={isPopoverHidden}
            button={
              <button className="flex w-[200px] items-center space-x-2 rounded-[10px] border border-[#6c8cff7f] bg-[#482296ff] px-4 py-3 text-white shadow-md transition duration-300 ease-in-out">
                <span className="text-lg font-bold">+</span>
                <span
                  className="font-medium"
                  onClick={() => setIsPopoverHidden(false)}
                >
                  Add Comment
                </span>
              </button>
            }
            content={
              <div className="z-18 mr-10 mt-4 w-[50vw] rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <div className="mx-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
                  <h2 className="mb-4 text-xl font-semibold text-white">
                    Add Comment
                  </h2>
                  <label className="mb-2">Comment</label>
                  <textarea
                    className="w-full rounded-lg border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
                    rows={6}
                    placeholder="Write a detailed comment for the creator on this section..."
                  ></textarea>
                  <div className="mt-4 flex space-x-4">
                    <button className="relative rounded-[1.5rem] p-[1px]">
                      <div
                        className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-1 text-white"
                        style={{
                          background:
                            'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                        }}
                      >
                        Save
                      </div>
                    </button>

                    <button
                      onClick={() => setIsPopoverHidden(true)}
                      className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-1 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            position="bottom"
          />
        </div>
        {productMedia && (
          <div className="mb-4">
            <div className="flex items-center">
              <div className="overflow-hidden rounded-lg">
                <img
                  src={productMediaLink}
                  alt="Product"
                  className="w-[100px]"
                />
              </div>
              <div className="ml-4">
                <h3 className="mb-1 text-xs font-semibold text-cyan-400">
                  PRODUCT NAME
                </h3>
                <h2 className="text-md text-white">TickTok</h2>
                <h3 className="mt-1 text-xs font-semibold text-cyan-400">
                  PRODUCT TAGLINE
                </h3>
                <p className="text-md flex text-white">
                  Make Your Day{' '}
                  <span>
                    <img
                      src={redirect}
                      className="ml-20 mt-1 w-[20px]"
                      alt="redirect"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Product Promotion */}
        <div className="mb-4 flex cursor-pointer items-center justify-between">
          <div
            onClick={() => setProductPromotion(!productPromotion)}
            className="mr-4 flex w-full items-center justify-between rounded-[10px] border border-[rgba(108,140,255,0.5)] bg-gradient-to-br from-[rgba(45,36,108,0.9)] via-[rgba(22,19,43,0.5)] via-[rgba(24,20,46,0.5)] to-[rgba(37,32,74,0.9)] p-4"
          >
            <span className="text-sm font-semibold text-white">
              Product Promotion
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-4 w-4 transform text-white transition-transform ${
                productPromotion ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
          <Popover
            hidePopover={isPopoverHidden}
            button={
              <button className="flex w-[200px] items-center space-x-2 rounded-[10px] border border-[#6c8cff7f] bg-[#482296ff] px-4 py-3 text-white shadow-md transition duration-300 ease-in-out">
                <span className="text-lg font-bold">+</span>
                <span
                  className="font-medium"
                  onClick={() => setIsPopoverHidden(false)}
                >
                  Add Comment
                </span>
              </button>
            }
            content={
              <div className="mr-10 mt-4 w-[50vw] rounded-lg bg-gradient-to-b from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
                <div className="mx-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
                  <h2 className="mb-4 text-xl font-semibold text-white">
                    Add Comment
                  </h2>
                  <label className="mb-2">Comment</label>
                  <textarea
                    className="w-full rounded-lg border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm text-white focus:outline-none"
                    rows={6}
                    placeholder="Write a detailed comment for the creator on this section..."
                  ></textarea>
                  <div className="mt-4 flex space-x-4">
                    <button className="relative rounded-[1.5rem] p-[1px]">
                      <div
                        className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-1 text-white"
                        style={{
                          background:
                            'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                        }}
                      >
                        Save
                      </div>
                    </button>

                    <button
                      onClick={() => setIsPopoverHidden(true)}
                      className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-1 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            }
            position="bottom"
          />
        </div>
        {productPromotion && (
          <div>
            <div
              className="flex items-center rounded-lg border border-[#A768FD] p-4 text-white shadow-[0_4px_5px_rgba(0,0,0,0.25)] shadow-md"
              style={{
                background:
                  'linear-gradient(105.51deg, rgba(87, 4, 236, 0.2) 4.54%, rgba(203, 66, 251, 0.2) 89.74%)',
                borderRadius: '11px',
              }}
            >
              {' '}
              <div className="w-1/6">
                <span className="text-lg font-semibold">Facebook Ads</span>
              </div>
              <div className="w-1/4">
                <p className="text-sm text-gray-300">
                  Schedule posts, analyze performance, and grow your presence on
                  social media.
                </p>
              </div>
              <div className="flex w-1/6 justify-center">
                <img src={fbSign} alt="Facebook Icon" className="h-5 w-5" />
              </div>
              <div className="w-1/6">
                <button
                  style={{
                    background:
                      'linear-gradient(123.18deg, rgba(87, 4, 236, 0.5) 22.35%, rgba(203, 66, 251, 0.1) 107.61%)',
                  }}
                  className="rounded-full px-6 py-2 text-white transition duration-200 hover:bg-purple-700"
                >
                  Preview Product
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Flyover isOpen={enableFlyout} onClose={() => dispatch(setFlyout(false))}>
        {flyoverType === 'history' && <ProductHistory />}
        {flyoverType === 'reject' && <RejectProduct />}
        {flyoverType === 'approve' && <ApproveProduct />}
        {flyoverType === 'comment' && <SentComents />}
      </Flyover>
      <div className="mt-4 flex gap-4">
        {/* Button 1: Preview Product */}
        <button className="w-[188px] rounded-full border-2 border-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
          Preview Product
        </button>

        {/* Button 2: Sent Comments */}
        <button
          onClick={() => {
            dispatch(setFlyout(true));
            setFlyoverType('comment');
          }}
          className="relative rounded-[1.5rem] bg-gradient-to-r from-purple-500 to-pink-300 p-[1px]"
        >
          <div className="flex w-[188px] items-center justify-center rounded-[1.5rem] bg-[#18142e] px-8 py-2 text-white">
            Sent Comments
          </div>
        </button>

        {/* Button 3: Approve Product */}
        {products?.status === 'pending' && (
          <button
            onClick={() => {
              handleApproveReject('APPROVE');
              // dispatch(setFlyout(true));
              // setFlyoverType('approve');
            }}
            className="relative rounded-[1.5rem] p-[1px]"
          >
            <div
              className="flex w-[188px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
              style={{
                background:
                  'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
              }}
            >
              Approve Product
            </div>
          </button>
        )}

        {products?.status === 'pending' && (
          <button
            onClick={() => {
              handleApproveReject('REJECT');
              // dispatch(setFlyout(true));
              // setFlyoverType('reject');
            }}
            className="w-[188px] rounded-full border-2 border-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white"
          >
            Reject
          </button>
        )}
      </div>
    </>
  );
}

export default ProductReviewUI;
