import {useQuery, useMutation} from '@tanstack/react-query';
import makeApiCall from '../lib/apiCall';
import {apiEndpoints} from '../constants/api-endpoints';

export const useGetCreators = (length = 10, start = 0, searchValue = '') => {
  return useQuery({
    queryKey: ['creators', {length, start, searchValue}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.AllCreators,
        params: {
          ...apiEndpoints.AllCreators.params,
          query: {
            ...apiEndpoints.AllCreators.params.query,
            length,
            start,
            searchValue,
          },
        },
      });
      return response;
    },
    // placeholderData: keepPreviousData,
  });
};

export const useGetCreatorProfile = (id = '') => {
  return useQuery({
    queryKey: ['creator', {id}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetUserProfile,
        endpoint: apiEndpoints.GetUserProfile.endpoint?.replace('{id}', id),
      });
      return response;
    },
  });
};

export const useGetCreatorProducts = (
  id = '',
  length = 10,
  start = 0,
  searchValue = '',
  active = true,
) => {
  return useQuery({
    queryKey: ['creatorProducts', {id, length, start}],
    queryFn: async () => {
      const response = await makeApiCall({
        ...apiEndpoints.GetCreatorProducts,
        endpoint: apiEndpoints.GetCreatorProducts.endpoint?.replace('{id}', id),
        params: {
          ...apiEndpoints.GetCreatorProducts.params,
          query: {
            ...apiEndpoints.GetCreatorProducts.params.query,
            length,
            start,
          },
        },
      });
      return response;
    },
  });
};

export const useGetCreatorProductsAnalytics = () => {
  return useMutation<
    any,
    Error,
    {fromDate: string; toDate: string; creatorId: string} // The input type for the mutation
  >({
    mutationFn: async ({
      fromDate,
      toDate,
      creatorId,
    }: {
      fromDate: string;
      toDate: string;
      creatorId: string;
    }) => {
      const response = await makeApiCall({
        ...apiEndpoints.GetCreatorProductsAnalytics,
        endpoint: apiEndpoints.GetCreatorProductsAnalytics.endpoint?.replace(
          '{creatorId}',
          creatorId,
        ),
        params: {
          ...apiEndpoints.GetCreatorProductsAnalytics.params,
          query: {
            ...apiEndpoints.GetCreatorProductsAnalytics.params.query,
            fromDate,
            toDate,
          },
        },
      });
      return response;
    },
  });
};

export const useGetCreatorSocialAnalytics = () => {
  return useMutation<
  any,
  Error, 
  { fromDate: string; toDate: string, creatorId: string, platform : string } // The input type for the mutation
>({
  mutationFn: async ({ fromDate, toDate, creatorId, platform}: { fromDate: string; toDate: string; creatorId: string; platform: string; }) => {
    const response = await makeApiCall({
      ...apiEndpoints.GetCreatorSocialAnalytics,
      endpoint: apiEndpoints.GetCreatorSocialAnalytics.endpoint?.replace(
        '{creatorId}',
        creatorId,
      ),
      params: {
        ...apiEndpoints.GetCreatorSocialAnalytics.params,
        query: {
          ...apiEndpoints.GetCreatorSocialAnalytics.params.query,
          fromDate,
          toDate,
          platform,
        },
      },
    });
    return response;
  },
});
};
