import {useState, useCallback} from 'react';
import dp from '../../assets/images/icons/dp.png';
import socials from '../../assets/images/icons/socials.png';
import eyeIcon from '../../assets/images/icons/eye.png';
import deleteIcon from '../../assets/images/icons/delete.png';
import Pagination from '../../components/common/Pagination';
import Search from '../../components/search/Search';
import {useNavigate} from 'react-router-dom';
import {useGetCreators} from '../../hooks/creatorQueryHooks';
import Loading from '../../components/common/Loading';

const ManageCreator = () => {
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('scheduled');
  const [fetchedRecords] = useState<number>(20);
  const [start, setStart] = useState<number>(0);

  const navigate = useNavigate();

  const onPageChange = (newStart: number) => {
    setStart(newStart);
  };

  const handleSearch = useCallback((query: string) => {
    setSearchValue(query);
    setStart(0);
  }, []);

  const {data: creatorData, isPending} = useGetCreators(
    fetchedRecords,
    start,
    searchValue,
  );

  const totalRecords = creatorData?.recordsFiltered || 0;

  const filterOptions = [
    {label: 'Scheduled', value: 'scheduled'},
    {label: 'Publish', value: 'publish'},
    {label: 'Review', value: 'review'},
  ];

  const onFilterChange = (value: any) => {
    setFilterValue(value);
  };

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-white">Manage Creator</h1>

        <div className="flex items-center space-x-4">
          <div className="relative w-[250px]">
            <select
              value={filterValue}
              onChange={e => onFilterChange(e.target.value)}
              className="h-9 w-full appearance-none rounded-md border border-[#c49af9] bg-[#171233] px-4 py-1 pr-8 text-white shadow-custom-shadow outline-none"
            >
              {filterOptions.map(option => (
                <option
                  key={option.value}
                  className="bg-[#1b1446]"
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-4 w-4 text-white"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4.25 4.25a.75.75 0 01-1.06 0l-4.25-4.25a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>

          <Search
            className="h-10 w-[250px]"
            placeholder={'Serach Creator'}
            onSearch={handleSearch}
          />
        </div>
      </div>

      <div>
        {!isPending &&
          creatorData?.users.map((item: any, index: number) => (
            <div
              key={index}
              className="mb-2 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]"
            >
              <div className="flex w-full items-center justify-between rounded-md bg-custom-gradient p-5 shadow-custom-shadow">
                <div className="relative flex w-[200px] cursor-pointer items-center space-x-2">
                  <img
                    src={dp}
                    alt="dp"
                    className="h-16 w-16 rounded-full object-cover"
                  />
                  <span className="ml-[-2rem!important] mt-[-3rem] rounded-full bg-green-500 px-2 text-[10px] text-xs text-white">
                    {item.status}
                  </span>
                  <div
                    className="ml-[31px]"
                    onClick={() => navigate(`creator-detail/${item.userId}`)}
                  >
                    <div className="flex items-center space-x-2">
                      <span className="text-lg font-semibold text-white">
                        {item.userName}
                      </span>
                    </div>
                    <p title={item.email} className="text-sm text-gray-400">
                      {item.email.length > 16
                        ? item.email.substring(0, 16) + '...'
                        : item.email}
                    </p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <svg
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 20.26C2 22.1069 3.435 24.2396 5.18889 24.9872L20.655 31.6273C21.8394 32.133 23.1833 32.133 24.345 31.6273L39.8111 24.9872C41.565 24.2396 43 22.1069 43 20.26M2 31.2535C2 33.2983 3.25278 35.1452 5.18889 35.9807L20.655 42.6207C21.8394 43.1264 23.1833 43.1264 24.345 42.6207L39.8111 35.9807C41.7472 35.1452 43 33.2983 43 31.2535M24.8005 2.49471L38.2394 8.25528C42.1116 9.9043 42.1116 12.6307 38.2394 14.2797L24.8005 20.0403C23.2744 20.7 20.7688 20.7 19.2427 20.0403L5.80384 14.2797C1.93162 12.6307 1.93162 9.9043 5.80384 8.25528L19.2427 2.49471C20.7688 1.8351 23.2744 1.8351 24.8005 2.49471Z"
                      stroke="url(#paint0_linear_8642_61657)"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_8642_61657"
                        x1="22.9184"
                        y1="2.13102"
                        x2="22.9184"
                        y2="43.5066"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0054B5" />
                        <stop offset="1" stopColor="#40DAFE" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div>
                    <p className="text-[10px] text-gray-400">Active Products</p>
                    <p className="text-2xl font-semibold text-cyan-300">
                      {item.activeProductCount}
                    </p>
                    <p
                      className="cursor-pointer text-[10px] text-gray-400"
                      onClick={() =>
                        navigate(
                          `creator-detail/${item.userId}?action=activeProducts`,
                        )
                      }
                    >
                      View details <span className="text-cyan-300">&rarr;</span>
                    </p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <svg
                    width="43"
                    height="43"
                    viewBox="0 0 43 43"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="21.5"
                      cy="21.5"
                      r="20"
                      stroke="url(#paint0_linear_8642_61668)"
                      strokeWidth="3"
                    />
                    <circle
                      cx="13.0508"
                      cy="21.7608"
                      r="1.5"
                      stroke="url(#paint1_linear_8642_61668)"
                      strokeWidth="2.63095"
                    />
                    <circle
                      cx="21.751"
                      cy="21.7608"
                      r="1.5"
                      stroke="url(#paint2_linear_8642_61668)"
                      strokeWidth="2.63095"
                    />
                    <circle
                      cx="30.4576"
                      cy="21.7608"
                      r="1.5"
                      stroke="url(#paint3_linear_8642_61668)"
                      strokeWidth="2.63095"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_8642_61668"
                        x1="0"
                        y1="21.5"
                        x2="43"
                        y2="21.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#8423F4" />
                        <stop
                          offset="1"
                          stopColor="#FF99EF"
                          stopOpacity="0.86"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_8642_61668"
                        x1="10.2354"
                        y1="21.7608"
                        x2="15.8663"
                        y2="21.7608"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#8423F4" />
                        <stop
                          offset="1"
                          stopColor="#FF99EF"
                          stopOpacity="0.86"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_8642_61668"
                        x1="18.9355"
                        y1="21.7608"
                        x2="24.5665"
                        y2="21.7608"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#8423F4" />
                        <stop
                          offset="1"
                          stopColor="#FF99EF"
                          stopOpacity="0.86"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_8642_61668"
                        x1="27.6421"
                        y1="21.7608"
                        x2="33.273"
                        y2="21.7608"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#8423F4" />
                        <stop
                          offset="1"
                          stopColor="#FF99EF"
                          stopOpacity="0.86"
                        />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div>
                    <p className="text-[10px] text-gray-400">
                      Pending Products
                    </p>
                    <p className="text-2xl font-semibold text-purple-300">
                      {item.pendingProductCount}
                    </p>
                    <p
                      className="cursor-pointer text-[10px] text-gray-400"
                      onClick={() =>
                        navigate(
                          `creator-detail/${item.userId}?action=pendingProducts`,
                        )
                      }
                    >
                      Review <span className="text-purple-300">&rarr;</span>
                    </p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <svg
                    width="45"
                    height="41"
                    viewBox="0 0 45 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3 6.65353C3 4.62329 4.28185 3.53906 5.23913 3.53906H38.8913C39.8486 3.53906 41.1304 4.62329 41.1304 6.65353V14.5H3V6.65353ZM0 16V6.65353C0 3.58677 2.06628 0.539062 5.23913 0.539062H38.8913C42.0642 0.539062 44.1304 3.58677 44.1304 6.65353V16V34.3403C44.1304 37.4071 42.0642 40.4548 38.8913 40.4548H5.23913C2.06628 40.4548 0 37.4071 0 34.3403V16ZM41.1304 17.5V34.3403C41.1304 36.3705 39.8486 37.4548 38.8913 37.4548H5.23913C4.28185 37.4548 3 36.3705 3 34.3403V17.5H41.1304Z"
                      fill="url(#paint0_linear_8642_61683)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_8642_61683"
                        x1="22.7407"
                        y1="0.316539"
                        x2="21.9837"
                        y2="40.358"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#B00D98" />
                        <stop offset="1" stopColor="#FF5EE5" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div>
                    <p className="text-[10px] text-gray-400">Subscription</p>
                    <p className="text-2xl font-semibold text-white">
                      INFINITY
                    </p>
                    <p
                      className="cursor-pointer text-[10px] text-gray-400"
                      onClick={() =>
                        navigate(
                          `creator-detail/${item.userId}?action=subscriptions`,
                        )
                      }
                    >
                      View details <span className="text-pink-300">&rarr;</span>
                    </p>
                  </div>
                </div>

                <div className="flex items-center space-x-3">
                  <svg
                    width="61"
                    height="61"
                    viewBox="0 0 61 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M55.1967 16.9519C54.9412 16.3366 54.4522 15.8476 53.8369 15.5921C53.5342 15.463 53.2091 15.3946 52.88 15.3906H40.2892C39.6214 15.3906 38.9809 15.6559 38.5086 16.1282C38.0364 16.6004 37.7711 17.2409 37.7711 17.9088C37.7711 18.5766 38.0364 19.2171 38.5086 19.6894C38.9809 20.1616 39.6214 20.4269 40.2892 20.4269H46.8113L32.7348 34.5034L24.4501 26.1935C24.216 25.9575 23.9375 25.7701 23.6306 25.6423C23.3237 25.5145 22.9946 25.4486 22.6622 25.4486C22.3297 25.4486 22.0006 25.5145 21.6938 25.6423C21.3869 25.7701 21.1084 25.9575 20.8743 26.1935L5.76537 41.3024C5.52935 41.5365 5.34201 41.815 5.21417 42.1219C5.08633 42.4287 5.02051 42.7579 5.02051 43.0903C5.02051 43.4227 5.08633 43.7519 5.21417 44.0587C5.34201 44.3656 5.52935 44.6441 5.76537 44.8782C5.99947 45.1142 6.27798 45.3015 6.58484 45.4294C6.8917 45.5572 7.22083 45.6231 7.55326 45.6231C7.88569 45.6231 8.21482 45.5572 8.52168 45.4294C8.82854 45.3015 9.10705 45.1142 9.34115 44.8782L22.6622 31.532L30.9469 39.8419C31.181 40.0779 31.4595 40.2652 31.7664 40.3931C32.0732 40.5209 32.4024 40.5867 32.7348 40.5867C33.0672 40.5867 33.3963 40.5209 33.7032 40.3931C34.0101 40.2652 34.2886 40.0779 34.5227 39.8419L50.3618 23.9775V30.4995C50.3618 31.1674 50.6271 31.8079 51.0994 32.2801C51.5716 32.7524 52.2121 33.0177 52.88 33.0177C53.5479 33.0177 54.1884 32.7524 54.6606 32.2801C55.1328 31.8079 55.3981 31.1674 55.3981 30.4995V17.9088C55.3942 17.5797 55.3257 17.2546 55.1967 16.9519Z"
                      fill="url(#paint0_linear_8642_61696)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_8642_61696"
                        x1="30.2093"
                        y1="15.3906"
                        x2="30.2093"
                        y2="45.7195"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#460F88" />
                        <stop offset="1" stopColor="#9B56FE" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div>
                    <p className="text-[10px] text-gray-400">Socials</p>
                    <img src={socials} alt="socials" className="w-[107px]" />
                    <p
                      className="cursor-pointer text-[10px] text-gray-400"
                      onClick={() =>
                        navigate(
                          `creator-detail/${item.userId}?action=socialAnaltics`,
                        )
                      }
                    >
                      View Analytics{' '}
                      <span className="text-pink-300">&rarr;</span>
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <img
                    src={eyeIcon}
                    alt="View"
                    className="w-[40px] cursor-pointer"
                    onClick={() => navigate(`creator-detail/${item.userId}`)}
                  />
                  <img src={deleteIcon} alt="Delete" className="w-[40px]" />
                </div>
              </div>
            </div>
          ))}

        {isPending ? (
          <div className="flex h-60 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          totalRecords > fetchedRecords && (
            <Pagination
              totalRecords={totalRecords}
              fetchedRecords={fetchedRecords}
              start={start}
              onPageChange={onPageChange}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ManageCreator;
