import React from 'react';
import ToggleSwitch from '../../components/ui/ToggleSwitch';

const NotificationSettings = () => {
  const actions = [
    'Important platform updates and announcements',
    'New creator/product submissions for review',
    'User account changes (registrations, deletions)',
    'Suspicious activities on user/creator accounts',
    'Pending subscription renewals or overdue payments',
    'Changes in subscription plans or pricing',
    'Weekly or monthly performance summary',
    'Important engagement drops or spikes',
  ];

  return (
    <>
      <div className="mb-5 flex items-center justify-between">
        <h1 className="text-3xl">
          <span className="font-light tracking-wider">Notifications</span>
        </h1>
      </div>
      <div className="rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        <div className="overflow-x-auto rounded-md bg-custom-gradient p-4 shadow-custom-shadow">
          <table className="w-full text-left text-sm text-white">
            <thead>
              <tr>
                <th className="px-6 py-3 font-medium">Action</th>
                <th className="px-6 py-3 font-medium text-[#B1ADCD]">Email</th>
                <th className="px-6 py-3 font-medium text-[#B1ADCD]">In-App</th>
              </tr>
            </thead>
            <tbody>
              {actions.map((action, index) => (
                <tr key={index} className="">
                  <td className="px-6 py-4 text-[#B1ADCD]">{action}</td>
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      defaultChecked
                      className="form-checkbox h-5 w-5 text-purple-500"
                    />
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="checkbox"
                      defaultChecked
                      className="form-checkbox h-5 w-5 text-purple-500"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="mt-5 rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
        <div className="rounded-md bg-custom-gradient p-8 shadow-custom-shadow">
          <h3 className="mb-4 text-lg font-semibold text-white">
            All Notifications
          </h3>
          <div className="flex flex-col justify-between gap-5">
            <ToggleSwitch label="All real-time notifications" />
            <ToggleSwitch label="Enable/disable sound" />
          </div>
        </div>
      </div>

      <div className="mt-5 flex">
        <button className="relative rounded-[1.5rem] p-[1px]">
          <div
            className="flex w-[188px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
            style={{
              background:
                'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
            }}
          >
            Save
          </div>
        </button>

        <button className="ml-5 w-[188px] rounded-full border-2 border-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
          Reset to default
        </button>
      </div>
    </>
  );
};

export default NotificationSettings;
