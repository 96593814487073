import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Table from '../../components/common/Table';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import hike from '../../assets/images/icons/hike.png';
import downfall from '../../assets/images/icons/downfall.png';
import topTrendingSample from '../../assets/images/icons/topTrendingSample.jpg';
import active from '../../assets/images/icons/active.png';
import suspended from '../../assets/images/icons/suspended.png';
import pending from '../../assets/images/icons/pending.png';

const hikeCardData = [
  {
    title: 'Clicks',
    image: hike,
    count: 1852,
  },
  {
    title: 'Page Visits',
    image: hike,
    count: 17.029,
  },
  {
    title: 'Liked',
    image: hike,
    count: 91,
  },
  {
    title: 'Average Time Spent',
    image: downfall,
    count: 155,
  },
];

const OverView = () => {
  const headings = [
    'PRODUCT NAME',
    'CATEGORY',
    'CREATOR',
    'CLICKS',
    'LIKED',
    'VISITS',
    'STATUS',
  ];
  const rows = [
    {
      productName: 'Fortnite',
      category: 'GAMES',
      creator: 'Meta',
      clicks: '23 K',
      liked: '144 K',
      visits: '3.2 K',
      status: active,
    },
    {
      productName: 'Netflix',
      category: 'APPS',
      creator: 'Netflix',
      clicks: '12.7 K',
      liked: '98 K',
      visits: '2.7 K',
      status: suspended,
    },
    {
      productName: 'Mastering UI/UX',
      category: 'COURSES',
      creator: 'Abc',
      clicks: '9.5 K',
      liked: '76 K',
      visits: '1.6 K',
      status: pending,
    },
    {
      productName: 'DUOLINGO',
      category: 'APPS',
      creator: '125',
      clicks: '6.2 K',
      liked: '53 K',
      visits: '968',
      status: active,
    },
    {
      productName: 'McCoy Law Firm',
      category: 'SERVICES',
      creator: 'Service',
      clicks: '3.8 K',
      liked: '39 K',
      visits: '784',
      status: pending,
    },
    {
      productName: 'McCoy Law Firm',
      category: 'SERVICES',
      creator: 'Service',
      clicks: '3.8 K',
      liked: '39 K',
      visits: '784',
      status: active,
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.productName}</td>
      <td className="px-6 py-4">{row.category}</td>
      <td className="px-6 py-4">{row.creator}</td>
      <td className="px-6 py-4">{row.clicks}</td>
      <td className="px-6 py-4">{row.liked}</td>
      <td className="px-6 py-4">{row.visits}</td>
      <td className="px-6 py-4">
        <img src={row.status} alt="Visa Logo" className="w-14" />
      </td>
    </>
  );
  const data = {
    labels: ['Aug 8', 'Aug 18', 'Aug 28', 'Sep 8', 'Sep 18', 'Sep 28'],
    datasets: [
      {
        label: 'Likes',
        data: [800, 900, 950, 1000, 1200, 1500],
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Shares',
        data: [2000, 2000, 1800, 1700, 1600, 1700],
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Visitors',
        data: [700, 800, 900, 850, 800, 900],
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Time Spend',
        data: [1000, 1000, 900, 900, 1100, 1200],
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const [selectedOption, setSelectedOption] = useState('All Products');

  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <>
      <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full w-full">
          <div className="flex h-full w-full flex-col">
            <CurveLineGraph
              data={data}
              options={options}
              title="Page Analytics"
            />
          </div>
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 w-full">
        <Table
          title="Products Page"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={true}
          filterOptions={[
            {label: 'All Products', value: 'All Products'},
            {label: 'Active', value: 'Active'},
            {label: 'Inactive', value: 'Inactive'},
            {label: 'Archived', value: 'Archived'},
            {label: 'Pending', value: 'Pending'},
            {label: 'Under Review', value: 'Under Review'},
          ]}
          selectedOption={selectedOption}
          searchPlaceholder="Search Product"
          onFilterChange={handleFilterChange} // Pass down the change handler
        />
      </div>
    </>
  );
};

export default OverView;
