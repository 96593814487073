import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import Modal from './Modal';
import {Link} from 'react-router-dom';
import Policy from '../policy/Policy';

const Footer = () => {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const handleModalClose = () => {
    setOpenPrivacyPolicy(false);
    setOpenTerms(false);
  };

  return (
    <footer className="flex items-center justify-between px-8 py-4 text-[11px] text-gray-400 md-lt:flex-col md-lt:gap-2">
      <div>
        <p>
          © 2024 —{' '}
          <Link to="#" className="text-blue-400 hover:underline">
            LUSSO.AI
          </Link>{' '}
          Powered by{' '}
          <Link to="#" className="text-blue-400 hover:underline">
            Lusso Labs
          </Link>{' '}
          All rights reserved
        </p>
      </div>
      <div className="flex items-center space-x-4">
        <Link
          to={'#'}
          className="text-blue-400 hover:underline"
          onClick={() => setOpenPrivacyPolicy(true)}
        >
          Privacy Policy
        </Link>
        <span>|</span>
        <Link
          to={'#'}
          className="text-blue-400 hover:underline"
          onClick={() => setOpenTerms(true)}
        >
          Terms & Condition
        </Link>
      </div>
      {createPortal(
        <Policy
          isOpen={openPrivacyPolicy}
          title="Privacy Policy"
          onClose={handleModalClose}
        />,
        document.body,
      )}
      {createPortal(
        <Policy
          isOpen={openTerms}
          title="Terms of Services"
          onClose={handleModalClose}
        />,
        document.body,
      )}
    </footer>
  );
};

export default Footer;
