import React, {useState} from 'react';
import Table from '../../components/common/Table';
import CurveLineGraph from '../../components/common/Graphs/CurveLineGraph';
import HikeCount from '../../components/core/AnalyticsCounter/HikeCount';
import hike from '../../assets/images/icons/hike.png';
import downfall from '../../assets/images/icons/downfall.png';
import active from '../../assets/images/icons/active.png';
import productSampleImage from '../../assets/images/icons/productSelection.png';
import visa from '../../assets/images/icons/visa.png';
import Popover from '../../components/common/Popover';
import Button from '../../components/ui/Button';
import Flyover from '../../components/common/Flyover';
import SubscriptionHeader from '../../components/core/Subscription/SubscriptionHeader';
import PaymentMethod from '../../components/core/Subscription/PaymentMethod';
import PlanBenefits from '../../components/core/Subscription/PlanBenefits';

const hikeCardData = [
  {
    title: 'Total Subscriptions',
    image: hike,
    count: 1852,
  },
  {
    title: 'Active',
    image: hike,
    count: 17,
  },
  {
    title: 'Cancelled',
    image: hike,
    count: 91,
  },
  {
    title: 'On Hold',
    image: downfall,
    count: 155,
  },
];

const SocialAnalytics = () => {
  const [selectedOption, setSelectedOption] = useState('scheduled');

  const [viewDetails, setViewDetails] = useState(false);
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [putOnHold, setPutOnHold] = useState(false);

  const headings = [
    'CREATOR',
    'PACKAGE',
    'METHOD',
    'AMOUNT',
    'NEXT RENEWAL',
    'STATUS',
    'ACTION',
  ];
  const rows = [
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
    {
      user: 'Sarah Johnson',
      package: 'ACCESS LUSSO (Monthly)',
      method: '**** 9519',
      amount: '$199',
      renewal: '01 Oct, 2024',
      status: active,
      action: '',
    },
  ];
  const renderRow = (row: (typeof rows)[number]) => (
    <>
      <td className="px-6 py-4">{row.user}</td>
      <td className="px-6 py-4">{row.package}</td>
      <td className="px-6 py-4">
        <td className="flex items-center px-6 py-4">
          <img src={visa} alt="Visa Logo" className="mr-2 h-4 w-10" />
          {row.method}
        </td>
      </td>
      <td className="px-6 py-4">{row.amount}</td>
      <td className="px-6 py-4">{row.renewal}</td>
      <td className="px-6 py-4">
        <img src={row.status} alt="Visa Logo" className="mr-2 h-4 w-10" />
      </td>
      <td className="px-6 py-4">
        <Popover
          button={
            <Button
              className="rounded-full bg-transparent p-1 hover:bg-[#00000022] active:bg-[#00000044]"
              type="custom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                viewBox="0 0 24 24"
                width="20"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                  stroke="#fff"
                  fill="#fff"
                />
              </svg>
            </Button>
          }
          content={
            <div className="z-10 w-40 rounded-xl bg-[#18142D] text-white shadow-xl backdrop-blur-sm">
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setViewDetails(true);
                }}
              >
                View Details
              </button>
              <hr className="border-t border-gray-600" />
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setCancelSubscription(true);
                }}
              >
                Cancel Subscription
              </button>
              <hr className="border-t border-gray-600" />
              <button
                className="w-full rounded-md px-4 py-2 text-left hover:bg-[#330987]"
                onClick={() => {
                  setPutOnHold(true);
                }}
              >
                Put on Hold
              </button>
            </div>
          }
          position="bottom-end"
        />
      </td>
    </>
  );
  const data = {
    labels: ['Aug 8', 'Aug 18', 'Aug 28', 'Sep 8', 'Sep 18', 'Sep 28'],
    datasets: [
      {
        label: 'Cancelled',
        data: [800, 900, 950, 1000, 1200, 1500],
        borderColor: '#ff0000',
        backgroundColor: '#ff0000',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'On Hold',
        data: [2000, 2000, 1800, 1700, 1600, 1700],
        borderColor: '#0000ff',
        backgroundColor: '#0000ff',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Active',
        data: [700, 800, 900, 850, 800, 900],
        borderColor: '#ffa500',
        backgroundColor: '#ffa500',
        tension: 0,
        pointRadius: 0,
      },
      {
        label: 'Total Subscriptions',
        data: [1000, 1000, 900, 900, 1100, 1200],
        borderColor: '#ffffff',
        backgroundColor: '#ffffff',
        tension: 0,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  const products = [
    {id: '1', name: 'Product 1', image: productSampleImage},
    {id: '2', name: 'Product 2', image: productSampleImage},
    {id: '3', name: 'Product 3', image: productSampleImage},
  ];

  const handleProductChange = (selectedProduct: {id: string; name: string}) => {
    console.log('Selected Product:', selectedProduct);
  };

  const handleFilterChange = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <>
      <div className="mb-8 flex items-center justify-between text-white">
        <h1 className="text-3xl">
          <span className="font-light tracking-wider">Manage</span> Subscription
        </h1>
      </div>
      <div className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2">
        {/* Left Side Graph Container */}
        <div className="col-span-2 row-span-2 flex h-full flex-col">
          <CurveLineGraph data={data} options={options} title="Social Media" />
        </div>

        {/* Right Side Cards Container */}
        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
      </div>

      <div className="mt-6 w-full">
        <Table
          title="Subscriptions"
          headings={headings}
          rows={rows}
          renderRow={renderRow}
          enableSearch={true}
          enableFilter={true}
          filterOptions={[
            {label: 'Active', value: 'active'},
            {label: 'In-Active', value: 'inactive'},
          ]}
          selectedOption={selectedOption}
          onFilterChange={handleFilterChange}
        />
      </div>
      <Flyover isOpen={viewDetails} onClose={() => setViewDetails(false)}>
        <div className="mt-6 h-[600px] overflow-y-auto pr-4">
          <SubscriptionHeader
            planStartDate="May 1, 2024"
            planName="ACCESS"
            planType="LUSSO"
            price="$99.99/mon"
            trialPeriod="Free 7-day trial"
          />
          <PaymentMethod
            title="PAYMENT METHOD"
            cardNumber="9519"
            planType="Standard (monthly)"
            nextBillDate="Jun 15, 2024"
          />

          <PlanBenefits
            title="PLAN BENEFITS"
            accessPlan="ACCESS"
            lussoPlan="LUSSO"
            benefit1="Free updates for 3 months."
            benefit2="Perfect for small projects and individual use."
            benefit3="Includes 50 digital products and complete customizable."
          />
        </div>
      </Flyover>

      <Flyover
        isOpen={cancelSubscription}
        onClose={() => setCancelSubscription(false)}
      >
        <form className="mt-6">
          <label className="mb-4 block text-xl font-medium text-white">
            Cancel Subscription
          </label>

          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium text-white">
              Comment
            </label>
            <textarea
              placeholder="Reason of cancelation"
              className="h-[100px] w-full rounded-md border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
            ></textarea>
          </div>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox text-purple-500"
              checked
            />
            <span className="text-gray ml-2 text-xs text-white">
              Are you sure, you want to cancel subscription. Alert will sent to
              user
            </span>
          </label>

          <div className="mt-4 flex space-x-4">
            <button className="relative rounded-[1.5rem] p-[1px]">
              <div
                className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                style={{
                  background:
                    'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                }}
              >
                Submit
              </div>
            </button>

            <button className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
              Cancel
            </button>
          </div>
        </form>
      </Flyover>

      <Flyover isOpen={putOnHold} onClose={() => setPutOnHold(false)}>
        <form className="mt-6">
          <label className="mb-4 block text-xl font-medium text-white">
            On Hold
          </label>

          <div className="mb-4 mt-4">
            <label className="mb-2 block text-sm font-medium text-white">
              Select Month
            </label>
            <div className="relative w-full">
              <select className="w-full appearance-none rounded-full border border-[#3c3e5c] bg-[#2E246C33] p-3 pr-10 text-sm text-white focus:outline-none">
                <option selected>3 Months</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium text-white">
              Comment
            </label>
            <textarea
              placeholder="Reason of cancelation"
              className="h-[100px] w-full rounded-md border border-[#3c3e5c] bg-[#2E246C33] p-3 text-sm focus:outline-none"
            ></textarea>
          </div>

          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox text-purple-500"
              checked
            />
            <span className="text-gray ml-2 text-xs text-white">
              Are you sure, you want to cancel subscription. Alert will sent to
              user
            </span>
          </label>

          <div className="mt-4 flex space-x-4">
            <button className="relative rounded-[1.5rem] p-[1px]">
              <div
                className="flex w-[110px] items-center justify-center rounded-[1.5rem] px-8 py-2 text-white"
                style={{
                  background:
                    'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
                }}
              >
                Submit
              </div>
            </button>

            <button className="w-[110px] rounded-full border-2 border-[#7D3CF3] bg-[#7D3CF3] px-6 py-2 font-semibold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
              Cancel
            </button>
          </div>
        </form>
      </Flyover>
    </>
  );
};

export default SocialAnalytics;
