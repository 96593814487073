import {useParams} from 'react-router-dom';
import HikeCount from '../AnalyticsCounter/HikeCount';
import UserProductList from './UserProductList';
import Reviews from './Reviews';
import UserTopProducts from './UserTopProducts';
import {
  useGetUserActions,
  useGetUserReviews,
  useGetUserStatistics,
} from '../../../hooks/userQueryHooks';

import like from '../../../assets/images/misc/like.svg';
import dislike from '../../../assets/images/misc/dislike.svg';
import love from '../../../assets/images/misc/icon-heart.svg';
import downfall from '../../../assets/images/icons/downfall.png';

const hikeCardData = [
  {
    title: 'Liked Products',
    image: like,
    count: 1852,
  },
  {
    title: 'Disliked Products',
    image: dislike,
    count: 17.029,
  },
  {
    title: 'Wishlisted',
    image: love,
    count: 91,
  },
  {
    title: 'Clicks',
    image: downfall,
    count: 155,
  },
];

const UserProducts = () => {
  const {id} = useParams();

  const {data: userStatistics} = useGetUserStatistics(id);
  const {data: userActions} = useGetUserActions({userId: id, type: 'user'});
  const {data: userReviews} = useGetUserReviews(id);

  return (
    <div className="flex flex-col gap-8">
      <div
        id="user products"
        className="relative grid w-full grid-cols-4 gap-6 md-lt:grid-cols-2"
      >
        <UserTopProducts className="col-span-2 row-span-3" />

        {hikeCardData.map((data, index) => (
          <HikeCount
            key={index}
            title={data.title}
            count={data.count}
            increaseText="+8.2k increase/day"
            progressSections={[
              {
                width: '1/4',
                gradientClasses: 'bg-gradient-to-r from-blue-600 to-blue-400',
              },
              {
                width: '1/6',
                gradientClasses:
                  'bg-gradient-to-r from-red-500 via-orange-500 to-yellow-400',
              },
            ]}
            imageSrc={data.image}
            imageClass="w-10 md-lt:w-8"
            countClass="mb-0 text-[40px] md-lt:text-2xl text-white"
            titleClass="text-sm md-lt:text-xs font-light tracking-widest md-lt:tracking-normal text-[#00f0fb]"
            increaseTextClass="mb-2 md-lt:m-0 text-xs text-gray-400"
            imagePositionClasses="absolute right-6 top-16"
          />
        ))}
        <Reviews />
      </div>
      <UserProductList />
    </div>
  );
};

export default UserProducts;
