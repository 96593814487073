import React, {useState} from 'react';

type Role = 'User' | 'Creator' | 'Admin';
type Action =
  | 'Content View'
  | 'Create Content'
  | 'Edit Content'
  | 'View Content Analytics'
  | 'Manage Notifications'
  | 'Respond to Content Feedback'
  | 'Override User/Creator Preferences'
  | 'Edit Creator Profile'
  | "View Other Users' Content"
  | 'Manage Users and Creators'
  | 'Send Platform Announcements'
  | 'View Full Platform Analytics';

interface Permissions {
  [action: string]: {[role in Role]: boolean};
}

const ManageRoles: React.FC = () => {
  const [permissions, setPermissions] = useState<Permissions>({
    'Content View': {User: true, Creator: true, Admin: true},
    'Create Content': {User: false, Creator: true, Admin: true},
    'Edit Content': {User: false, Creator: true, Admin: true},
    'View Content Analytics': {User: false, Creator: true, Admin: true},
    'Manage Notifications': {User: true, Creator: true, Admin: true},
    'Respond to Content Feedback': {User: true, Creator: true, Admin: true},
    'Override User/Creator Preferences': {
      User: false,
      Creator: false,
      Admin: true,
    },
    'Edit Creator Profile': {User: false, Creator: true, Admin: true},
    "View Other Users' Content": {User: true, Creator: true, Admin: true},
    'Manage Users and Creators': {User: false, Creator: false, Admin: true},
    'Send Platform Announcements': {User: false, Creator: false, Admin: true},
    'View Full Platform Analytics': {User: false, Creator: false, Admin: true},
  });

  const togglePermission = (action: Action, role: Role) => {
    setPermissions(prev => ({
      ...prev,
      [action]: {
        ...prev[action],
        [role]: !prev[action][role],
      },
    }));
  };

  return (
    <div className="text-gray-400">
      <div className="w-full">
        <div className="w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-px">
          <div className="grid grid-cols-7 rounded-md bg-custom-gradient font-semibold">
            <div className="col-span-4 p-2 text-left">Action</div>
            <div className="justify-self-center p-2">User</div>
            <div className="justify-self-center p-2">Creator</div>
            <div className="justify-self-center p-2">Admin</div>
          </div>
        </div>
        <div>
          {Object.entries(permissions).map(([action, roles]) => (
            <div key={action} className="grid grid-cols-7">
              <div className="col-span-4 p-2 text-left">{action}</div>
              {Object.entries(roles).map(([role, hasPermission]) => (
                <div key={role} className="p-2 text-center">
                  <input
                    type="checkbox"
                    checked={hasPermission}
                    onChange={() =>
                      togglePermission(action as Action, role as Role)
                    }
                    className="form-checkbox h-5 w-5 text-purple-600"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManageRoles;
