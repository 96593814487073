import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import arrow from '../../../../assets/images/icons/arrow-right.png';

function HistoryTabs() {
  const [openTab, setOpenTab] = useState(null);

  const toggleTab = (tabIndex: any) => {
    setOpenTab(openTab === tabIndex ? null : tabIndex);
  };

  return (
    <div className="mx-auto max-w-xl space-y-4 p-6 text-white">
      {/* Heading */}
      <h2 className="text-3xl font-semibold text-gray-300">History</h2>

      {/* Tab Items */}
      <div>
        {/* Tab 1 */}
        <div>
          <button
            onClick={() => toggleTab(1)} // Add onClick handler to toggle the tab
            className="flex w-full items-center justify-between rounded-lg border-b-[0.5px] border-solid px-2 py-4 text-left hover:bg-[#342a5d]"
            style={{
              borderImageSlice: 1,
              borderImageSource:
                'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
            }}
          >
            <span>Comments Sent over by Admin</span>
            <div className="text-right">
              <span className="text-[12px] text-[#B1ADCD]">11:30 Today</span>
              <Link to="#" className="flex items-center gap-2 text-[10px]">
                View details
                <img className="w-[5px]" src={arrow} alt="arrow" />
              </Link>
            </div>
          </button>
          {openTab === 1 && (
            <div className="mt-2">
              {/* Expanded Tab Content */}
              <div className="text-white">
                <div className="mt-8">
                  <h3 className="mb-2 text-lg font-semibold uppercase text-teal-400">
                    Product Name & Logo
                  </h3>
                  <p className="mb-3 leading-relaxed text-gray-200">
                    TikTok is a social media platform that lets users create,
                    discover, and share short-form videos with a global
                    audience. Known for its viral challenges, filters, and
                    diverse music library, TikTok offers an immersive experience
                    for both creators and viewers. With a focus on
                    entertainment, creativity, and community, TikTok has become
                    a cultural phenomenon, empowering users to express
                    themselves and connect with others.
                  </p>
                </div>
                <hr className="border-t border-gray-500" />
                <div className="mt-8">
                  <h3 className="mb-2 text-lg font-semibold uppercase text-teal-400">
                    Comments
                  </h3>
                  <p className="leading-relaxed text-gray-200">
                    TikTok allows users to create and share 15-second to
                    3-minute videos set to music, offering a wide range of
                    editing tools, filters, and effects. The app is designed for
                    quick entertainment, with trending challenges and viral
                    content taking center stage.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Tab 2 */}
        <div>
          <button
            onClick={() => toggleTab(2)} // Add onClick handler to toggle the tab
            className="flex w-full items-center justify-between rounded-lg border-b-[0.5px] border-solid px-2 py-4 text-left hover:bg-[#342a5d]"
            style={{
              borderImageSlice: 1,
              borderImageSource:
                'linear-gradient(180deg, #0054B5 0.32%, #40DAFE 101.24%)',
            }}
          >
            <span>Comments Sent over by Admin</span>
            <div className="text-right">
              <span className="text-[12px] text-[#B1ADCD]">11:30 Today</span>
              <Link to="#" className="flex items-center gap-2 text-[10px]">
                View details
                <img className="w-[5px]" src={arrow} alt="arrow" />
              </Link>
            </div>
          </button>
          {openTab === 2 && (
            <div className="mt-2">
              {/* Expanded Tab Content */}
              <div className="text-white">
                <div className="mt-8">
                  <h3 className="mb-2 text-lg font-semibold uppercase text-teal-400">
                    Product Name & Logo
                  </h3>
                  <p className="mb-3 leading-relaxed text-gray-200">
                    TikTok is a social media platform that lets users create,
                    discover, and share short-form videos with a global
                    audience. Known for its viral challenges, filters, and
                    diverse music library, TikTok offers an immersive experience
                    for both creators and viewers. With a focus on
                    entertainment, creativity, and community, TikTok has become
                    a cultural phenomenon, empowering users to express
                    themselves and connect with others.
                  </p>
                </div>
                <hr className="border-t border-gray-500" />
                <div className="mt-8">
                  <h3 className="mb-2 text-lg font-semibold uppercase text-teal-400">
                    Comments
                  </h3>
                  <p className="leading-relaxed text-gray-200">
                    TikTok allows users to create and share 15-second to
                    3-minute videos set to music, offering a wide range of
                    editing tools, filters, and effects. The app is designed for
                    quick entertainment, with trending challenges and viral
                    content taking center stage.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HistoryTabs;
