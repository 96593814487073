import React from 'react';
import {cn} from '../../lib/utils';

interface InputProps {
  label?: string;
  name?: string;
  value?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  checked?: boolean;
  error?: boolean;
  className?: string;
  errorMessage?: string;
  type?:
    | 'text'
    | 'email'
    | 'number'
    | 'phone'
    | 'password'
    | 'radio'
    | 'textarea';
  rows?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTextareaChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Input: React.FC<InputProps> = ({
  label = '',
  name = '',
  value = '',
  placeholder = 'Enter here...',
  checked = false,
  required = false,
  disabled = false,
  readOnly = false,
  className = '',
  type = 'text',
  onChange = () => console.log('...clicked'),
  onTextareaChange = () => console.log('...clicked'),
  error = false,
  errorMessage = '',
  rows = 3,
}) => {
  return (
    <div>
      <label className="text-sm">
        <p className="text-xs font-light text-white">
          {label ?? ''}
          {required && <span className="ms-1 text-[#F04438]">*</span>}
        </p>
        {type === 'textarea' ? (
          <textarea
            rows={rows}
            name={name ?? ''}
            autoComplete="off"
            placeholder={placeholder ?? ''}
            className={cn(
              `mt-2 w-full outline-none ${disabled ? 'text-gray-400' : 'hover:border-[#ffffff99]'} border-2 px-4 py-2 border-[${error ? '#F04438' : '#3c3e5c'}] focus:border-[#2684ff]`,
              className,
            )}
            value={value || ''}
            style={{
              // borderRadius: 90,
              // border: `1px solid ${error ? '#F04438' : '#3c3e5c'}`,
              background: '#2E246C33',
            }}
            onChange={onTextareaChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : (
          <input
            type={type}
            name={name ?? ''}
            autoComplete="off"
            placeholder={placeholder ?? ''}
            className={cn(
              `mt-2 w-full rounded-full outline-none ${disabled ? 'text-gray-400' : 'hover:border-[#ffffff99]'} border-2 px-4 py-2 border-[${error ? '#F04438' : '#3c3e5c'}] focus:border-[#2684ff]`,
              className,
            )}
            value={value || ''}
            style={{
              borderRadius: 90,
              // border: `1px solid ${error ? '#F04438' : '#3c3e5c'}`,
              background: '#2E246C33',
            }}
            onChange={onChange}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
      </label>
      {error && <span className="errorField">{errorMessage ?? ''}</span>}
    </div>
  );
};

export default Input;
