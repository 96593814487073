import React from 'react';

const RejectProduct = () => {
  return (
    <div className="mx-auto max-w-3xl space-y-6 p-8 text-white">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold">Reject Product</h2>
      </div>
      <div className="">
        <div className="space-y-6">
          <div>
            <h3 className="text-md mb-2 font-semibold text-gray-300">
              Comment
            </h3>
            <textarea
              className="h-[90px] w-full rounded-[7px] border border-[rgba(108,140,255,0.5)] bg-[rgba(46,36,108,0.2)] p-4 text-gray-300 focus:border-purple-500 focus:outline-none"
              placeholder="Admin will inform user why the product is rejected"
            ></textarea>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="checkbox-id"
                className="form-checkbox h-5 w-5 text-purple-500"
              />
              <label
                htmlFor="checkbox-id"
                className="font-sans text-[10px] italic leading-[20px] text-[rgba(255,255,255,0.6)]"
              >
                Send Disclaimer message : If you feel like we have made a
                mistake ypu can request correction here Email@.com
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex space-x-4">
        <button className="relative rounded-[1.5rem] p-[1px]">
          <div
            className="flex w-[170px] items-center justify-center rounded-[1.5rem] px-8 py-2 font-bold text-white"
            style={{
              background:
                'linear-gradient(177.73deg, #4300BD -37.45%, #792FFF 29.89%, #FF77B0 115.59%)',
            }}
          >
            Reject
          </div>
        </button>

        <button className="w-[100px] rounded-full bg-[#7D3CF3] px-6 py-2 font-bold text-white transition-colors duration-300 hover:bg-[#7D3CF3] hover:text-white">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RejectProduct;
