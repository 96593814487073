import {Link} from 'react-router-dom';
import arrow from '../../../assets/images/icons/arrow-right.png';

const Reviews = () => {
  return (
    <div className="col-span-2 w-full rounded-md bg-gradient-to-br from-[#3c80f6] via-[#312961] to-[#4b3baf] p-[1px]">
      <div className="flex flex-col gap-4 rounded-md bg-custom-gradient p-6 shadow-custom-shadow">
        <div className="flex items-center justify-between">
          <h2 className="text-md font-light uppercase text-[#00f0fb]">
            Reviewed and Rating
          </h2>
          <h2 className="text-md font-light uppercase text-[#00f0fb]">
            Reviewed: 100
          </h2>
        </div>
        <div className="flex flex-col gap-3">
          {[...Array(2)].map((_, i) => (
            <div
              key={i}
              className="flex items-center justify-between border-b border-[#2b7695] px-2 pb-2 text-xs font-light text-white"
            >
              <p>
                {i % 2 === 0
                  ? 'User updated subscription Details'
                  : 'Requested New Product Approval'}
              </p>
              <div className="flex flex-col items-start text-[8px] leading-tight">
                <span className="text-[#B1ADCD]">11:30 Today</span>
                <Link to="#" className="flex items-center gap-2 text-[10px]">
                  View details
                  <img className="w-[5px]" src={arrow} alt="arrow" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
